<template>
    <div />
</template>

<script>
import globalConstants from '@/plugins/globalConstants.js';
import FbaApiError from '../models/ApiError';
import { shouldValidateProximity } from '@/shared/helpers.js';

export default {
    data: () => ({
        qid: null,
        trailerId: null,
        trailerData: null,
        isAttachedToScannedTrailer: false,
    }),
    async created() {
        this.setLoading(true);
        this.setScannedId();

        this.validUser = this.$store.getters.validateUserDetails;
        if (!this.validUser) {
            console.warn(
                'need to get you registered, maybe your browser settings dont allow local storage?'
            );
            this.$router.push({ path: '/getstarted' });
            return;
        }

        await this.getDriverAndTrailerData();
    },
    methods: {
        setScannedId() {
            // Get qid from params from coming from /scan OR native QR scan
            this.qid = this.$route.params?.id;
            if (this.qid) {
                this.$store.dispatch('setNewQid', this.qid);
            } else {
                // Get trailerId from state.currentTrailerInfo if coming from /trailerNotRegistered
                this.trailerData = this.$store.getters.getCurrentTrailerInfo;
                this.trailerId = this.trailerData?.trailerId;
            }
        },
        async checkTrailerAttachabilityAndRoute() {
            try {
                const attachability = await await this.$store.dispatch(
                    'getTrailerAttachability'
                );

                if (attachability instanceof Array) {
                    const routeObject =
                        attachability[0]?.field === 'Driver Locator'
                            ? { name: 'TrailerUnavailable' }
                            : {
                                  name: 'EnterTrailerDetails',
                                  params: { alert: 'trailerNotFound' },
                              };

                    this.$router.push(routeObject);
                    this.setLoading(false);
                    return false;
                }

                // TODO: implement proximity validation workflow
                if (
                    attachability?.canAttach === false ||
                    shouldValidateProximity(attachability)
                ) {
                    this.$store.commit('saveTrailerInfo', {
                        qid: null,
                        trailerId: attachability.trailerNumber,
                        trailerCompany: attachability.trailerCompany,
                        isAttached: null,
                    });

                    this.$router.push({ name: 'TrailerUnavailable' });
                    this.setLoading(false);
                    return false;
                }

                return true;
            } catch (error) {
                console.warn('Error getting trailer attachability', error);
                this.$router.push({ path: '/' });

                this.setLoading(false);
                return false;
            }
        },
        async getDriverAndTrailerData() {
            if (!(await this.checkTrailerAttachabilityAndRoute())) {
                return;
            }

            try {
                if (this.qid || this.trailerData?.qid) {
                    this.qid = this.qid || this.trailerData?.qid;
                    let response = await this.$store.dispatch(
                        'getTrailerbyQId',
                        this.qid
                    );
                    if (
                        response instanceof FbaApiError &&
                        driverResponse.statusCode ===
                            globalConstants.httpStatusCodes.timeout
                    ) {
                        this.$router.push({ name: history.go(-1) });
                    } else {
                        this.trailerData = response;
                    }
                } else {
                    let response = await this.$store.dispatch(
                        'getTrailerById',
                        {
                            id: this.trailerId,
                            company: this.trailerData?.trailerCompany,
                        }
                    );
                    if (
                        response instanceof FbaApiError &&
                        response.statusCode ===
                            globalConstants.httpStatusCodes.timeout
                    ) {
                        this.$router.push({ name: history.go(-1) });
                        this.setLoading(false);
                        return;
                    } else {
                        this.trailerData = response;
                    }
                }

                if (this.trailerData.isFound === false) {
                    this.$router.push({ name: 'EnterTrailerDetails' });
                    return;
                }

                const driver = this.$store.getters.getUserDetails;
                const driverResponse = await this.$store.dispatch(
                    'getAttachedTrailersByDriverNumber',
                    driver.mobileNumber
                );
                if (
                    driverResponse instanceof FbaApiError &&
                    driverResponse.statusCode ===
                        globalConstants.httpStatusCodes.timeout
                ) {
                    this.$router.push({ name: history.go(-1) });
                    this.setLoading(false);
                    return;
                }
                const scannedTrailerMatch = driverResponse?.trailers?.find(
                    (trailer) =>
                        trailer.trailerId === this.trailerData?.trailerId
                );
                this.isAttachedToScannedTrailer = scannedTrailerMatch
                    ? true
                    : false;

                this.$store.commit('saveTrailerInfo', {
                    qid: this.qid,
                    trailerId: this.trailerData?.trailerId,
                    trailerCompany: this.trailerData?.trailerCompany,
                    daysStationary: this.trailerData?.daysStationary,
                    cargoStatus: this.trailerData?.cargoStatus,
                    isAttached: this.isAttachedToScannedTrailer,
                });
                await this.$store.dispatch('clearCurrentTrailerAssessment');
                this.setLoading(false);
                this.routeUser();
            } catch (e) {
                this.$router.push({ name: history.go(-1) });
            }
        },
        routeUser() {
            if (this.isAttachedToScannedTrailer) {
                this.$router.push({ name: 'DetachTrailer' });
            } else {
                this.$router.push({ name: 'AttachTrailer' });
            }
        },
    },
};
</script>
