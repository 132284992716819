import apiErrorHandler from '../plugins/apiErrorHandler';
import axios from '@/plugins/axiosConfig';

export default async function (
    mcNumber,
    dotNumber,
    mobileNumber,
    lastName,
    firstName,
    truckNumber
) {
    let driverRegisterRequestDto = {
        mcNumber: mcNumber,
        dotNumber: dotNumber,
        mobileNumber: mobileNumber,
        firstName: firstName,
        lastName: lastName,
        truckNumber: truckNumber,
    };

    return await axios({
        method: 'post',
        url: `${process.env.VUE_APP_EXT_API_URL}api/drivers/register`,
        data: driverRegisterRequestDto,
    })
        .then((r) => r)
        .catch((error) => {
            return apiErrorHandler(error);
        });
}
