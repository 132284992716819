import { createStore } from 'vuex';
import loadStore from './loads';
import localforage from 'localforage';
import queries from '@/queries';
import { cloneDeep, set, isEmpty, omit } from 'lodash';
import FbaApiError from '../models/ApiError';
import globalConstants from '../plugins/globalConstants';
import { tryGetCurrentPosition } from '@/shared/helpers.js';
import { DateTime } from 'luxon';
import {
    isNullOrUndefined,
    shouldClearTypeOnSubtypeRemoval,
} from '@/shared/helpers';
import apmTransactions from '../libraries/apm/transactions';
import { getDriverIdentifier, getTrailerIdentifier } from './helpers';

export default createStore({
    modules: {
        loadStore,
    },
    state: {
        localStoreKeys: {
            apiTokenKey: 'jwt',
            attachEventsKey: 'ae',
            currentLocale: 'loc',
            currentTrailerInfoKey: 'tfo',
            userDetailKey: 'udtl',
            accessGrantIdKey: 'agik',
        },
        localforageKeys: {
            trailerAssessmentKey: 'ta',
            trailerAssessmentMetaKey: 'tam',
        },
        apiToken: null,
        attachEvents: null,
        currentLocale: globalConstants.locale.english,
        currentTrailerInfo: null,
        globalLoading: false,
        qidExists: false,
        userDetails: null,
        trailerAssessment: null,
        trailerAssessmentMeta: null,
        confirmationShown: false,
        acceptedTrailerHasBodyDamage: false,
        accessGrantId: null,
        notificationMessage: null,
    },
    mutations: {
        clearUserDetails(state) {
            localStorage.removeItem(state.localStoreKeys.apiTokenKey);
            localStorage.removeItem(state.localStoreKeys.userDetailKey);
            state.apiToken = {};
            state.userDetails = {};
        },
        clearStaleDataAfterAssociationSuccess(state) {
            this.commit('setAcceptedTrailerHasBodyDamage', false);
            localStorage.removeItem(state.localStoreKeys.currentTrailerInfoKey);
            state.currentTrailerInfo = {};
        },
        clearStore(state) {
            this.commit('loadStore/clearLoadStore');
            localStorage.removeItem(state.localStoreKeys.apiTokenKey);
            localStorage.removeItem(state.localStoreKeys.attachEventsKey);
            localStorage.removeItem(state.localStoreKeys.currentTrailerInfoKey);
            localStorage.removeItem(state.localStoreKeys.userDetailKey);
            console.log('Local Storage Cleared');
        },
        clearStoreAndState(state) {
            this.commit('loadStore/clearLoadStore');
            this.commit('loadStore/initializeLoadsStore');
            localStorage.removeItem(state.localStoreKeys.apiTokenKey);
            localStorage.removeItem(state.localStoreKeys.attachEventsKey);
            localStorage.removeItem(state.localStoreKeys.currentTrailerInfoKey);
            localStorage.removeItem(state.localStoreKeys.userDetailKey);
            state.apiToken = {};
            state.userDetails = {};
            state.currentTrailerInfo = {};
            state.trailerAssessment = {};
            state.trailerAssessmentMeta = {};
        },
        clearCurrentTrailerInfo(state) {
            localStorage.removeItem(state.localStoreKeys.currentTrailerInfoKey);
        },
        clearCurrentTrailerAssessment(state) {
            state.trailerAssessment = {};
            state.trailerAssessmentMeta = {};
        },

        initializeStore(state) {
            this.commit('loadStore/initializeLoadsStore');
            // apiToken = {jwt:"", saved:DateTime}
            const apiToken = localStorage.getItem(
                state.localStoreKeys.apiTokenKey
            );
            //userDetails = {driverId, mobileNumber, dotNumber, carrierName, mobileNumberValidated}
            const userDetails = localStorage.getItem(
                state.localStoreKeys.userDetailKey
            );
            //trailerInfo = {qid, trailerId, trailerCompany, isAttached}
            const currentTrailerInfo = localStorage.getItem(
                state.localStoreKeys.currentTrailerInfoKey
            ); // Should we expire these? How long can we trust them?
            //attachEvents = [{loadNumber, TrailerId, Qid, isAttached, savedTime}]
            const attachEvents = localStorage.getItem(
                state.localStoreKeys.attachEventsKey
            );
            // currentLocale = "locale shortcode EX: en/es/ps"
            const currentLocale = localStorage.getItem(
                state.localStoreKeys.currentLocale
            );

            const accessGrantId = localStorage.getItem(
                state.localStoreKeys.accessGrantIdKey
            );

            if (apiToken) {
                state.apiToken = JSON.parse(apiToken);
            }
            if (userDetails) {
                state.userDetails = JSON.parse(userDetails);
            }
            if (currentTrailerInfo) {
                state.currentTrailerInfo = JSON.parse(currentTrailerInfo);
            }
            if (attachEvents) {
                state.attachEvents = JSON.parse(attachEvents);
            }
            if (currentLocale) {
                state.currentLocale = JSON.parse(currentLocale);
            }
            if (accessGrantId) {
                state.accessGrantId = JSON.parse(accessGrantId);
            }
        },
        saveApiToken(state, token) {
            let apiToken = {};
            apiToken.token = token;
            apiToken.saved = new Date();
            localStorage.setItem(
                state.localStoreKeys.apiTokenKey,
                JSON.stringify(apiToken)
            );
            state.apiToken = apiToken;
        },
        saveUserDetails(state, userDetails) {
            userDetails.saved = new Date();
            localStorage.setItem(
                state.localStoreKeys.userDetailKey,
                JSON.stringify(userDetails)
            );
            state.userDetails = userDetails;
            apmTransactions.setUserDetails();
        },
        setMobileNumberAsValid(state) {
            let details = state.userDetails;
            details.mobileNumberValidated = true;
            this.commit('saveUserDetails', details);
        },
        setGlobalLoading(state, isLoading) {
            state.globalLoading = isLoading;
        },
        saveTrailerInfo(state, trailerInfo) {
            trailerInfo.saved = new Date();
            localStorage.setItem(
                state.localStoreKeys.currentTrailerInfoKey,
                JSON.stringify(trailerInfo)
            );
            state.currentTrailerInfo = trailerInfo;
        },

        updateAttachEvents(state, attachEvent) {
            let events = JSON.parse(
                localStorage.getItem(state.localStoreKeys.attachEventsKey)
            );
            if (!events) {
                events = [];
            }
            attachEvent.saved = new Date();
            events.push(attachEvent);
            localStorage.setItem(
                state.localStoreKeys.attachEventsKey,
                JSON.stringify(events)
            );
            state.attachEvents = events;
        },
        updateTrailerAssessment(state, assessment) {
            state.trailerAssessment = assessment;
        },
        updateTrailerAssessmentMeta(state, assessmentMeta) {
            state.trailerAssessmentMeta = assessmentMeta;
        },
        saveIsAttached(state, isAttached) {
            let tInfo = state.currentTrailerInfo;
            tInfo.isAttached = isAttached;
            this.commit('saveTrailerInfo', tInfo);
        },
        saveCurrentLocale(state, locale) {
            localStorage.setItem(
                state.localStoreKeys.currentLocale,
                JSON.stringify(locale)
            );
            state.currentLocale = locale;
        },
        saveNeedsToRegister(state, needsToRegister) {
            let userDetails = this.getters.getUserDetail();
            userDetails.needsToRegister = needsToRegister;
            this.commit('saveUserDetails', userDetails);
        },
        setConfirmationShown(state, payload) {
            state.confirmationShown = payload;
        },
        setAcceptedTrailerHasBodyDamage(state, payload) {
            state.acceptedTrailerHasBodyDamage = payload;
        },
        setTrailerMapAccessGrant(state, payload) {
            localStorage.setItem(
                state.localStoreKeys.accessGrantIdKey,
                JSON.stringify(payload)
            );

            state.accessGrantId = payload;
        },
        saveIsTrailerAttachmentEvent(state, isTrailerAttachmentEvent) {
            let meta = state.trailerAssessmentMeta;
            meta.isTrailerAttachmentEvent = isTrailerAttachmentEvent;
            this.commit('updateTrailerAssessmentMeta', meta);
        },
        setNotificationMessage(state, message) {
            state.notificationMessage = message;
        },
    },
    actions: {
        async getTrailerByAccessGrant({ commit, state }) {
            return await queries.getMapTrailerByAccessGrant(
                state.accessGrantId
            );
        },
        async setNewQid({ commit, getters, state }, qid) {
            const prevTrailerInfo = state.currentTrailerInfo;
            if (
                getters.validateUserInfo &&
                prevTrailerInfo?.isAttached &&
                qid !== prevTrailerInfo?.qid
            ) {
                console.warn("We've skipped the previous disconnect");
            }
            const trailerInfo = {
                qid: qid,
                isAttached: null,
                trailerCompany: null,
                trailerId: null,
            };
            this.commit('saveTrailerInfo', trailerInfo);
        },

        async attachDriverTrailer({ commit, state }) {
            const response = await queries.postAttach({
                trailer: getTrailerIdentifier(state),
                driver: getDriverIdentifier(state),
            });

            const event = {
                trailerId: state.currentTrailerInfo.trailerId,
                trailerCompany: state.currentTrailerInfo.trailerCompany,
                qid: state.currentTrailerInfo.qid,
                isAttached: state.currentTrailerInfo.isAttached,
                acceptedTrailerHasBodyDamage:
                    state.acceptedTrailerHasBodyDamage,
            };
            commit('updateAttachEvents', event);

            return response;
        },

        async detachDriverTrailer({ commit, state }) {
            const response = await queries.postDetach({
                trailer: getTrailerIdentifier(state),
                driver: getDriverIdentifier(state),
            });

            const event = {
                trailerId: state.currentTrailerInfo.trailerId,
                trailerCompany: state.currentTrailerInfo.trailerCompany,
                qid: state.currentTrailerInfo.qid,
                isAttached: state.currentTrailerInfo.isAttached,
                acceptedTrailerHasBodyDamage:
                    state.acceptedTrailerHasBodyDamage,
            };
            commit('updateAttachEvents', event);

            return response;
        },

        async attachDriverTrailerLoad({ commit, state }, payload) {
            const response = await queries.postAttach({
                trailer: getTrailerIdentifier(state),
                driver: {
                    ...getDriverIdentifier(state),
                    driverName:
                        state.userDetails.firstName +
                        state.userDetails.lastName,
                },
                load: {
                    loadNumber: payload.loadNumber,
                    dotNumber: state.userDetails.dotNumber,
                },
            });

            const event = {
                loadNumber: payload.loadNumber,
                trailerId: state.currentTrailerInfo.trailerId,
                trailerCompany: state.currentTrailerInfo.trailerCompany,
                qid: state.currentTrailerInfo.qid,
                isAttached: state.currentTrailerInfo.isAttached,
                acceptedTrailerHasBodyDamage:
                    state.acceptedTrailerHasBodyDamage,
            };
            commit('updateAttachEvents', event);

            return response;
        },

        async detachDriverTrailerLoad({ commit, state }, payload) {
            const response = await queries.postDetach({
                trailer: getTrailerIdentifier(state),
                driver: {
                    ...getDriverIdentifier(state),
                    driverName:
                        state.userDetails.firstName +
                        state.userDetails.lastName,
                },
                load: {
                    loadNumber: payload.loadNumber,
                    dotNumber: state.userDetails.dotNumber,
                },
            });

            const event = {
                loadNumber: payload.loadNumber,
                trailerId: state.currentTrailerInfo.trailerId,
                trailerCompany: state.currentTrailerInfo.trailerCompany,
                qid: state.currentTrailerInfo.qid,
                isAttached: state.currentTrailerInfo.isAttached,
                acceptedTrailerHasBodyDamage:
                    state.acceptedTrailerHasBodyDamage,
            };
            commit('updateAttachEvents', event);

            return response;
        },

        //<qid>
        async getTrailerbyQId({ commit, state }, qid) {
            const trailerInfo = await queries.getTrailerbyQrId(qid);
            trailerInfo.qid = qid;
            this.commit('saveTrailerInfo', trailerInfo);
            return trailerInfo;
        },

        //< payload.scannedId, payload.truckId, payload.scanAction >
        async associateAssetTruckToTrailer({ commit, state }, payload) {
            return await queries.associateAssetTruckToTrailer(
                payload.scannedId,
                payload.truckId,
                payload.scanAction
            );
        },

        async postTrailerNotRegistered({ commit, state }, payload) {
            return await queries.postTrailerNotRegistered(
                payload.dotNumber,
                payload.trailerId,
                payload.trailerCompany,
                payload.location,
                payload.mobileNumber,
                payload.qid
            );
        },

        async getCustomersByLatLng({ commit, state }, payload) {
            return await queries.getCustomersByLatLng(
                payload.lat,
                payload.lng,
                payload.company,
                payload.limit
            );
        },

        async getTrailerAttachability({ commit, state }) {
            const position = await tryGetCurrentPosition();

            return await queries.getAssociatabilityEvaluation('attach', {
                trailer: getTrailerIdentifier(state),
                driver: {
                    ...getDriverIdentifier(state),
                    location: position.coords,
                },
            });
        },

        async getTrailerById({ commit, state }, payload) {
            return await queries.getTrailerById(payload.id, payload.company);
        },

        async getTrailersNearby({ commit, state }, payload) {
            return await queries.getTrailersNearby(payload.lat, payload.lon);
        },

        async getTrailersNearbyTruck({ commit, state }, payload) {
            return await queries.getTrailersNearbyTruck(
                payload.truckId,
                payload.company
            );
        },

        async getAttachedTrailersByDriverNumber(
            { commit, state },
            driverNumber
        ) {
            return await queries.getAttachedTrailersByDriverNumber(
                driverNumber
            );
        },

        async getTrailerAssessmentMetaFromIndexedDB({ commit, state }) {
            try {
                const dbRetVal = await localforage.getItem(
                    state.localforageKeys.trailerAssessmentMetaKey
                );
                commit('updateTrailerAssessmentMeta', dbRetVal);
            } catch (e) {
                console.warn(
                    'failed to retrieve trailer assessment meta from indexed DB',
                    e
                );
            }
        },

        async updateTrailerAssessmentMeta({ commit, state }, meta) {
            let assessmentMeta = cloneDeep(state.trailerAssessmentMeta) || {};

            for (const [key, value] of Object.entries(meta)) {
                assessmentMeta[key] = value;
            }

            try {
                const dbRetVal = await localforage.setItem(
                    state.localforageKeys.trailerAssessmentMetaKey,
                    assessmentMeta
                );
                assessmentMeta = dbRetVal;
            } catch (e) {
                console.warn(
                    'local forage failed to update trailer assessment meta in indexed DB',
                    e
                );
            }

            commit('updateTrailerAssessmentMeta', assessmentMeta);
        },

        noteTrailerAssessmentQuestionDotSafe({ commit, dispatch }, isDotSafe) {
            dispatch('updateTrailerAssessmentQuestion', {
                question: globalConstants.trailer.assessmentQuestions.dotSafe,
                answer: isDotSafe ? 'Yes' : 'No',
            });
        },
        async confirmTrailerAssessmentWithRedTag(
            { commit, dispatch },
            payload
        ) {
            dispatch(
                'updateTrailerAssessmentOutcome',
                globalConstants.trailer.assessmentOutcomes.virtualRedTag
            );
            await dispatch('confirmTrailerAssessment', payload);
        },
        async acceptTrailerWithBodyDamageWithPossessionAccepted(
            { commit, dispatch },
            payload
        ) {
            dispatch(
                'updateTrailerAssessmentOutcome',
                globalConstants.trailer.assessmentOutcomes.tookPossession
            );
            dispatch('updateTrailerAssessmentQuestion', {
                question:
                    globalConstants.trailer.assessmentQuestions
                        .willTakePossession,
                answer: 'Yes',
            });

            await dispatch('acceptTrailerWithBodyDamage', payload);
        },
        async acceptTrailerWithBodyDamageWithBreakdownAccepted(
            { commit, dispatch },
            payload
        ) {
            dispatch(
                'updateTrailerAssessmentOutcome',
                globalConstants.trailer.assessmentOutcomes.tookPossession
            );
            dispatch('updateTrailerAssessmentQuestion', {
                question:
                    globalConstants.trailer.assessmentQuestions
                        .willContactBreakdown,
                answer: 'Yes',
            });

            await dispatch('acceptTrailerWithBodyDamage', payload);
        },
        async confirmTrailerAssessmentWithPossessionDeclined(
            { commit, dispatch },
            payload
        ) {
            dispatch(
                'updateTrailerAssessmentOutcome',
                globalConstants.trailer.assessmentOutcomes.declinedPossession
            );
            dispatch('updateTrailerAssessmentQuestion', {
                question:
                    globalConstants.trailer.assessmentQuestions
                        .willTakePossession,
                answer: 'No',
            });

            await dispatch('confirmTrailerAssessment', payload);
        },
        async confirmTrailerAssessmentWithBreakdownDeclined(
            { commit, dispatch },
            payload
        ) {
            dispatch(
                'updateTrailerAssessmentOutcome',
                globalConstants.trailer.assessmentOutcomes.declinedPossession
            );
            dispatch('updateTrailerAssessmentQuestion', {
                question:
                    globalConstants.trailer.assessmentQuestions
                        .willContactBreakdown,
                answer: 'No',
            });

            await dispatch('confirmTrailerAssessment', payload);
        },

        updateTrailerAssessmentQuestion({ commit, state }, payload) {
            const meta = state.trailerAssessmentMeta ?? {};
            let questions = meta?.questionAnswers ?? [];
            questions = questions.filter(
                (qna) => qna.question != payload.question
            );
            questions.push(payload);
            meta.questionAnswers = questions;

            commit('updateTrailerAssessmentMeta', meta);
        },

        updateTrailerAssessmentOutcome({ commit, state }, outcome) {
            const meta = state.trailerAssessmentMeta ?? {};
            meta.assessmentOutcome = outcome;
            commit('updateTrailerAssessmentMeta', meta);
        },

        async getTrailerAssessmentFromIndexedDB({ commit, state }) {
            try {
                const dbRetVal = await localforage.getItem(
                    state.localforageKeys.trailerAssessmentKey
                );
                commit('updateTrailerAssessment', dbRetVal);
            } catch (e) {
                console.warn(
                    'failed to retrieve trailer assessment from indexed DB',
                    e
                );
            }
        },

        async updateTrailerAssessment({ commit, state }, damage) {
            let assessment = cloneDeep(state.trailerAssessment) || {};

            if (
                isNullOrUndefined(assessment[damage.type]) ||
                assessment[damage.type].typeHasDamage !== damage.typeHasDamage
            ) {
                set(assessment, `${damage.type}`, damage);
            }

            try {
                const dbRetVal = await localforage.setItem(
                    state.localforageKeys.trailerAssessmentKey,
                    assessment
                );
                assessment = dbRetVal;
            } catch (e) {
                console.warn(
                    'local forage failed to update trailer assessment to indexed DB',
                    e
                );
            }
            commit('updateTrailerAssessment', assessment);
        },
        async savePartialTrailerAssessment(
            { commit, dispatch, state },
            partial
        ) {
            let assessment = cloneDeep(state.trailerAssessment) || {};

            if (isNullOrUndefined(assessment[partial?.assessmentType])) {
                const damage = {
                    type: partial.assessmentType,
                    typeHasDamage: true,
                    subTypes: [],
                };

                set(assessment, `${partial.assessmentType}`, damage);
            }

            let subTypes = assessment[partial?.assessmentType]?.subTypes;

            const newSubType = {
                subType: partial.subType,
                documents: partial.documents,
                comment: partial.comment,
            };

            const idx = subTypes?.findIndex(
                (ele) => ele?.subType === partial.subType
            );
            if (idx === -1) {
                subTypes.push(newSubType);
            } else {
                subTypes[idx] = newSubType;
            }

            set(assessment, `${partial.assessmentType}.subTypes`, subTypes);
            try {
                const dbRetVal = await localforage.setItem(
                    state.localforageKeys.trailerAssessmentKey,
                    assessment
                );
                assessment = dbRetVal;
            } catch (e) {
                console.warn(
                    'local forage failed to save trailer assessment to indexed DB',
                    e
                );
            }
            commit('updateTrailerAssessment', assessment);
        },
        async removeTrailerAssessmentSubType(
            { commit, state },
            typeAndSubType
        ) {
            let assessment = cloneDeep(state.trailerAssessment);
            const { assessmentType, subType } = typeAndSubType;

            const assessmentSubTypes = assessment[assessmentType]?.subTypes;

            const idx = assessmentSubTypes?.findIndex(
                (ele) => ele?.subType === subType
            );

            if (idx === -1) {
                return;
            } else if (
                shouldClearTypeOnSubtypeRemoval(assessment, typeAndSubType)
            ) {
                assessment = omit(assessment, assessmentType);
            } else {
                assessmentSubTypes.splice(idx, 1);
            }

            try {
                const dbRetVal = await localforage.setItem(
                    state.localforageKeys.trailerAssessmentKey,
                    assessment
                );
                assessment = dbRetVal;
            } catch (e) {
                console.warn(
                    'local forage failed to update trailer assessment to indexed DB',
                    e
                );
            }
            commit('updateTrailerAssessment', assessment);
        },
        async clearCurrentTrailerAssessment({ commit, state }) {
            try {
                commit('clearCurrentTrailerAssessment');
                localforage.removeItem(
                    state.localforageKeys.trailerAssessmentKey
                );
                localforage.removeItem(
                    state.localforageKeys.trailerAssessmentKeyMeta
                );
            } catch (e) {
                console.warn(
                    'local forage failed to clear trailer assessment to indexed DB',
                    e
                );
            }
        },
        async postTrailerAssessment({ commit, state }, payload) {
            try {
                return await queries.postTrailerAssessment(
                    payload.eventName,
                    payload.driverData,
                    payload.trailerData,
                    payload.locationData,
                    payload.startTime,
                    payload.endTime,
                    payload.assessmentTypes,
                    payload.questionAnswers,
                    payload.assessmentOutcome
                );
            } catch (e) {
                console.error(e);
                return new FbaApiError();
            }
        },

        async iterateAndSaveAssessmentDocuments({ commit, state }, payload) {
            // Step through assessment types, subtypes, and documents array for individually calling saveDocument
            let types = [];
            Object.values(state?.trailerAssessment)?.forEach((type) => {
                if (type.subTypes) types = types.concat(type.subTypes);
            });

            let docs = types.flatMap((subs) => subs.documents);
            docs.forEach(async (doc) => {
                await this.dispatch('saveDocument', {
                    doc: doc,
                    assessmentId: payload.assessmentId,
                });
            });
        },

        async saveDocument({ commit, state }, payload) {
            let blob = await (await fetch(payload.doc.src)).blob();
            const file = new File([blob], payload.doc.fileName);

            var formData = new FormData();
            formData.append(
                'metadata',
                JSON.stringify({
                    trailerId: payload.doc.metadata.trailerId,
                    trailerAssessmentId: payload.assessmentId,
                    type: payload.doc.metadata.type,
                    subType: payload.doc.metadata.subType,
                })
            );

            formData.append('file', file);
            await queries.saveDocument(formData);
        },

        async requestVerificationCode(
            { commit, getters, state },
            mobileNumber
        ) {
            try {
                let response = await queries.postRequestVerificationCode(
                    mobileNumber
                );
                if (
                    response?.status === globalConstants.httpStatusCodes.success
                ) {
                    commit('saveApiToken', response.data.token);
                    return true;
                } else if (response instanceof FbaApiError) {
                    return response;
                } else {
                    apmTransactions.instrumentUnhandledApiResponse();
                }
            } catch (e) {
                console.error(e);
            }
        },
        async driverRegister({ commit, state, getter }, payload) {
            try {
                let response = await queries.postDriverRegister(
                    payload.mcNumber,
                    payload.dotNumber,
                    payload.mobileNumber,
                    payload.lastName,
                    payload.firstName,
                    payload.truckNumber
                );
                if (
                    response?.status === globalConstants.httpStatusCodes.success
                ) {
                    let userDetail = {
                        driverId: response.data.driverId,
                        dotNumber: response.data.dotNumber,
                        carrierName: response.data.carrierName,
                        mobileNumber: payload.mobileNumber,
                        firstName: payload.firstName,
                        lastName: payload.lastName,
                        dotNumber: payload.dotNumber,
                        mcNumber: payload.mcNumber,
                        needsToRegister: false,
                        driverId: response.data.driverId,
                        truckNumber: payload.truckNumber,
                    };
                    commit('saveUserDetails', userDetail);
                    return userDetail;
                } else if (response instanceof FbaApiError) {
                    return response;
                } else {
                    apmTransactions.instrumentUnhandledApiResponse();
                }
            } catch (e) {
                console.error(e);
            }
        },
        async driverSignin({ commit, state }, payload) {
            try {
                let response = await queries.postDriverSignin(
                    payload.mcNumber,
                    payload.dotNumber,
                    payload.mobileNumber,
                    payload.truckNumber
                );
                if (
                    response?.status === globalConstants.httpStatusCodes.success
                ) {
                    let userDetail = {
                        driverId: response.data.driverId,
                        dotNumber: response.data.dotNumber,
                        carrierName: response.data.carrierName,
                        firstName: response.data.firstName,
                        lastName: response.data.lastName,
                        mobileNumber: payload.mobileNumber,
                        needsToRegister: response.data.needsToRegister,
                        mcNumber: payload.mcNumber,
                        truckNumber: response.data.truckNumber,
                    };
                    commit('saveUserDetails', userDetail);
                    return userDetail;
                } else if (response instanceof FbaApiError) {
                    return response;
                } else {
                    apmTransactions.instrumentUnhandledApiResponse();
                }
            } catch (e) {
                console.error(e);
            }
        },
        async driverVerifyIdentity({ commit, state }, code) {
            try {
                let response = await queries.postVerifyIdentity(
                    state.userDetails.mobileNumber,
                    code
                );
                if (
                    response?.status === globalConstants.httpStatusCodes.success
                ) {
                    return true;
                } else if (response instanceof FbaApiError) {
                    return response;
                }
            } catch (e) {
                console.error(e);
            }
        },
        async driverResendVerificationCode({ commit, state }) {
            try {
                let response = await queries.postRequestVerificationCode(
                    state.userDetails.mobileNumber
                );
                if (
                    response?.status === globalConstants.httpStatusCodes.success
                ) {
                    return true;
                } else if (response instanceof FbaApiError) {
                    return response;
                } else {
                }
            } catch (e) {
                console.error(e);
            }
        },
        async canIDetachThisTrailer({ commit, state }, qidFromUrl) {
            if (!state.attachEvents || state.attachEvents.length == 0) {
                return true;
            }
            const lastEvent = state.attachEvents[state.attachEvents.length - 1];
            if (lastEvent.qid == qidFromUrl) {
                return lastEvent.isAttached;
            } else {
                return true;
            }
        },
        setGlobalLoading({ commit, state }, isLoading) {
            commit('setGlobalLoading', isLoading);
        },
        saveCurrentLocale({ commit, state }, locale) {
            commit('saveCurrentLocale', locale);
        },
        async resetTrailerInfoUponUndo({ commit, state }, payload) {
            const trailerInfo = await queries.getTrailerById(
                payload.trailerId,
                payload.trailerCompany
            );
            trailerInfo.qid = payload.qid;
            trailerInfo.isAttached = payload.isAttached;
            commit('saveTrailerInfo', trailerInfo);
        },
        setAlertPopup({ commit, state }, localeKey) {
            state.alert = localeKey;
        },
        clearAlert({ commit, state }) {
            state.alert = null;
        },
        async useTrailerWithoutValidLoad({ getters }, payload) {
            const { attemptedBillOfLadingNumber, attemptedLoadNumber } =
                payload;

            const { firstName, lastName, mobileNumber, mcNumber } =
                getters['getUserDetails'];

            const { trailerId, trailerCompany } =
                getters['getCurrentTrailerInfo'];

            await queries.postUseTrailerWithoutLoadNumber(
                attemptedBillOfLadingNumber,
                attemptedLoadNumber,
                firstName,
                lastName,
                mobileNumber,
                mcNumber,
                `${trailerId}|${trailerCompany}`
            );
        },
        async confirmKnownLoad({ commit, dispatch }, payload) {
            commit('setGlobalLoading', true);
            await dispatch('attachDriverTrailerLoad', {
                loadNumber: payload,
            });

            commit('clearStaleDataAfterAssociationSuccess');
        },
        async confirmTrailerAssessment({ commit, getters, dispatch }, payload) {
            commit('setGlobalLoading', true);
            await dispatch('getTrailerById', {
                id: payload.trailerData?.trailerId,
                company: payload.trailerData?.trailerCompany,
            });

            const assessmentTypes = Object.values(payload.trailerAssessment);

            const newPayload = {
                eventName: payload.assessmentVariety,
                driverData: {
                    dotNumber: payload.driverData?.dotNumber,
                    mcNumber: payload.driverData?.mcNumber,
                    carrierName: payload.driverData?.carrierName,
                    mobileNumber: payload.driverData?.mobileNumber,
                    firstName: payload.driverData?.firstName,
                    lastName: payload.driverData?.lastName,
                },
                trailerData: {
                    id: payload.trailerData.trailerId,
                    company: payload.trailerData.trailerCompany,
                },
                locationData: {
                    lat: getters.trailerAssessmentMeta?.locationData?.lat ?? 0,
                    lon: getters.trailerAssessmentMeta?.locationData?.lon ?? 0,
                },
                startTime:
                    getters.trailerAssessmentMeta?.startTimeISO ??
                    DateTime.now().toISO(),
                endTime:
                    getters.trailerAssessmentMeta?.endTimeISO ??
                    DateTime.now().toISO(),
                assessmentTypes: assessmentTypes,
                questionAnswers: getters.trailerAssessmentMeta?.questionAnswers,
                assessmentOutcome:
                    getters.trailerAssessmentMeta?.assessmentOutcome ??
                    globalConstants.trailer.assessmentOutcomes.unspecified,
            };

            const response = await dispatch(
                'postTrailerAssessment',
                newPayload
            );

            commit('updateTrailerAssessment', payload.trailerAssessment);

            await dispatch('iterateAndSaveAssessmentDocuments', {
                assessmentId: response?.assessmentId,
            });

            await dispatch('clearCurrentTrailerAssessment');
        },
        async acceptTrailerWithBodyDamage({ commit, dispatch }, payload) {
            dispatch('acceptedTrailerHasBodyDamage', true);
            await dispatch('confirmTrailerAssessment', payload);
        },
        async confirmUnknownLoad({ commit, dispatch }, payload) {
            var data = {
                loadNumber: null,
            };

            commit('setGlobalLoading', true);
            const attemptedLoadInfo = {
                attemptedBillOfLadingNumber: '',
                attemptedLoadNumber: payload,
            };

            await dispatch('useTrailerWithoutValidLoad', attemptedLoadInfo);
            await dispatch('attachDriverTrailer');
            commit('clearStaleDataAfterAssociationSuccess');
            commit('setGlobalLoading', false);
        },
        async confirmDetachTrailer({ commit, dispatch }, payload) {
            commit('setGlobalLoading', true);

            commit('saveTrailerInfo', {
                qid: payload.getCurrentTrailerInfo?.qid,
                trailerId: payload.getCurrentTrailerInfo?.trailerId,
                trailerCompany: payload.getCurrentTrailerInfo?.trailerCompany,
                daysStationary: payload.getCurrentTrailerInfo?.daysStationary,
                cargoStatus: payload.getCurrentTrailerInfo?.cargoStatus,
                isAttached: payload.isAttached,
            });

            if (payload.currentLoad?.loadNumber) {
                await dispatch('detachDriverTrailerLoad', {
                    loadNumber: payload.currentLoad?.loadNumber,
                });
            } else {
                await dispatch('detachDriverTrailer');
            }
        },
        showConfirmation({ commit }) {
            commit('setConfirmationShown', true);
        },
        hideConfirmation({ commit }) {
            commit('setConfirmationShown', false);
        },
        acceptedTrailerHasBodyDamage({ commit }, payload) {
            commit('setAcceptedTrailerHasBodyDamage', payload);
        },
        async shareTrailerLocationByAssetMobileNumber(_, payload) {
            const shareTrailer = queries.shareTrailerWithAssetDriver;

            try {
                const result = await shareTrailer(payload);

                return result.data;
            } catch (e) {
                console.error(e);
            }
        },
        async shareTrailerLocationByThirdPartyMobileNumber(_, payload) {
            const shareTrailer = queries.shareTrailerWithThirdPartyDriver;

            try {
                const result = await shareTrailer(payload);

                return result.data;
            } catch (e) {
                console.error(e);
            }
        },
    },
    getters: {
        activeAlertLocaleKey: (state) => {
            return state?.alert;
        },
        acceptedTrailerHasBodyDamage: (state) => {
            return state?.acceptedTrailerHasBodyDamage;
        },
        validateUserDetails: (state) => {
            var weekAgo = new Date();
            weekAgo.setDate(weekAgo.getDate() - 7);
            return state?.userDetails &&
                state?.userDetails?.mobileNumber &&
                state?.userDetails?.dotNumber &&
                state?.userDetails?.carrierName &&
                !state?.userDetails?.needsToRegister &&
                new Date(state?.apiToken?.saved) > weekAgo
                ? true
                : false;
        },
        getCurrentTrailerInfo: (state) => {
            return state?.currentTrailerInfo;
        },
        getCurrentTrailerId: (state, getters) => {
            if (
                !isNullOrUndefined(state?.currentTrailerInfo) &&
                !isEmpty(state.currentTrailerInfo)
            ) {
                return state.currentTrailerInfo.trailerId;
            } else if (!isNullOrUndefined(getters['lastEvent'])) {
                return getters['lastEvent'].trailerId;
            }
            return null;
        },
        trailerAssessment: (state) => {
            return state?.trailerAssessment;
        },
        trailerAssessmentMeta: (state) => {
            return state?.trailerAssessmentMeta;
        },

        getCurrentCarrierName: (state) => {
            return state?.userDetails?.carrierName;
        },
        getUserDetails: (state) => {
            return state?.userDetails;
        },
        lastEvent: (state) => {
            if (!state.attachEvents || state.attachEvents.length == 0) {
                return {};
            } else {
                return state.attachEvents[state.attachEvents.length - 1];
            }
        },
        getGlobalLoadingStatus: (state) => {
            return state?.globalLoading;
        },
        getCurrentLocale: (state) => {
            return state?.currentLocale;
        },
        notificationMessage: (state) => {
            return state?.notificationMessage;
        },
    },
});
