import associateAssetTruckToTrailer from './associateAssetTruckToTrailer';
import getAttachedLoadByDriverNumber from './getAttachedLoadByDriverNumber';
import getAttachedTrailersByDriverNumber from './getAttachedTrailersByDriverNumber';
import getCustomersByLatLng from './getCustomersByLatLng';
import getLoad from './getLoad';
import getLoads from './getLoads';
import getLoadsByDriverNumber from './getLoadsByDriverNumber';
import getTrailerById from './getTrailerById';
import getTrailerbyQrId from './getTrailerbyQrId';
import getMapTrailerByAccessGrant from './getMapTrailerByAccessGrant';
import getTrailersNearby from './getTrailersNearby';
import getTrailersNearbyTruck from './getTrailersNearbyTruck';
import postDriverRegister from './postDriverRegister';
import postDriverSignin from './postDriverSignin';
import postRequestVerificationCode from './postRequestVerificationCode';
import postTrailerAssessment from './postTrailerAssessment';
import postTrailerNotRegistered from './postTrailerNotRegistered';
import postUseTrailerWithoutLoadNumber from './postUseTrailerWithoutLoadNumber';
import postVerifyIdentity from './postVerifyIdentity';
import saveDocument from './saveDocument';
import * as trailerAssociations from './trailerAssociations';
import * as trailerSharing from './trailerSharing';
import getAssociatabilityEvaluation from './getAssociabilityEvaluation';

export default {
    associateAssetTruckToTrailer,
    getAttachedLoadByDriverNumber,
    getAttachedTrailersByDriverNumber,
    getAssociatabilityEvaluation,
    getCustomersByLatLng,
    getLoad,
    getLoads,
    getLoadsByDriverNumber,
    getTrailerById,
    getTrailerbyQrId,
    getMapTrailerByAccessGrant,
    getTrailersNearby,
    getTrailersNearbyTruck,
    postDriverRegister,
    postDriverSignin,
    postRequestVerificationCode,
    postTrailerAssessment,
    postTrailerNotRegistered,
    postUseTrailerWithoutLoadNumber,
    postVerifyIdentity,
    saveDocument,
    ...trailerAssociations,
    ...trailerSharing,
};
