import apiErrorHandler from '../plugins/apiErrorHandler';
import axios from '@/plugins/axiosConfig';

export default async function (
    attemptedBillOfLadingNumber,
    attemptedLoadNumber,
    driverFirstName,
    driverLastName,
    driverMobileNumber,
    motorCarrierNumber,
    trailerId
) {
    return await axios({
        method: 'post',
        url: `${process.env.VUE_APP_EXT_API_URL}api/trailers/useTrailerWithoutLoadNumber`,
        data: {
            attemptedBillOfLadingNumber,
            attemptedLoadNumber,
            driverFirstName,
            driverLastName,
            driverMobileNumber,
            motorCarrierNumber,
            trailerId,
        },
    })
        .then((r) => r.data)
        .catch((error) => {
            return apiErrorHandler(error);
        });
}
