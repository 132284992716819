<template>
    <div>
        <div class="fb-container body">
            <div class="fb-form">
                <button type="button" class="btn btn-link fb-form-clear-button">
                    <font-awesome-icon :icon="['fas', 'xmark']" />
                </button>
                <div class="fb-form-image info">
                    <font-awesome-icon :icon="['fas', 'circle-info']" />
                </div>
                <h1 class="fb-form-heading">
                    {{ $t('About.title') }}
                </h1>
                <p class="fb-form-paragraph">
                    {{ $t('About.text') }}
                </p>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.fb-form-image {
    &.info {
        color: $uix-color-info;
        display: inline-block;
        font-size: 6rem; // 96px
        margin: 40px auto 0;
        position: relative;

        .fa-circle-info {
            float: left;
            margin: 0;
            position: relative;
        }
    }
}

.fb-form-heading {
    margin: 10px 0 30px;
}

.fb-form-paragraph {
    margin: 0 0 60px;
    text-align: left;
}
</style>
