import apiErrorHandler from '../plugins/apiErrorHandler';
import axios from '@/plugins/axiosConfig';

export default async function (mobileNumber, verificationCode) {
    let verifyIdentityRequestDto = {
        mobileNumber: mobileNumber,
        verificationCode: verificationCode,
    };

    return await axios({
        method: 'post',
        url: `${process.env.VUE_APP_EXT_API_URL}api/drivers/verifyIdentity`,
        data: verifyIdentityRequestDto,
    })
        .then((r) => r)
        .catch((error) => {
            return apiErrorHandler(error);
        });
}
