<template>
    <div
        class="uix-control uix-text-control"
        :class="{ 'has-error': hasAlert }"
    >
        <div
            class="uix-control-container fbx-select"
            :class="{ 'has-error': hasAlert }"
        >
            <div
                class="uix-control-groups fbx-select"
                :class="{ 'has-error': hasAlert }"
            >
                <div
                    class="uix-control-group fbx-select"
                    :class="{ 'has-error': hasAlert }"
                >
                    <input
                        v-model="inputValue"
                        class="uix-control-text"
                        :class="{
                            'has-error': hasAlert,
                            'uix-control-text-center': !(
                                hasAlert || inputValue
                            ),
                        }"
                        name="mcDotNumber"
                        type="text"
                        :inputmode="keyboardType"
                        :required="required"
                        :placeholder="$t(label)"
                        @input="updateValue()"
                    />
                    <label
                        v-if="inputValue || hasAlert"
                        class="uix-control-sm-label show"
                        for="mcDotNumber"
                    >
                        {{ $t(labelValue) }}
                        <strong v-if="hasAlert" :class="errorTextClass">
                            {{ $t(errorText) }}
                        </strong>
                    </label>
                    <fbx-input-reset
                        :value="inputValue"
                        @resetValue="resetValue()"
                    />
                </div>
            </div>

            <div
                id="dropdownToggle"
                class="uix-control-btn-addon addon-right fb-dropdown-toggle"
                @click="toggleDropdown()"
            >
                <a
                    id="ControlRightIconButton"
                    class="btn uix-control-btn fb-dropdown-toggle"
                >
                    <i class="fas fa-caret-down fb-dropdown-toggle" />
                </a>
            </div>

            <div
                class="dropdown-menu uix-dropdown-menu"
                :class="{ show: ariaExpanded }"
                aria-labelledby="ControlSelectSelect"
            >
                <a
                    v-for="item in dropdownOptions"
                    :key="item"
                    class="dropdown-item uix-dropdown-item"
                    :class="{ active: item == dropdownValue }"
                    @click="updateDropdownValue(item)"
                    >{{ $t(item.Name) }}</a
                >
            </div>
        </div>
        <label for="mcDotNumber" class="uix-control-description" />
    </div>
</template>

<script>
import BaseStyleControl from '../styles/baseStyleControl';
import fbxInputReset from './fbx-input-reset.vue';
const alertTypes = BaseStyleControl.alertInputThemes;

export default {
    name: 'FbxDropdownInput',
    components: { fbxInputReset },
    props: {
        alert: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        dropdownOptions: {
            type: Array,
            default() {
                return ['select one'];
            },
        },
        keyboardType: {
            type: String,
            default() {
                return 'text';
            },
            validator(type) {
                if (
                    !BaseStyleControl.virtualKeyboardInputModes.includes(type)
                ) {
                    console.warn(
                        `fbx-dropdown-input has invalid keyboardType - ${type}. valid types are ${BaseStyleControl.virtualKeyboardInputModes}`
                    );
                    return false;
                } else {
                    return true;
                }
            },
        },
    },
    emits: ['update:modelValue', 'validationError'],
    setup: () => {},
    data() {
        return {
            ariaExpanded: false,
            errorText: 'Required',
            hasAlert: false,
            inputValue: '',
            label: this.dropdownOptions[0].Name,
            labelValue: '',
            dropdownValue: {},
            errorTextClass: '',
        };
    },
    watch: {
        alert(alertValue) {
            this.setAlert(alertValue);
        },
        inputValue(val) {
            this.inputValue = val.replace(/[^0-9]/g, '');
            this.labelValue = (val + '').length > 0 ? this.label : '';
        },
    },
    async created() {
        this.dropdownValue = this.dropdownOptions[0];
        this.label = this.dropdownValue.Name;
        window.addEventListener('click', this.clickOff);
        window.addEventListener('touchStart', this.clickOff);
    },
    onUnmounted() {
        window.removeEventListener('click', this.clickOff);
        window.addEventListener('touchStart', this.clickOff);
    },
    methods: {
        resetValue() {
            this.inputValue = '';
            this.updateValue();
        },
        validate(value) {
            this.inputValue = value.replace(/[^0-9]/g, '');
            if (this.required && this.inputValue.trim().length === 0) {
                this.$emit('validationError', true);
            } else {
                this.setAlert(this.alert);
                this.$emit('validationError', false);
            }
        },
        updateValue() {
            this.modelValue = this.dropdownValue.Value + this.inputValue;
            this.$emit('update:modelValue', this.modelValue);
            this.validate(this.inputValue);
        },
        clickOff(e) {
            if (
                !BaseStyleControl.isTheClickTargetADropdown(e) &&
                this.ariaExpanded == true
            ) {
                this.ariaExpanded = false;
            }
        },
        updateDropdownValue(value) {
            this.dropdownValue = value;
            this.label = value.Name;
            this.labelValue = this.inputValue.length > 0 ? this.label : '';
            this.clickOff();
            this.updateValue();
        },
        toggleDropdown(e) {
            this.ariaExpanded = !this.ariaExpanded;
        },
        setAlert(alertType) {
            if (alertTypes.includes(alertType)) {
                this.errorText =
                    BaseStyleControl.getInputClassesForTheme(alertType).text;
                this.errorTextClass =
                    BaseStyleControl.getInputClassesForTheme(
                        alertType
                    ).textClass;
                this.hasAlert = true;
            } else {
                this.hasAlert = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.uix-control-text-center {
    padding: 0px 10px;
}
</style>
