<template>
    <div>
        <div class="fb-container body">
            <div class="fb-form">
                <h2 class="fb-form-heading">
                    {{ $t('EnterTrailerDetails.title') }}
                </h2>
                <p class="fb-form-paragraph">
                    {{ $t('EnterTrailerDetails.subTitle') }}
                    <em>{{ $t('EnterTrailerDetails.subTitle2') }}</em>
                </p>
                <div>
                    <knight-or-swift-toggle
                        ref="selectedCompany"
                        v-model="selectedCompany"
                    />
                    <fbx-input
                        ref="trailerId"
                        v-model="trailerId"
                        :required="true"
                        inputmode="numeric"
                        :label="'EnterTrailerDetails.trailerNumberPlaceholder'"
                    />
                    <p
                        v-show="displayError"
                        class="fb-error-text fb-form-paragraph"
                    >
                        <strong>{{
                            $t('EnterTrailerDetails.trailer404Heading')
                        }}</strong>
                        {{ $t('EnterTrailerDetails.trailer404Text') }}
                    </p>
                    <fbx-button
                        class="wide"
                        theme="primary"
                        :button-text="$t('EnterTrailerDetails.submitButton')"
                        @clicked="onSubmit"
                    />
                </div>
            </div>
            <div class="bottom-div">
                <fbx-button
                    theme="linkEm"
                    :button-text="$t('EnterTrailerDetails.scanQrCodeButton')"
                    @clicked="$router.push({ name: 'Scan' })"
                />
            </div>
        </div>
    </div>
</template>

<script>
import FbxButton from '../components/fbx-button.vue';
import FbxInput from '../components/fbx-input.vue';
import KnightOrSwiftToggle from '../components/KnightOrSwiftToggle.vue';

const ALERTS = {
    trailerNotFound: 'trailerNotFound',
};

export default {
    name: 'EnterTrailerDetails',
    components: { KnightOrSwiftToggle, FbxInput, FbxButton },
    data: () => ({
        trailerId: null,
        selectedCompany: null,
        displayError: false,
    }),
    watch: {
        trailerId() {
            this.trailerId = this.trailerId.trim();
            this.displayError = false;
        },
    },
    created() {
        this.setLoading(false);
    },
    mounted() {
        this.setAlert();
    },
    methods: {
        setAlert() {
            const alert = ALERTS[this.$route.params?.alert];

            if (alert) {
                this.$refs.trailerId.setAlert(alert);
                this.displayError = true;
                this.setLoading(false);
            }
        },
        validateForm() {
            if (!this.selectedCompany && !this.trailerId) {
                this.$refs.selectedCompany.setAlert('required');
                this.$refs.trailerId.setAlert('required');
                return false;
            } else if (!this.selectedCompany) {
                this.$refs.selectedCompany.setAlert('required');
                return false;
            } else if (!this.trailerId) {
                this.$refs.trailerId.setAlert('required');
                return false;
            } else {
                return true;
            }
        },
        async onSubmit() {
            if (this.validateForm()) {
                this.$store.commit('saveTrailerInfo', {
                    qid: null,
                    trailerId: this.trailerId,
                    trailerCompany: this.selectedCompany,
                    isAttached: null,
                });

                this.$router.push({ name: 'TrailerScan', params: { id: '' } });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.bottom-div {
    margin-top: auto;
    width: 100%;
}
.fb-form-paragraph {
    margin: 0 0 30px;

    > em {
        display: block;
    }
}

.btn-primary {
    margin-top: 1rem;
}

.fb-error-text {
    color: $uix-color-error;
}
</style>
