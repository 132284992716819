<template>
    <div>
        <div class="fb-container body">
            <div class="fb-form">
                <h1 class="fb-form-heading">
                    {{ $t('TrailerNotFit.title') }}
                    <i18n-t keypath="TrailerNotFit.subTitle" tag="em">
                        <a :href="`tel:${supportNumber}`">{{
                            supportNumber
                        }}</a>
                    </i18n-t>
                </h1>

                <div class="fb-form-image trailer-side error">
                    <font-awesome-icon :icon="['fas', 'circle-xmark']" />
                    <svg
                        version="1.1"
                        class="fb-svg trailer-side"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 280.5 96.5"
                        style="enable-background: new 0 0 280.5 96.5"
                        xml:space="preserve"
                    >
                        <path
                            class="fb-path trailer-outline"
                            d="M50.2,84.6c0-6.3,5.1-11.4,11.4-11.4l0,0c6.3,0,11.4,5.1,11.4,11.4l0,0C73.1,90.9,68,96,61.6,96 l0,0C55.3,96,50.2,90.9,50.2,84.6L50.2,84.6z M55.8,84.6c0,3.3,2.6,5.9,5.9,5.9l0,0c3.3,0,5.9-2.6,5.9-5.9l0,0 c0-3.3-2.6-5.9-5.9-5.9l0,0C58.4,78.7,55.8,81.3,55.8,84.6L55.8,84.6z M23.6,84.6c0-6.3,5.1-11.4,11.4-11.4l0,0 c6.3,0,11.4,5.1,11.4,11.4l0,0C46.4,90.9,41.3,96,35,96l0,0C28.7,96,23.6,90.9,23.6,84.6L23.6,84.6z M29.1,84.6 c0,3.3,2.6,5.9,5.9,5.9s5.9-2.6,5.9-5.9l0,0c0-3.3-2.6-5.9-5.9-5.9l0,0C31.8,78.7,29.1,81.3,29.1,84.6z M201.5,91.2V74.1h-99.8 l-21.5,6.5c0,0.1,0,0.2,0,0.3V87h-4.6c0.1-0.8,0.2-1.6,0.2-2.4c0-7.8-6.3-14.2-14.2-14.2c-6,0-11.3,3.8-13.3,9.4 c-2-5.6-7.3-9.4-13.3-9.4c-7.8,0-14.2,6.3-14.2,14.2c0,0.8,0.1,1.6,0.2,2.4h-4.4v-6.1c0-0.3,0-0.7,0.1-1L2.2,74.3v-7.9H280v5.3 h-73.5v19.5L201.5,91.2z M1,56v-6h4c0.6,0,1,0.4,1,1l0,0v4c0,0.6-0.4,1-1,1l0,0H1z M1,38v-6h4c0.6,0,1,0.4,1,1l0,0v4 c0,0.6-0.4,1-1,1l0,0H1z M1,20v-6h4c0.6,0,1,0.4,1,1l0,0v4c0,0.6-0.4,1-1,1l0,0H1z"
                        />
                        <g>
                            <path
                                class="fb-path trailer-box"
                                d="M278.5,67.5H1.5V6.8c0-2.9,2.4-5.3,5.3-5.3h266.5c2.9,0,5.3,2.4,5.3,5.3L278.5,67.5L278.5,67.5z"
                            />
                            <path
                                class="fb-path trailer-outline"
                                d="M6.8,3C4.7,3,3,4.7,3,6.8V66h274V6.8c0-2.1-1.7-3.8-3.8-3.8L6.8,3 M6.8,0h266.5c3.7,0,6.8,3,6.8,6.8V69H0V6.8 C0,3,3,0,6.8,0z"
                            />
                        </g>
                    </svg>
                </div>

                <div class="fb-form-heading-group">
                    <span class="fb-form-sub-heading">{{
                        $t('TrailerNotFit.dryType', [trailerData?.trailerId])
                    }}</span>
                </div>

                <p class="fb-form-paragraph">
                    {{ $t('TrailerNotFit.paragraph') }}
                </p>

                <div class="fb-form-button-group footer-buttons">
                    <button
                        type="button"
                        class="btn btn-link fb-form-button"
                        @click="onScanClick"
                    >
                        <em>{{ $t('TrailerNotFit.scanButton') }}</em>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        supportNumber: null,
        trailerData: null,
        assessmentId: null,
        previousTrailerStatus: null,
    }),
    created() {
        this.initializeData();
        this.setLoading(false);
    },
    methods: {
        initializeData() {
            this.supportNumber = process.env.VUE_APP_SUPPORT_NUMBER;
            this.trailerData = this.$store.getters.getCurrentTrailerInfo;
        },
        onScanClick() {
            this.setLoading(true);
            this.$router.push({ name: 'Scan' });
        },
    },
};
</script>

<style lang="scss" scoped>
.fb-svg {
    &.trailer-side {
        height: 99.7639px;
        margin: 0;
        position: relative;
        width: 290px;
        z-index: 0;
    }
}

.fb-path {
    &.trailer-outline {
        fill: $uix-heading-text-color;
    }

    &.trailer-box {
        fill: rgba($uix-heading-text-color, 0);
    }
}

.fb-form-image {
    &.trailer-side {
        color: $uix-heading-text-color;
        display: inline-block;
        margin: 30px auto 0;
        position: relative;

        .fa-circle-question,
        .fa-circle-check,
        .fa-circle-xmark {
            font-size: 32px;
            left: 50%;
            margin: -30px 0 0 -16px;
            position: absolute;
            top: 50%;
            z-index: 1;
        }

        &.success {
            .fb-path {
                &.trailer-outline {
                    fill: $uix-color-success;
                }

                &.trailer-box {
                    fill: rgba($uix-color-success, 0);
                }
            }

            .fa-circle-check {
                color: $uix-color-success;
            }
        }

        &.error {
            .fb-path {
                &.trailer-outline {
                    fill: $uix-color-error;
                }

                &.trailer-box {
                    fill: rgba($uix-color-error, 0);
                }
            }

            .fa-circle-xmark {
                color: $uix-color-error;
            }
        }

        &.mt-60 {
            margin-top: 60px;
        }
    }
}

.fb-form-heading {
    > em {
        display: block;
        font-weight: normal;
    }
}

.fb-form-heading-group {
    margin: 5px 0 50px;

    .fb-form-sub-heading {
        margin-bottom: 0;
    }
}

.fb-form-paragraph {
    margin: 15px 0 60px;
    text-align: left;
}
</style>
