const globalConstants = {
    addressTypes: {
        customer: 'customer',
        hereMaps: 'hereMaps',
    },
    localStorageKeys: {
        availableLoadsKey: 'ldz',
        currentLoadInfoKey: 'cli',
    },
    reasonCodes: {
        cannotSaveQrToTrailer: 450,
        trailerAlreadyHasQr: 451,
        invalidQr: 452,
        qrAlreaduRegistered: 453,
        invalidPhoneNumber: 460,
        configurationError: 461,
        invalidMfaCode: 462,
        loadNotFound: 470,
        loadAlreadyCompleted: 472,
        trailerNotFound: 480,
        scannedIdNotFound: 481,
        scannedIdAlreadyAssigned: 482,
        carrierNotFound: 490,
        carrierNotActive: 491,
        driverAlreadyRegistered: 492,
    },
    httpStatusCodes: {
        success: 200,
        timeout: 408,
    },
    locale: {
        english: 'en',
        spanish: 'es',
        pirate: 'ps',
    },
    trailer: {
        loadedStatus: 0,
        emptyStatus: 1,
        assistance: {
            knight: 'Breakdown',
            swift: 'OnRoad',
        },
        statuses: {
            TRAILER_SERVICE_STATUS: 'S',
        },
        types: {
            DEDICATED_ROLL_DOOR: 'Dedicated Roll Door',
            DEDICATED_VAN: 'Dedicated Van',
            HIGH_CUBE: 'High Cube',
            ROLL_DOOR: 'Roll Door',
            STANDARD: 'Standard',
        },
        assessmentVarieties: {
            attach: 'attach',
            detach: 'detach',
        },
        assessmentTypes: {
            brakes: 'brakes',
            tires: 'tires',
            lights: 'lights',
            damage: 'damage',
            kingpinLanding: 'kingpinLanding',
            airlinesElectrical: 'airlinesElectrical',
            other: 'other',
            subTypes: {
                kingpin: 'kingpin',
                driverSide: 'driverSide',
                passengerSide: 'passengerSide',
                frontSide: 'frontSide',
                rearSide: 'rearSide',
                driverTop: 'driverTop',
                centerTop: 'centerTop',
                passengerTop: 'passengerTop',
                driverBottom: 'driverBottom',
                centerBottom: 'centerBottom',
                passengerBottom: 'passengerBottom',
                leftAirline: 'leftAirline',
                sevenWay: '7way',
                rightAirline: 'rightAirline',
                lowerAirlineElectrical: 'lowerAirlineElectrical',
            },
        },
        assessmentQuestions: {
            dotSafe:
                'Is the trailer still DOT compliant and safe to perform the load with?',
            willTakePossession:
                'Please confirm you will take possession despite damage.',
            willContactBreakdown:
                'Will you take possession and contact breakdown immediately?',
        },
        assessmentOutcomes: {
            virtualRedTag: 'Virtual Red Tag',
            tookPossession: 'Took Possession',
            declinedPossession: 'Declined Possession',
            unspecified: 'Unspecified',
        },
    },
    keyboard: {
        key: {
            backspace: 'Backspace',
            digits: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
        },
        code: {
            backspace: 'Backspace',
            digits: [
                'Digit1',
                'Digit2',
                'Digit3',
                'Digit4',
                'Digit5',
                'Digit6',
                'Digit7',
                'Digit8',
                'Digit9',
                'Digit0',
            ],
        },
    },
    popupTimeoutInSeconds: 5,
    map: {
        name: 'Pinpoint',
        menu: {
            directions: 'directions',
            attach: 'attach',
            share: 'share',
        },
    },
};

export default globalConstants;
