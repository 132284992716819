<template>
    <div>
        <div class="fb-container body">
            <img
                class="fb-form-logo"
                src="../../../public/img/knx_logistics.png"
                alt="Knight Swift Logistics"
            />
            <div
                v-if="isSearching == false && trailerId == null"
                class="fb-form"
            >
                Truck ID:<input v-model="truckId" placeholder="Truck Id" />
                <img
                    class="fb-scan"
                    src="../../../public/img/scan_for_trailer.png"
                    alt="Scan for Trailer"
                    @click="onSearchClicked"
                />
            </div>
            <div v-else class="fb-form">
                <p>{{ searchingLabel }}</p>
                <p v-if="trailers != null">Found!!</p>
                <p>{{ lat }} || {{ lon }}</p>
                <button class="fb-form-button" @click="onStopSearchClicked">
                    Stop Search
                </button>
                <div
                    v-for="trailer in trailers"
                    :key="trailer.trailerId"
                    class="list-item"
                    :class="test"
                    tabindex="0"
                >
                    <p class="fb-form-paragraph">
                        Trailer: <strong>{{ trailer.trailerId }}</strong> ({{
                            trailer.company
                        }})
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        company: 'knight',
        isSearching: false,
        lat: null,
        lon: null,
        searchingLabel: 'Searching',
        timerInterval: null,
        trailerId: null,
        trailers: null,
        truckId: '312693',
    }),
    methods: {
        onSearchClicked() {
            this.startSearch();
        },
        onStopSearchClicked() {
            this.isSearching = false;
            this.trailerId = null;
            this.trailers = null;
            clearInterval(this.timerInterval);
        },
        async startSearch() {
            this.isSearching = true;

            if (this.truckId !== '') {
                var result = await this.$store.dispatch(
                    'getTrailersNearbyTruck',
                    {
                        company: this.company,
                        truckId: this.truckId,
                    }
                );

                if (result.isFound === true) {
                    this.trailers = result.trailers;
                    this.searchingLabel = null;
                }
            } else {
                navigator.geolocation.getCurrentPosition(
                    this.getGeolocationSuccess,
                    this.getGeolocationError
                );
            }
        },
        getGeolocationError(err) {
            console.warn(`ERROR(${err.code}): ${err.message}`);
        },
        async getGeolocationSuccess(pos) {
            var result = await this.$store.dispatch('getTrailersNearby', {
                lat: pos.coords.latitude,
                lon: pos.coords.longitude,
            });
            this.lat = pos.coords.latitude;
            this.lon = pos.coords.longitude;
            this.trailers = result.trailers;
            if (result.isFound === true) {
                this.searchingLabel = null;
            } else if (
                result.isFound === false &&
                this.searchingLabel === null
            ) {
                this.searchingLabel = 'Searching';
            }
        },
    },
};
</script>

<style scoped>
.fb-scan {
    width: 100%;
}
</style>
