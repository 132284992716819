<template>
    <div>
        <div class="fb-container body">
            <div class="fb-form">
                <h1 class="fb-form-heading">{{ $t(headingText) }}</h1>
                <div :class="scrollClass">
                    <fbx-load-card
                        v-for="load in availableLoads
                            ? availableLoads
                            : [currentLoad]"
                        :key="load?.loadNumber"
                        :current-load="load"
                        :show-buttons="showButtons"
                        @confirm="onYesClicked(load?.loadNumber)"
                    />
                </div>
                <button
                    type="button"
                    class="btn fb-form-button"
                    @click="onNoClicked"
                >
                    <em>{{ $t(rejectButtonText) }}</em>
                </button>
            </div>
        </div>

        <ConfirmationOverlay
            v-if="$store.state.confirmationShown"
            :titleText="$t('LoadConfirmation.confirmationTitle')"
            :subtitleText="$t('LoadConfirmation.confirmationSubtitle')"
            :acceptText="$t('LoadConfirmation.acceptText')"
            :rejectText="$t('LoadConfirmation.rejectText')"
            acceptTheme="primary"
            rejectTheme="return"
            acceptAction="confirmKnownLoad"
            acceptDestination="AttachTrailerSuccess"
            :acceptActionArgs="loadNumber"
        />
    </div>
</template>

<script>
import { isNullOrUndefined } from '@/shared/helpers';
import { isEmpty } from 'lodash';
import FbxLoadCard from '../components/fbx-load-card.vue';
import FbaApiError from '../models/ApiError';
import globalConstants from '../plugins/globalConstants';
import ConfirmationOverlay from '../components/ConfirmationOverlay.vue';

export default {
    components: { FbxLoadCard, ConfirmationOverlay },
    data: () => ({
        user: null,
        hasLoad: null,
        availableLoads: null,
        currentLoad: null,
        loadNumber: null,
    }),
    computed: {
        headingText() {
            return this.availableLoads?.length > 1
                ? 'LoadConfirmation.headingMultiple'
                : 'LoadConfirmation.heading';
        },
        showButtons() {
            return this.availableLoads?.length > 1 ? false : true;
        },
        rejectButtonText() {
            return this.availableLoads?.length > 1
                ? 'LoadConfirmation.rejectButtonMultiple'
                : 'LoadConfirmation.rejectButton';
        },
        scrollClass() {
            return this.availableLoads?.length > 1
                ? 'card-scroll active'
                : 'card-scroll';
        },
    },
    async created() {
        this.setLoading(true);
        const validUser = this.$store.getters.validateUserDetails;
        if (!validUser) {
            this.$router.push({ name: 'GetStarted' });
            return;
        }
        this.user = this.$store.getters.getUserDetails;
        await this.getCurrentLoad();
        this.setLoading(false);
    },
    methods: {
        async getCurrentLoad() {
            const loadInfo =
                this.$store.getters['loadStore/getCurrentLoadInfo'];

            if (isNullOrUndefined(loadInfo?.loadNumber)) {
                const response = await this.$store.dispatch(
                    'loadStore/getAttachedLoadByDriverNumber',
                    this.user?.mobileNumber
                );
                if (
                    response instanceof FbaApiError &&
                    response.statusCode ===
                        globalConstants.httpStatusCodes.timeout
                ) {
                    this.$router.push({ name: history.go(-1) });
                    this.setLoading(false);
                    return;
                }
                this.currentLoad = response;
            } else {
                this.currentLoad = loadInfo;
            }

            if (!this.currentLoad) {
                const response = await this.$store.dispatch(
                    'loadStore/getAllLoadsByDriverNumber',
                    this.user?.mobileNumber
                );
                if (
                    response instanceof FbaApiError &&
                    response.statusCode ===
                        globalConstants.httpStatusCodes.timeout
                ) {
                    this.$router.push({ name: history.go(-1) });
                    this.setLoading(false);
                    return;
                }
                if (isEmpty(response)) this.$router.push({ name: 'FindLoad' });
                this.availableLoads = response;
            }
        },
        async onYesClicked(loadNumber) {
            this.loadNumber = loadNumber;
            this.$store.dispatch('showConfirmation');
        },
        onNoClicked() {
            this.$store.commit('loadStore/clearCurrentLoadInfo');
            this.$router.push({ name: 'FindLoad' });
        },
    },
};
</script>

<style lang="scss" scoped>
.fb-form-heading {
    padding: 30px 5px;
}
.fb-container {
    padding: 0px;
}
.card-scroll {
    max-height: 65vh;
    padding: 0 20px;
    overflow-y: auto;

    &.active {
        border-top: 2px solid $uix-navbar-border-color;
        border-bottom: 2px solid $uix-navbar-border-color;
    }
}
</style>
