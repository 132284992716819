<template>
    <div>
        <div class="fb-container body">
            <img
                class="fb-form-logo"
                src="../../public/img/knx_logistics.png"
                alt="Knight Swift Logistics"
            />
            <div class="fb-form">
                <h1 class="fb-form-heading">
                    {{ $t('UserSelection.title') }}
                </h1>
                <div class="fb-form-container">
                    <button class="fb-form-button" @click="adminClicked">
                        {{ $t('UserSelection.adminButton') }}
                    </button>
                    <button class="fb-form-button" @click="carrierClicked">
                        {{ $t('UserSelection.carrierButton') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        carrierClicked() {
            if (!this.$store.getters.validateUserDetails)
                this.$router.push({ path: '/getstarted' });
            else this.$router.push({ name: 'EnterTrailerDetails' });
        },
        adminClicked() {
            if (null) this.$router.push({ path: '/admin/trailerlookup' });
            else this.$router.push({ path: '/admin/signin' });
        },
    },
};
</script>
