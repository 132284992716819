<template>
    <div>
        <div class="fb-container body">
            <div class="fb-form">
                <div class="fb-form-image trailer-side">
                    <img
                        v-if="trailerCompany.toLowerCase() === 'swift'"
                        alt="Swift Transportation"
                        class="fb-logo-overlay swift-transportation"
                        src="../assets/swift-logo-icon-text.svg"
                    />
                    <img
                        v-else-if="trailerCompany.toLowerCase() === 'knight'"
                        alt="Knight Transportation"
                        class="fb-logo-overlay knight-transportation"
                        src="../assets/knight-logo-icon-text.svg"
                    />

                    <svg
                        version="1.1"
                        class="fb-svg trailer-side"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 280.5 96.5"
                        style="enable-background: new 0 0 280.5 96.5"
                        xml:space="preserve"
                    >
                        <path
                            class="fb-path trailer-outline"
                            d="M50.2,84.6c0-6.3,5.1-11.4,11.4-11.4l0,0c6.3,0,11.4,5.1,11.4,11.4l0,0C73.1,90.9,68,96,61.6,96 l0,0C55.3,96,50.2,90.9,50.2,84.6L50.2,84.6z M55.8,84.6c0,3.3,2.6,5.9,5.9,5.9l0,0c3.3,0,5.9-2.6,5.9-5.9l0,0 c0-3.3-2.6-5.9-5.9-5.9l0,0C58.4,78.7,55.8,81.3,55.8,84.6L55.8,84.6z M23.6,84.6c0-6.3,5.1-11.4,11.4-11.4l0,0 c6.3,0,11.4,5.1,11.4,11.4l0,0C46.4,90.9,41.3,96,35,96l0,0C28.7,96,23.6,90.9,23.6,84.6L23.6,84.6z M29.1,84.6 c0,3.3,2.6,5.9,5.9,5.9s5.9-2.6,5.9-5.9l0,0c0-3.3-2.6-5.9-5.9-5.9l0,0C31.8,78.7,29.1,81.3,29.1,84.6z M201.5,91.2V74.1h-99.8 l-21.5,6.5c0,0.1,0,0.2,0,0.3V87h-4.6c0.1-0.8,0.2-1.6,0.2-2.4c0-7.8-6.3-14.2-14.2-14.2c-6,0-11.3,3.8-13.3,9.4 c-2-5.6-7.3-9.4-13.3-9.4c-7.8,0-14.2,6.3-14.2,14.2c0,0.8,0.1,1.6,0.2,2.4h-4.4v-6.1c0-0.3,0-0.7,0.1-1L2.2,74.3v-7.9H280v5.3 h-73.5v19.5L201.5,91.2z M1,56v-6h4c0.6,0,1,0.4,1,1l0,0v4c0,0.6-0.4,1-1,1l0,0H1z M1,38v-6h4c0.6,0,1,0.4,1,1l0,0v4 c0,0.6-0.4,1-1,1l0,0H1z M1,20v-6h4c0.6,0,1,0.4,1,1l0,0v4c0,0.6-0.4,1-1,1l0,0H1z"
                        />
                        <g>
                            <path
                                class="fb-path trailer-box"
                                d="M278.5,67.5H1.5V6.8c0-2.9,2.4-5.3,5.3-5.3h266.5c2.9,0,5.3,2.4,5.3,5.3L278.5,67.5L278.5,67.5z"
                            />
                            <path
                                class="fb-path trailer-outline"
                                d="M6.8,3C4.7,3,3,4.7,3,6.8V66h274V6.8c0-2.1-1.7-3.8-3.8-3.8L6.8,3 M6.8,0h266.5c3.7,0,6.8,3,6.8,6.8V69H0V6.8 C0,3,3,0,6.8,0z"
                            />
                        </g>
                    </svg>
                </div>

                <div class="fb-form-heading-group mb-60">
                    <h1 class="fb-form-heading">
                        {{ $t('AttachTrailer.title') }}
                    </h1>
                    <span class="fb-form-sub-heading mb-60"
                        >{{ $t('AttachTrailer.dryVan') }} #{{ trailerId }}</span
                    >
                </div>

                <button
                    type="button"
                    class="btn btn-primary fb-form-button"
                    @click="onAttachClicked"
                >
                    {{ $t('AttachTrailer.submitButton') }}
                </button>
                <p
                    class="fb-form-paragraph fb-form-paragraph-fineprint-heading"
                />

                <div class="fb-form-button-group footer-buttons">
                    <button
                        type="button"
                        class="btn btn-link fb-form-button"
                        @click="onScanClicked"
                    >
                        <em>{{ $t('Scan.differentTrailerButton') }}</em>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import globalConstants from '../plugins/globalConstants';

export default {
    data: () => ({
        qid: null,
        trailerId: null,
        trailerData: null,
        trailerCompany: null,
        daysStationary: null,
        cargoStatus: null,
    }),
    computed: {
        cargoStatusText() {
            if (this.cargoStatus === globalConstants.trailer.loadedStatus)
                return 'AttachTrailer.loaded';
            else if (this.cargoStatus === globalConstants.trailer.emptyStatus)
                return 'AttachTrailer.empty';
            return '';
        },
    },
    created() {
        this.initializeStoreValues();
        this.checkTrailerStatus();
        this.setLoading(false);
    },
    methods: {
        initializeStoreValues() {
            this.trailerData = this.$store.getters.getCurrentTrailerInfo;
            this.trailerId = this.trailerData?.trailerId;
            this.trailerCompany = this.trailerData?.trailerCompany;
            this.daysStationary = this.trailerData?.daysStationary;
            this.cargoStatus = this.trailerData?.cargoStatus;
        },
        onAttachClicked() {
            this.setLoading(true);
            this.$store.commit('saveIsAttached', true);
            this.$store.commit('saveIsTrailerAttachmentEvent', true);
            this.$router.push({ name: 'TrailerAssessment' });
        },
        onScanClicked() {
            this.setLoading(true);
            this.$router.push({ name: 'Scan' });
        },
        checkTrailerStatus() {
            if (
                this.trailerData.trailerStatus ===
                globalConstants.trailer.statuses.TRAILER_SERVICE_STATUS
            ) {
                this.$router.push({ name: 'TrailerUnavailable' });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.fb-form-paragraph {
    margin-top: 30px;
    font-size: 0.75em;
}

.fb-form-paragraph-fineprint {
    font-style: italic;
    text-align: left;
    margin-left: 100px;
    margin-top: 0px;
}

.fb-form-paragraph-fineprint-heading {
    font-style: italic;
    font-weight: bold;
    text-align: left;
    margin-left: 70px;
    margin-right: 70px;
}

.fb-logo-overlay {
    height: 44px;
    left: 50%;
    position: absolute;
    top: 50%;
    z-index: 1;

    &.knight-transportation {
        margin: -39px 0 0 -94px;
    }

    &.swift-transportation {
        margin: -37px 0 0 -72px;
    }
}

.fb-svg {
    &.trailer-side {
        height: 99.7639px;
        margin: 0;
        position: relative;
        width: 290px;
        z-index: 0;
    }
}

.fb-path {
    &.trailer-outline {
        fill: $uix-heading-text-color;
    }

    &.trailer-box {
        fill: rgba($uix-heading-text-color, 0);
    }
}

.fb-form-image {
    &.trailer-side {
        color: $uix-heading-text-color;
        display: inline-block;
        margin: 40px auto 0;
        position: relative;

        .fa-circle-question,
        .fa-circle-check,
        .fa-circle-xmark {
            font-size: 32px;
            left: 50%;
            margin: -30px 0 0 -16px;
            position: absolute;
            top: 50%;
            z-index: 1;
        }

        &.success {
            .fb-path {
                &.trailer-outline {
                    fill: $uix-color-success;
                }

                &.trailer-box {
                    fill: rgba($uix-color-success, 0);
                }
            }

            .fa-circle-check {
                color: $uix-color-success;
            }
        }

        &.error {
            .fb-path {
                &.trailer-outline {
                    fill: $uix-color-error;
                }

                &.trailer-box {
                    fill: rgba($uix-color-error, 0);
                }
            }

            .fa-circle-xmark {
                color: $uix-color-error;
            }
        }

        &.mt-60 {
            margin-top: 60px;
        }
    }
}

.fb-form-heading-group {
    margin: 20px 0 30px;

    .fb-form-heading {
        margin-top: 0;
    }

    .fb-form-sub-heading {
        margin-bottom: 0;
    }

    &.mb-60 {
        margin-bottom: 60px;
    }
}
</style>
