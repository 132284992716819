<template>
    <div class="wrapper-container">
        <div class="main-container">
            <div class="map-container">
                <div ref="customMapEl" class="custom-map" />
            </div>

            <div class="address-container">
                <div class="customer-content">
                    <LinkExpiration v-if="isExpired" />

                    <div v-else class="customer-content-container">
                        <img
                            alt="Freightbox Logo"
                            src="../../assets/freightbox-logo-icon-gray-bg.svg"
                        />
                        <h1 v-if="!showAttach">{{ headerText }}</h1>

                        <div
                            v-if="showShareLocation"
                            class="share-location-container"
                        >
                            <slot
                                name="share-content"
                                :hasTrailer="hasTrailer"
                                :customerName="customerName"
                                :geoLink="geoLink"
                                :trailerAddressLine1="trailerAddressLine1"
                                :trailerAddressLine2="trailerAddressLine2"
                                :subheaderText="subheaderText"
                                :toggleMenu="toggleMenu"
                            />
                        </div>

                        <div v-else-if="showAttach" class="attach-container">
                            <slot
                                name="attach-trailer"
                                :trailer="trailer"
                                :toggleMenu="toggleMenu"
                            />
                        </div>

                        <LocationGroup
                            v-else
                            :hideBackground="showDirections"
                            :hasTrailer="hasTrailer"
                            :trailerId="trailer?.id"
                            :customerName="customerName"
                            :geoLink="geoLink"
                            :trailerAddressLine1="trailerAddressLine1"
                            :trailerAddressLine2="trailerAddressLine2"
                            :subheaderText="subheaderText"
                        />

                        <div v-if="showDirections">
                            <div class="exit">
                                <font-awesome-icon
                                    :icon="['fas', 'xmark']"
                                    @click="toggleMenu(null)"
                                />
                            </div>
                            <div class="customer-textarea">
                                {{ generalDirections }}
                            </div>
                        </div>

                        <slot
                            name="content-panel-menu"
                            :generalDirections="generalDirections"
                            :menuSelection="menuSelection"
                            :toggleMenu="toggleMenu"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import globalConstants from '@/plugins/globalConstants';
import trailerIconSvg from '@/assets/trailer-marker.svg';
import myLocationIconSvg from '@/assets/my-location-marker.svg';
import LocationGroup from './LocationGroup.vue';
import LinkExpiration from './LinkExpiration.vue';
import { loadScript } from 'vue-plugin-load-script';
import { isNullOrUndefined, tryGetCurrentPosition } from '@/shared/helpers.js';
import { DateTime } from 'luxon';

const { directions, attach, share } = globalConstants.map.menu;

export default {
    components: {
        LocationGroup,
        LinkExpiration,
    },
    data: () => ({
        defaultMapZoom: 16,
        headerText: '',
        subheaderText: '',
        trailer: null,
        menuSelection: null,
        mapService: null,
        hasTrailer: true,
        customMap: null,
        userBubble: null,
        trailerBubble: null,
        expiration: null,
        isExpired: false,
    }),
    computed: {
        generalDirections() {
            return this.hasGeneralDirections
                ? this.trailer.locationDetails.directions
                : '';
        },
        hasGeneralDirections() {
            return !!this.trailer?.locationDetails?.directions;
        },
        showAttach() {
            return this.menuSelection === attach;
        },
        showDirections() {
            return this.menuSelection === directions;
        },
        showShareLocation() {
            return this.menuSelection === share;
        },
        customerName() {
            if (!isNullOrUndefined(this.trailer?.locationDetails?.name)) {
                return this.trailer.locationDetails.name.trim().toLowerCase();
            }
            return '';
        },
        geoLink() {
            if (
                !isNullOrUndefined(this.trailer?.latitude) &&
                !isNullOrUndefined(this.trailer?.latitude)
            )
                return `http://maps.apple.com/?q=${this.trailer.latitude},${this.trailer.longitude}`;
            else return '#';
        },
        trailerAddressLine1() {
            if (this.hasCustomerAddress()) {
                let address = '';
                address +=
                    this.trailer?.locationDetails?.address?.line1
                        ?.trim()
                        .toLowerCase() + ' ';
                address +=
                    this.trailer?.locationDetails?.address?.line2
                        ?.trim()
                        .toLowerCase() + ' ';
                return address;
            } else if (this.hasHereMapsAddress()) {
                let address = '';
                if (
                    this.trailer.locationDetails.address.countryCode === 'USA'
                ) {
                    address += isNullOrUndefined(
                        this.trailer?.locationDetails?.address?.label
                    )
                        ? ''
                        : this.trailer?.locationDetails?.address?.label.substring(
                              0,
                              this.trailer.locationDetails.address.label.indexOf(
                                  this.trailer.locationDetails.address.city
                              ) - 2
                          );
                } else {
                    address += isNullOrUndefined(
                        this.trailer?.locationDetails?.address?.label
                    )
                        ? ''
                        : this.trailer?.locationDetails?.address?.label.substring(
                              0,
                              this.trailer.locationDetails.address.label.indexOf(
                                  this.trailer.locationDetails.address
                                      .postalCode
                              ) - 2
                          );
                }

                return address;
            } else if (
                !isNullOrUndefined(this.trailer?.latitude) &&
                !isNullOrUndefined(this.trailer?.longitude)
            ) {
                return `${this.trailer.latitude}, ${this.trailer.longitude}`;
            }
            return '';
        },
        trailerAddressLine2() {
            if (this.hasCustomerAddress()) {
                let address = '';
                address +=
                    this.trailer?.locationDetails?.address?.city
                        ?.trim()
                        .toLowerCase() + ', ' ?? '';
                address +=
                    this.trailer?.locationDetails?.address?.state?.trim() +
                        ' ' ?? '';
                address +=
                    this.trailer?.locationDetails?.address?.zip?.trim() ?? '';
                return address;
            } else if (this.hasHereMapsAddress()) {
                let address = '';
                address +=
                    this.trailer?.locationDetails?.address?.city
                        ?.trim()
                        .toLowerCase() + ', ' ?? '';
                address +=
                    this.trailer?.locationDetails?.address?.stateCode?.trim() +
                        ' ' ?? '';
                address +=
                    this.trailer?.locationDetails?.address?.postalCode?.trim() ??
                    '';
                return address;
            }
            return '';
        },
    },

    created() {
        this.setLoading(true);
    },
    async mounted() {
        /* Note: Needed to load the HERE Maps script files only on this page rather than on the entire FreightBox app, and therefore needed
         *       a way to ensure the first "core" file was loaded first due to dependencies from the other files. This vue plugin allows for
         *       dynamic script loading.
         */
        loadScript('https://js.api.here.com/v3/3.1/mapsjs-core.js')
            .then(() => {
                loadScript('https://js.api.here.com/v3/3.1/mapsjs-service.js');
                loadScript('https://js.api.here.com/v3/3.1/mapsjs-ui.js');
                loadScript(
                    'https://js.api.here.com/v3/3.1/mapsjs-mapevents.js'
                );
                loadScript(
                    'https://js.api.here.com/v3/3.1/mapsjs-clustering.js'
                );
                loadScript('https://js.api.here.com/v3/3.1/mapsjs-data.js');
            })
            .then(async () => {
                await this.checkAccessGrant();

                this.headerText = `${this.titleCase(
                    this.trailer?.trailerCompany
                )} Trailer #${this.trailer?.trailerId}`;
                if ((this.trailer || { isFound: false }).isFound === false) {
                    this.subheaderText = 'Trailer Not Found';
                    this.hasTrailer = false;
                    this.setLoading(false);
                }

                // Kick off process to render map based on current location, if applicable; Otherwise render the map based on trailer location alone

                const location = await tryGetCurrentPosition();
                if (isNullOrUndefined(location?.error)) {
                    this.currentLocation = location;
                }
                this.renderMap();
            });
    },

    methods: {
        async checkAccessGrant() {
            const response = await this.$store.dispatch(
                'getTrailerByAccessGrant'
            );

            if (response.trailer && response.expiration) {
                this.trailer = response.trailer;
                this.expiration = DateTime.fromISO(response.expiration).toUTC();
            } else {
                this.isExpired = true;
                this.trailer = null;
            }
        },
        checkExpiration() {
            // IF TTL expires, clear trailer and force a UI refresh without trailer
            if (
                this.isExpired === false &&
                DateTime.now().toUTC() >= this.expiration
            ) {
                this.isExpired = true;
                this.trailer = null;
                window.location.reload();
            }
        },
        hasCustomerAddress() {
            return (
                this.trailer?.locationDetails?.type ===
                globalConstants.addressTypes.customer
            );
        },
        hasHereMapsAddress() {
            return (
                this.trailer?.locationDetails?.type ===
                globalConstants.addressTypes.hereMaps
            );
        },
        toggleMenu(selection) {
            if (this.menuSelection === share && selection === null) {
                setTimeout(this.resizeMap, 500);
            }

            this.menuSelection = selection;

            this.openBubbles();
        },
        openBubbles() {
            this.userBubble.open();
            this.trailerBubble.open();
        },
        renderMap() {
            // Initialize map platform
            let platform = new H.service.Platform({
                apikey: `${process.env.VUE_APP_HERE_MAPS_API_KEY}`,
            });
            let mapDefaultLayers = platform.createDefaultLayers();

            // Initialize a map
            let viewportPadding = 50;
            let map = new H.Map(
                this.$refs.customMapEl,
                mapDefaultLayers.vector.normal.map,
                {
                    center: this.isExpired
                        ? {
                              lat: this.currentLocation?.coords?.latitude,
                              lng: this.currentLocation?.coords?.longitude,
                          }
                        : {
                              lat: this.trailer?.latitude,
                              lng: this.trailer?.longitude,
                          },
                    zoom: this.defaultMapZoom,
                    pixelRatio: window.devicePixelRatio || 1,
                    padding: {
                        bottom: viewportPadding,
                        left: viewportPadding + 10,
                        right: viewportPadding + 20,
                        top: viewportPadding + 30,
                    },
                }
            );

            // Add a resize listener to make sure that the map occupies the whole container
            window.addEventListener('resize', () => {
                if (!this.menuSelection) {
                    map.getViewPort().resize();
                }
            });

            // Make the map interactive
            let behavior = new H.mapevents.Behavior(
                new H.mapevents.MapEvents(map)
            );

            // create default UI with layers provided by the platform
            var ui = H.ui.UI.createDefault(map, mapDefaultLayers);

            // Create map markers and add to marker group
            let mapGroup = new H.map.Group();

            // Only show the users current location bubble if the link has expired
            if (!this.isExpired) {
                let icon = new H.map.Icon(trailerIconSvg);
                let trailerMarker = new H.map.Marker(
                    {
                        lat: this.trailer?.latitude,
                        lng: this.trailer?.longitude,
                    },
                    { icon }
                );

                mapGroup.addObject(trailerMarker);

                // Add bubble to trailer location
                let trailerBubble = new H.ui.InfoBubble(
                    {
                        lat: this.trailer?.latitude,
                        lng: this.trailer?.longitude,
                    },
                    { content: 'Trailer Location' }
                );
                ui.addBubble(trailerBubble);
                this.trailerBubble = trailerBubble;
            }

            // If location services are enabled then plot the circle on the map
            if (this.currentLocation) {
                let myIcon = new H.map.Icon(myLocationIconSvg);
                let currentLocationMarker = new H.map.Marker(
                    {
                        lat: this.currentLocation.coords.latitude,
                        lng: this.currentLocation.coords.longitude,
                    },
                    { icon: myIcon }
                );
                mapGroup.addObject(currentLocationMarker);

                // Add bubble to current location
                let currentBubble = new H.ui.InfoBubble(
                    {
                        lat: this.currentLocation.coords.latitude,
                        lng: this.currentLocation.coords.longitude,
                    },
                    { content: 'My Location' }
                );
                ui.addBubble(currentBubble);
                this.userBubble = currentBubble;

                // Set an interval to continually update the map with the current location (i.e. as the user moves the circle indicating the current location will update on the map)
                setInterval(() => {
                    this.checkExpiration();

                    navigator.geolocation.getCurrentPosition(
                        (currentLocation) => {
                            // Update the current location and tooltip of the individual on the map
                            currentLocationMarker.setGeometry({
                                lat: currentLocation.coords.latitude,
                                lng: currentLocation.coords.longitude,
                            });
                            currentBubble.setPosition({
                                lat: currentLocation.coords.latitude,
                                lng: currentLocation.coords.longitude,
                            });

                            map.getViewModel().setLookAtData({
                                bounds: mapGroup.getBoundingBox(),
                            });
                            this.updateSubheaderText();
                        }
                    );
                }, 5000);

                // Set geo bounding box for group and display on the map
                map.getViewModel().setLookAtData({
                    bounds: mapGroup.getBoundingBox(),
                });
            }

            // Add group to map
            map.addObject(mapGroup);
            this.customMap = map;

            //Get map service for performing reverse geocoding
            this.mapService = platform.getSearchService();
            this.updateSubheaderText();
            this.setTrailerLocationDetails();

            setTimeout(this.resizeMap, 3000);
        },
        resizeMap() {
            this.customMap.getViewPort().resize();
            this.setLoading(false);
        },
        async reverseGeocode(lat = '', lon = '') {
            return new Promise((resolve, reject) => {
                if (lat !== '' && lon !== '') {
                    this.mapService.reverseGeocode(
                        {
                            at: `${lat},${lon}`,
                        },
                        resolve,
                        reject
                    );
                }
            });
        },
        async setTrailerLocationDetails() {
            if (
                isNullOrUndefined(this.trailer?.latitude) ||
                isNullOrUndefined(this.trailer?.longitude)
            ) {
                return;
            }
            const customersAtLocation = await this.$store.dispatch(
                'getCustomersByLatLng',
                {
                    lat: this.trailer?.latitude,
                    lng: this.trailer?.longitude,
                    company: this.trailer?.trailerCompany,
                    limit: 1,
                }
            );
            if (customersAtLocation.length > 0) {
                const mostRecentlyActiveCustomer = customersAtLocation[0];
                this.trailer.locationDetails = {
                    address: mostRecentlyActiveCustomer.address,
                    name: mostRecentlyActiveCustomer.name,
                    directions: mostRecentlyActiveCustomer.generalDirections,
                    type: globalConstants.addressTypes.customer,
                };
            } else {
                const { items } = await this.reverseGeocode(
                    this.trailer?.latitude,
                    this.trailer?.longitude
                );
                if (items.length > 0) {
                    this.trailer.locationDetails = items[0];
                    this.trailer.locationDetails.type =
                        globalConstants.addressTypes.hereMaps;
                }
            }
        },
        updateSubheaderText() {
            let dt = new Date(),
                minutes =
                    dt.getMinutes().toString().length == 1
                        ? `0${dt.getMinutes()}`
                        : dt.getMinutes(),
                hours =
                    dt.getHours().toString().length == 1
                        ? `0${dt.getHours()}`
                        : dt.getHours(),
                seconds =
                    dt.getSeconds().toString().length == 1
                        ? `0${dt.getSeconds()}`
                        : dt.getSeconds();

            this.subheaderText = `Last updated: ${
                dt.getMonth() + 1
            }/${dt.getDate()}/${dt.getFullYear()} ${hours}:${minutes}:${seconds}`;
        },
    },
};
</script>

<style lang="scss">
.custom-map {
    .H_ib {
        top: -35px;

        .H_ib_body {
            background: $uix-shade-alt-five;
            border-radius: 6px 5px;
            border: 1px solid $uix-shade-tooltip;
            bottom: 0;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
            margin-bottom: 0;
            margin-right: -51px;

            .H_ib_close {
                display: none;
            }

            .H_ib_content {
                color: $uix-heading-text-color;
                font-family: 'Open Sans', Avenir, Helvetica, Arial, sans-serif;
                font-size: 0.75rem;
                font-weight: 600;
                line-height: 1.021rem;
                margin: 0;
                padding: 4px;
                white-space: nowrap;
            }
        }

        &.H_ib_top {
            .H_ib_tail {
                height: inherit;
                margin: 0;
                position: relative;
                width: inherit;

                &::before {
                    border-top-color: $uix-shade-tooltip;
                    border-width: 8px 5px 0px 5px;
                    bottom: -8px;
                    left: -5px;
                }

                &::after {
                    border-color: $uix-shade-alt-five transparent;
                    border-width: 8px 5px 0px 5px;
                    bottom: -7px;
                    left: -5px;
                }
            }
        }
    }

    .H_ui {
        > .H_ib:first-child {
            top: -38px;

            .H_ib_body {
                margin-right: -70px;
            }
        }
    }

    .H_l_bottom {
        .H_l_vertical {
            .H_zoom {
                display: none;
            }
        }

        .H_l_horizontal {
            .H_scalebar,
            .H_ctl {
                display: none;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
.wrapper-container {
    align-items: center;
    background: $uix-shade-alt-one;
    display: flex;
    flex-wrap: nowrap;
    height: var(--innerHeight);
    justify-content: center;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;

    .main-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        height: 100%;
        max-width: 800px;
        position: relative;
        width: 100%;

        .map-container {
            height: 100%;
        }

        .custom-map {
            height: 100%;
        }

        .address-container {
            bottom: 0;
            color: $uix-paragraph-secondary-color;
            left: 0;
            width: 100%;
            z-index: 2;
        }

        .customer-content {
            align-items: center;
            background: #f5f4f2
                linear-gradient(
                    180deg,
                    rgba($color: $uix-shade-white, $alpha: 0.8) 0%,
                    rgba($color: $uix-shade-white, $alpha: 0) 100%,
                    rgba($color: $uix-shade-white, $alpha: 0.2) 100%
                );
            border-radius: 10px 10px 0 0;
            display: flex;
            filter: drop-shadow(0px 0px 24px rgba(29, 29, 29, 0.25));
            flex-direction: column;
            margin: 0 auto;
            max-width: 800px;
            padding: 45px 20px 0 20px;
            position: relative;

            .customer-content-container {
                width: 100%;
            }

            img {
                left: 10px;
                position: absolute;
                top: -45px;
            }

            &::before {
                background: $uix-shade-alt-three;
                border-radius: 22px;
                content: '';
                display: block;
                height: 5px;
                left: calc(50% - 26px);
                position: absolute;
                top: 9px;
                width: 52px;
            }
        }

        h1 {
            font-size: 1.313rem;
            font-weight: 700;
            line-height: 1.813rem;
            margin-bottom: 0;
        }

        .share-location-container {
            width: 100%;
        }

        .attach-container {
            width: 100%;
        }

        .exit {
            width: calc(100% - 1.313rem);
            text-align: right;
            height: 0px;
            position: relative;
            bottom: 153px;

            svg {
                cursor: pointer;
                height: 27px;
                width: 27px;
                color: #c7c6c4;
            }
        }

        .customer-textarea {
            background: $uix-shade-white;
            border-radius: 4px;
            border: 1px solid $uix-shade;
            color: #545454;
            flex: 1;
            font-size: 0.938rem;
            font-weight: 500;
            min-height: 200px;
            max-height: 200px;
            line-height: 1.25rem;
            text-align: left;
            margin-bottom: 20px;
            overflow-x: hidden;
            overflow-y: auto;
            padding: 10px 15px;
            resize: none;
            width: 100%;

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-track {
                background: $uix-shade-white;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #9e9d9a;
                border-radius: 4px;
                border: 0;
            }
        }
    }
}
</style>
