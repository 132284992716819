<template>
    <div class="customer-directions">
        <div class="menu-items d-flex flex-row justify-content-center">
            <div
                :class="{
                    selected: showDirections,
                    'disabled left': !hasGeneralDirections,
                }"
                @click="toggleDirections"
            >
                <font-awesome-icon :icon="['fas', 'info']" />
                <h3>General Info</h3>
            </div>
        </div>
    </div>
</template>

<script>
import globalConstants from '@/plugins/globalConstants.js';

const { directions } = globalConstants.map.menu;

export default {
    name: 'AssetMapMenu',
    emits: ['toggleMenu'],
    props: {
        generalDirections: {
            type: String,
            default: '',
        },
        menuSelection: {
            type: String,
            default: null,
        },
    },
    methods: {
        toggleDirections() {
            let selection =
                this.menuSelection === directions ? null : directions;

            this.$emit('toggleMenu', selection);
        },
    },
    computed: {
        hasGeneralDirections() {
            return this.generalDirections !== '';
        },
        showDirections() {
            return this.menuSelection === directions;
        },
    },
};
</script>

<style lang="scss" scoped>
.customer-directions {
    width: 100%;
    margin: 0 0 2.125rem 0;

    .menu-items {
        width: fit-content;
        border: 1px solid $uix-shade-800;
        border-radius: 6px;
        margin: 0 auto;
        width: 100%;
        max-width: 388px;

        .selected {
            background: $uix-shade-primary;
        }

        .disabled {
            pointer-events: none;
            color: $uix-shade-700;

            .left {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            .right {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            h3 {
                color: inherit;
            }
        }

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            min-height: 40px;
            width: 100%;

            svg {
                max-height: 11.25px;
                margin-left: 5px;
            }

            h3 {
                color: #615f5a;
                font-size: 0.875rem;
                font-weight: 700;
                line-height: 1.25rem;
                margin: 0 0 0 0.688rem;
                user-select: none;
            }
        }
    }
}
</style>
