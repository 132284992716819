import ApiError from '../models/ApiError';
import store from '../store';
import apmTransactions from '../libraries/apm/transactions';
import globalConstants from './globalConstants';

export default async function (axiosError) {
    if (axiosError.response) {
        //Got response from API but it was an error
        let apiError = new ApiError(
            axiosError.response.data?.message,
            axiosError.response.data?.reasonCode,
            axiosError.response.status
        );
        return apiError;
    } else if (axiosError.request) {
        //Made a request but didn't get a response
        console.error("Can't Reach Api");
        apmTransactions.instrumentApiTimeout();
        store.dispatch('setAlertPopup', 'AlertPopup.timeout');
        return new ApiError(
            'No Response From API',
            null,
            globalConstants.httpStatusCodes.timeout
        );
    } else {
        //Something else
        console.error('Axios Error', axiosError.message);
        apmTransactions.instrumentUnhandledApiResponse();
        store.dispatch('setAlertPopup', 'AlertPopup.timeout');
        return new ApiError(null, null, null);
    }
}
