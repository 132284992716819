<!-- [INGEE - 12/30/2022] NOTE: in the effort to decouple our confirmation overlay from vuex actions and simplify our modals, please use the 'LightweightConfirmationOverlay' component as we phase this overlay out -->

<template>
    <div class="background">
        <div class="message-container">
            <div class="header-container">
                <font-awesome-icon v-if="!!icon" :icon="icon" size="2xl" />
                <span class="title" v-html="titleText"></span>
            </div>
            <span
                class="subtitle"
                :class="subtitleStyle"
                v-html="subtitleText"
                v-if="!!subtitleText"
            ></span>
            <br v-if="!!subtitleText" />
            <br />
            <slot name="warning"></slot>
            <div class="button-container">
                <FbxButton
                    :button-text="acceptText"
                    :theme="acceptTheme"
                    @click="acceptClicked"
                />
                <FbxButton
                    :button-text="rejectText"
                    :theme="rejectTheme"
                    :class="{ noReject }"
                    @click="rejectClicked"
                />
            </div>
        </div>
    </div>
</template>

<script>
import FbxButton from './fbx-button.vue';
import { isNullOrUndefined } from '@/shared/helpers';

export default {
    props: {
        titleText: String,
        subtitleText: String,
        subtitleStyle: String,
        acceptText: {
            type: String,
            default: 'Accept',
        },
        rejectText: {
            type: String,
            default: 'Reject',
        },
        acceptTheme: {
            type: String,
            default: 'primary',
        },
        rejectTheme: {
            type: String,
            default: 'return',
        },
        rejectAction: {
            type: String,
            default: 'hideConfirmation',
        },
        acceptAction: String,
        acceptDestination: String,
        rejectDestination: String,
        acceptActionArgs: Object,
        rejectActionArgs: Object,
        noReject: {
            type: Boolean,
            default: false,
        },
        icon: String,
    },
    components: {
        FbxButton,
    },
    methods: {
        async acceptClicked() {
            if (this.acceptAction === 'skip') {
                this.$emit('confirmStep', { didAccept: true });
                return;
            }
            this.$store.dispatch('hideConfirmation');
            await this.$store.dispatch(
                this.acceptAction,
                this.acceptActionArgs
            );
            this.$router.push({ name: this.acceptDestination });
        },
        async rejectClicked() {
            if (this.rejectAction === 'skip') {
                this.$emit('confirmStep', { didAccept: false });
                return;
            }
            this.$store.dispatch('hideConfirmation');
            if (
                this.rejectAction !== 'hideConfirmation' &&
                !isNullOrUndefined(this.rejectAction)
            ) {
                await this.$store.dispatch(
                    this.rejectAction,
                    this.rejectActionArgs
                );
                this.$router.push({ name: this.rejectDestination });
            }
        },
    },
    created() {
        document.activeElement.blur();
    },
    beforeMount() {
        document.body.classList.add('modal-open');
    },
    beforeUnmount() {
        document.body.classList.remove('modal-open');
    },
};
</script>

<style lang="scss">
.red {
    color: $uix-color-error;
    font-weight: bold;
}

.message-container {
    background-color: #f5f4f2;
    position: fixed;
    bottom: 0px;
    width: 100%;
    border: 4px solid #e0dfdd;
    border-radius: 16px 16px 0px 0px;
    z-index: 101;
    padding: 10px 10px 0px 10px;
}

.header-container {
    display: flex;
    flex-direction: column;
}

.button-container {
    display: flex;
    flex-direction: column;
    max-width: 376px;
    margin: auto;
}

.background {
    background-color: #333333aa;
    height: calc(100% + 80px);
    width: 100%;
    position: fixed;
    top: -80px;
    z-index: 100;
}

.title {
    font-weight: bold;
    font-size: min(4vw, 1.2rem);

    &:not(:only-child) {
        margin-top: 1rem;
    }
}

.subtitle {
    font-style: italic;
    font-size: min(3.5vw, 1.15rem);
}

.strong-subtitle {
    font-style: normal;
    font-size: min(3.5vw, 1.15rem);
}

.noReject {
    visibility: hidden;
}
</style>
