/* eslint-disable no-console */
import { register } from 'register-service-worker';

if (!process.env.VUE_APP_TACLITE_ROOT_URL?.includes('localhost')) {
    register(`${process.env.BASE_URL}fba-service-worker.js`, {
        async ready() {
            console.log(
                'App is being served from cache by a service worker.\n' +
                    'For more details, visit https://goo.gl/AFskqB'
            );
        },
        registered() {
            console.log('Service worker has been registered.');
        },
        cached() {
            console.log('Content has been cached for offline use.');
        },
        updatefound() {
            console.log('New content is downloading.');
        },
        updated(registration) {
            // If new service worker waiting, post message to the incoming serviceworker to skipwaiting and install itself
            // Then, refresh the window to show the newly cached content incoming with serviceworker
            if (registration.waiting) {
                registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                window.location.reload();
            }
            // Consideration - manually give user the option to post this message so the UI doesnt automatically go through refresh
        },
        offline() {
            console.log(
                'No internet connection found. App is running in offline mode.'
            );
        },
        error(error) {
            console.error('Error during service worker registration:', error);
        },
    });
}
