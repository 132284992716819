export function getTrailerIdentifier(state) {
    const { qid: qrCode, trailerId, trailerCompany } = state.currentTrailerInfo;
    const compositeId = `${trailerId}|${trailerCompany}`;

    return qrCode ? { qrCode } : { compositeId };
}

export function getDriverIdentifier(state) {
    return { mobileNumber: state.userDetails.mobileNumber };
}
