<template>
    <button
        v-if="!usesIcon"
        type="button"
        class="fb-control-button"
        @click="$emit('clicked')"
    >
        {{ buttonText }}
    </button>
    <button
        v-else
        type="button"
        class="fb-control-button"
        @click="$emit('clicked')"
    >
        <font-awesome-icon :icon="[iconType, iconName]" />
    </button>
</template>

<script>
import BaseStyleControl from '../styles/baseStyleControl';
const buttonThemes = BaseStyleControl.buttonThemes;

export default {
    name: 'FbxInputInlineButton',
    props: {
        buttonText: {
            type: String,
            default: '',
        },
        theme: {
            type: String,
            default: 'primary',
            validator(theme) {
                if (!buttonThemes.includes(theme)) {
                    console.warn(
                        `fbx-button has invalid theme - ${theme}. valid themes are ${buttonThemes}`
                    );
                    return false;
                } else {
                    return true;
                }
            },
        },
        usesIcon: {
            type: Boolean,
            default: false,
        },
        iconType: {
            type: String,
            default: '',
        },
        iconName: {
            type: String,
            default: '',
        },
    },
    emits: ['clicked'],
    setup: (props) => {},
    data: () => {
        return {};
    },
    computed: {},
    watch: {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.wide {
    width: 100%;
}

.fb-control-button {
    color: $uix-shade-600;
    background-color: $uix-shade-white;
    border: 1px solid $uix-shade-900;
    width: 60px;
    padding: 15px 0px;
    position: relative;
    margin-left: -4px;
    border-radius: 0px $uix-control-border-radius $uix-control-border-radius 0px;
    height: 58px;
    transition: 0.2s;

    &:hover {
        color: $uix-shade-white;
        background-color: $uix-color-primary;
    }

    &:active {
        background-color: $uix-shade-200;
    }
}
</style>
