<template>
    <div class="tc-container">
        <img
            v-if="company == 'knight'"
            class="tc-form-logo"
            src="../../../public/img/knight_logo.png"
            alt="Knight Transportation"
        />
        <img
            v-if="company == 'swift'"
            class="tc-form-logo"
            src="../../../public/img/swift_logo.png"
            alt="Swift Transportation"
        />
        <div v-if="scannedId == null" class="tc-form">
            <p class="tc-form-paragraph">
                {{ $t('Asset.TrailerScan.landing.title') }}
            </p>
            <div class="tc-form-group">
                <div class="center stream tc-scanner">
                    <qrcode-stream @decode="onDecode" />
                </div>
            </div>
        </div>
        <div v-if="scannedId != null && isSuccessful == null" class="tc-form">
            <p class="tc-form-paragraph">
                {{ $t('Asset.TrailerScan.scannedSuccess.title') }}
            </p>
            <div
                class="tc-button-group"
                role="group"
                aria-label="Organization Options"
            >
                <button
                    type="button"
                    class="tc-group-button"
                    :class="isAttached == true ? 'active' : ''"
                    @click="onAttachStatusClicked(true)"
                >
                    {{ $t('Asset.TrailerScan.attachTrailer') }}
                </button>
                <button
                    type="button"
                    class="tc-group-button"
                    :class="isAttached == false ? 'active' : ''"
                    @click="onAttachStatusClicked(false)"
                >
                    {{ $t('Asset.TrailerScan.detachTrailer') }}
                </button>
            </div>
            <div v-if="isAttached != null">
                <button class="tc-form-button" @click="onSaveClicked">
                    {{ $t('Asset.TrailerScan.submitButton') }}
                </button>
            </div>
        </div>
        <div v-if="isSuccessful == true" class="tc-form">
            {{ $t('Asset.TrailerScan.saveSuccess.title') }}
        </div>
        <div v-if="isSuccessful == false" class="tc-form">
            {{ $t('Asset.TrailerScan.saveFailed.title') }}
        </div>
    </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
export default {
    components: {
        QrcodeStream,
    },
    data: () => ({
        company: null,
        companyLogo: 'swift_logo.png',
        isAttached: null,
        isSuccessful: null,
        scannedId: null,
        truckId: null,
    }),
    created() {
        this.truckId = this.$route.params.truckId;
        var values = this.truckId.split('|');
        this.company = values[1].toLowerCase();
    },
    methods: {
        onAttachStatusClicked(val) {
            this.isAttached = val;
        },
        onDecode(decodedString) {
            var values = decodedString.split('/');
            this.scannedId = values[values.length - 1];
        },
        async onSaveClicked() {
            var response = await this.$store.dispatch(
                'associateAssetTruckToTrailer',
                {
                    scanAction: this.isAttached,
                    scannedId: this.scannedId,
                    truckId: this.truckId,
                }
            );
            this.isSuccessful = response;
        },
    },
};
</script>

<style scoped>
.tc-scanner {
    width: 260px;
}
.tc-form-link {
    color: #ec9724;
    text-decoration: none;
}
.tc-form-link:focus,
.tc-form-link:hover {
    text-decoration: underline;
}
.tc-group-button {
    background-color: #fff;
    border: 1px solid #bec6cc;
    border-radius: 4px;
    color: #999;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-style: italic;
    font-weight: normal;
    line-height: 1.5;
    padding: 10px 15px;
    position: relative;
    text-align: center;
    width: 50%;
    z-index: 0;
}
.tc-group-button:hover {
    cursor: pointer;
}
.tc-group-button.active {
    border-color: #ec9724;
    color: #ec9724;
    font-style: normal;
    font-weight: 700;
    border-width: 2px;
    z-index: 1;
}
.tc-button-group {
    align-content: flex-start;
    align-items: stretch;
    display: flex;
    flex-flow: row nowrap;
    gap: 0;
    justify-content: center;
    margin: 0 0 15px;
    width: 100%;
}
.tc-button-group > .tc-group-button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: -1px;
}
.tc-button-group > .tc-group-button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
</style>
