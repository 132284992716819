<template>
    <CustomWaldoMap>
        <!-- TODO: add trailer scan slot -->

        <template v-slot:attach-trailer="{ trailer, toggleMenu }">
            <ThirdPartyAttachTrailer
                :trailer="trailer"
                @toggleMenu="toggleMenu"
            />
        </template>

        <template
            v-slot:share-content="{
                hasTrailer,
                customerName,
                geoLink,
                trailerAddressLine1,
                trailerAddressLine2,
                subheaderText,
                toggleMenu,
            }"
        >
            <ShareTrailer
                :hasTrailer="hasTrailer"
                :customerName="customerName"
                :geoLink="geoLink"
                :trailerAddressLine1="trailerAddressLine1"
                :trailerAddressLine2="trailerAddressLine2"
                :subheaderText="subheaderText"
                :submitting="submittingShareTrailer"
                @toggle-menu="toggleMenu"
                @share-location="
                    async (e) => {
                        const shared = await onShareTrailer(e);

                        if (shared) {
                            toggleMenu(null);
                        }
                    }
                "
            />
        </template>

        <template
            v-slot:content-panel-menu="{
                generalDirections,
                menuSelection,
                toggleMenu,
            }"
        >
            <ThirdPartyMapMenu
                :generalDirections="generalDirections"
                :menuSelection="menuSelection"
                @toggleMenu="toggleMenu"
            />
        </template>
    </CustomWaldoMap>
</template>

<script>
import ThirdPartyMapMenu from '@/views/Map/ThirdParty/ThirdPartyMapMenu.vue';
import ThirdPartyAttachTrailer from '@/views/Map/ThirdParty/ThirdPartyAttachTrailer.vue';
import CustomWaldoMap from '../CustomWaldoMap.vue';
import ShareTrailer from '../ShareTrailer.vue';
import { mapActions } from 'vuex';

export default {
    components: {
        CustomWaldoMap,
        ThirdPartyMapMenu,
        ThirdPartyAttachTrailer,
        ShareTrailer,
    },
    data() {
        return { submittingShareTrailer: false };
    },
    methods: {
        ...mapActions({
            shareTrailer: 'shareTrailerLocationByThirdPartyMobileNumber',
        }),
        async onShareTrailer({ mobileNumber }) {
            const resourceAccessGrantId = this.$store.state.accessGrantId;

            this.submittingShareTrailer = true;

            let response;
            try {
                response = await this.shareTrailer({
                    mobileNumber,
                    resourceAccessGrantId,
                });
            } finally {
                this.submittingShareTrailer = false;
            }

            return response?.isSuccessful || false;
        },
    },
};
</script>
