<template>
    <div v-if="confirmationShown">
        <LightweightConfirmationOverlay
            acceptTheme="primary"
            rejectTheme="return"
            titleText="You have reported the following damage on this trailer. Please confirm this is accurate."
            acceptText="Yes"
            rejectText="No"
            @accept="onAccept"
            @reject="onReject"
        >
            <template v-slot:warning>
                <div
                    class="d-flex flex-column align-items-center slot-container"
                >
                    <div id="fbx-damage-list" class="damage-list">
                        <div
                            v-for="damage in recordedTypes"
                            :key="damage?.type"
                        >
                            - {{ listItemTitle(damage?.type) }}
                        </div>
                    </div>
                </div>
            </template>
        </LightweightConfirmationOverlay>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import LightweightConfirmationOverlay from '@/components/LightweightConfirmationOverlay.vue';
import { pickBy } from 'lodash';
import { mapGetters } from 'vuex';

const listItems = {
    brakes: 'Brakes',
    tires: 'Tires & Wheels',
    lights: 'Lights',
    damage: 'Body Damage',
    kingpinLanding: 'Kingpin & Landing Gear',
    airlinesElectrical: 'Airlines & Electrical',
    other: 'Other',
};

export default defineComponent({
    components: {
        LightweightConfirmationOverlay,
    },
    computed: {
        ...mapGetters({ trailerAssessment: 'trailerAssessment' }),
        confirmationShown() {
            return this.$route.query.confirmation === 'confirmdamage';
        },
        recordedTypes() {
            const types = pickBy(
                this.trailerAssessment,
                (type) => type?.typeHasDamage
            );

            return Object.values(types);
        },
    },
    methods: {
        onAccept() {
            this.$router.push({ name: 'TrailerAssessment' });
            this.$emit('acceptDamage');
        },
        onReject() {
            this.$router.push({ name: 'TrailerAssessment' });
        },
        listItemTitle(type) {
            return listItems[type];
        },
    },
});
</script>

<style lang="scss" scoped>
.slot-container {
    min-height: 130px;
}

.damage-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    font-weight: 500;
    font-size: 1rem;
    font-style: italic;
    text-transform: capitalize;
    margin-bottom: 20px;
    text-align: left;
    max-height: 88px;
}

.extra-wide {
    width: 400px;
}

.extra-wide div {
    width: 200px;
}

.critical-damage {
    font-style: italic;
    font-size: 15px;
    font-weight: 400;
}

@include media-breakpoint-down(sm) {
    .slot-container {
        min-height: 0px;
        margin-bottom: 10px;
    }

    .damage-list {
        font-size: 1.125rem;
        margin-bottom: 30px;
        max-height: 800px;
    }

    .extra-wide {
        width: fit-content;
    }

    .extra-wide div {
        width: fit-content;
    }
}
</style>
