import apiErrorHandler from '../plugins/apiErrorHandler';
import axios from '@/plugins/axiosConfig';

async function shareTrailer(
    driverType,
    { mobileNumber, resourceAccessGrantId }
) {
    const endpoint = endpoints[driverType];

    if (!endpoint) {
        throw new Error('invalid driver type');
    }

    const url = `${process.env.VUE_APP_EXT_API_URL}api/trailers/${endpoint}`;
    const data = { mobileNumber, resourceAccessGrantId };

    return await axios({ url, data, method: 'post' }).catch((error) => {
        return apiErrorHandler(error);
    });
}

export const shareTrailerWithAssetDriver = shareTrailer.bind(null, 'asset');

export const shareTrailerWithThirdPartyDriver = shareTrailer.bind(
    null,
    'logistics'
);

const endpoints = Object.freeze({
    asset: 'shareTrailerLocationByAssetMobileNumber',
    logistics: 'shareTrailerLocationByLogisticsMobileNumber',
});
