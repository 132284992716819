import { createRouter, createWebHistory } from 'vue-router';
import globalConstants from '../plugins/globalConstants';
import Store from '../store/index';

// Driver views
import About from '../views/About.vue';
import AttachTrailer from '../views/AttachTrailer.vue';
import BrakeDamage from '../views/TrailerAssessment/BrakeDamage.vue';
import BodyDamage from '../views/TrailerAssessment/BodyDamage.vue';
import DetachTrailer from '../views/DetachTrailer.vue';
import DetachTrailerSuccess from '../views/DetachTrailerSuccess.vue';
import DriverProfile from '../views/DriverProfile.vue';
import AttachTrailerWithoutLoadSuccess from '../views/AttachTrailerWithoutLoadSuccess.vue';
import ElectricalDamage from '../views/TrailerAssessment/ElectricalDamage.vue';
import KingpinDamage from '../views/TrailerAssessment/KingpinDamage.vue';
import AirlinesElectricalDamage from '../views/TrailerAssessment/AirlinesElectricalDamage.vue';
import EnterTrailerDetails from '../views/EnterTrailerDetails.vue';
import FindLoad from '../views/FindLoad.vue';
import GetStarted from '../views/GetStarted.vue';
import LoadLookup from '../views/LoadLookup.vue';
import Scan from '../views/Scan.vue';
import Signup from '../views/Signup.vue';
import LoadConfirmation from '../views/LoadConfirmation.vue';
import AttachTrailerSuccess from '../views/AttachTrailerSuccess.vue';
import Support from '../views/Support.vue';
import TermsOfUse from '../views/TermsOfUse.vue';
import TireDamage from '../views/TrailerAssessment/TireDamage.vue';
import AssetTrailerFinderMap from '../views/Map/Asset/AssetTrailerFinderMap.vue';
import ThirdPartyTrailerFinderMap from '../views/Map/ThirdParty/ThirdPartyTrailerFinderMap.vue';
import TrailerAssessment from '../views/TrailerAssessment/TrailerAssessment.vue';
import TrailerNotFit from '../views/TrailerNotFit.vue';
import TrailerScan from '../views/TrailerScan.vue';
import TrailerUnavailable from '../views/TrailerUnavailable.vue';
import UserSelection from '../views/UserSelection.vue';
import VerifyIdentity from '../views/VerifyIdentity.vue';
import DamagedPicturesAndComments from '../views/TrailerAssessment/DamagedPicturesAndComments.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';

// Truck views
import TruckScanner from '../views/trucks/Scanner.vue';

// Sandbox
import PairTrailer from '../views/sandbox/PairTrailer.vue';

const routes = [
    // Driver routes
    {
        path: '/about',
        name: 'About',
        component: About,
    },
    {
        path: '/attachTrailer',
        name: 'AttachTrailer',
        component: AttachTrailer,
    },
    {
        path: '/bodyDamage',
        name: 'BodyDamage',
        component: BodyDamage,
    },
    {
        path: '/privacyPolicy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
    },
    {
        path: '/electricalDamage',
        name: 'ElectricalDamage',
        component: ElectricalDamage,
    },
    {
        path: '/kingpinDamage',
        name: 'KingpinDamage',
        component: KingpinDamage,
    },
    {
        path: '/airlinesElectricalDamage',
        name: 'AirlinesElectricalDamage',
        component: AirlinesElectricalDamage,
    },
    {
        path: '/trailers/:trailerId/brakeAssessment/:subType',
        name: 'BrakeAssessment',
        component: DamagedPicturesAndComments,
        props: {
            assessmentType: globalConstants.trailer.assessmentTypes.brakes,
            titleLocaleKey:
                'DamagedPicturesAndComments.BrakesAndAirlines.title',
            textareaPlaceholderLocaleKey:
                'DamagedPicturesAndComments.BrakesAndAirlines.textareaPlaceholder',
        },
    },
    {
        path: '/trailers/:trailerId/damageAssessment/:subType',
        name: 'BodyDamageAssessment',
        component: DamagedPicturesAndComments,
        props: {
            assessmentType: globalConstants.trailer.assessmentTypes.damage,
            titleLocaleKey: 'DamagedPicturesAndComments.BodyDamage.title',
            textareaPlaceholderLocaleKey:
                'DamagedPicturesAndComments.BodyDamage.textareaPlaceholder',
        },
    },
    {
        path: '/trailers/:trailerId/electricalAssessment/:subType',
        name: 'ElectricalAssessment',
        component: DamagedPicturesAndComments,
        props: {
            assessmentType: globalConstants.trailer.assessmentTypes.lights,
            titleLocaleKey: 'DamagedPicturesAndComments.ElectricalDamage.title',
            textareaPlaceholderLocaleKey:
                'DamagedPicturesAndComments.ElectricalDamage.textareaPlaceholder',
        },
    },
    {
        path: '/trailers/:trailerId/tiresAssessment/:subType',
        name: 'TiresAssessment',
        component: DamagedPicturesAndComments,
        props: {
            assessmentType: globalConstants.trailer.assessmentTypes.tires,
            titleLocaleKey: 'DamagedPicturesAndComments.TiresAndWheels.title',
            textareaPlaceholderLocaleKey:
                'DamagedPicturesAndComments.TiresAndWheels.textareaPlaceholder',
        },
    },
    {
        path: '/trailers/:trailerId/kingpinAssessment/:subType',
        name: 'KingpinAssessment',
        component: DamagedPicturesAndComments,
        props: {
            assessmentType:
                globalConstants.trailer.assessmentTypes.kingpinLanding,
            titleLocaleKey: 'DamagedPicturesAndComments.KingpinDamage.title',
            subtitleLocaleKey:
                'DamagedPicturesAndComments.KingpinDamage.subtitle',
        },
    },
    {
        path: '/trailers/:trailerId/airlinesAssessment/:subType',
        name: 'AirlinesAssessment',
        component: DamagedPicturesAndComments,
        props: {
            assessmentType:
                globalConstants.trailer.assessmentTypes.airlinesElectrical,
            titleLocaleKey: 'DamagedPicturesAndComments.AirlinesDamage.title',
            subtitleLocaleKey:
                'DamagedPicturesAndComments.AirlinesDamage.subtitle',
        },
    },
    {
        path: '/trailers/:trailerId/otherAssessment/:subType',
        name: 'OtherAssessment',
        component: DamagedPicturesAndComments,
        props: {
            assessmentType: globalConstants.trailer.assessmentTypes.other,
            titleLocaleKey: 'DamagedPicturesAndComments.OtherDamage.title',
            subtitleLocaleKey:
                'DamagedPicturesAndComments.OtherDamage.subtitle',
            textareaPlaceholderLocaleKey:
                'DamagedPicturesAndComments.OtherDamage.textareaPlaceholder',
        },
    },
    {
        path: '/brakeDamage',
        name: 'BrakeDamage',
        component: BrakeDamage,
    },
    {
        path: '/tireDamage',
        name: 'TireDamage',
        component: TireDamage,
    },
    {
        path: '/detachTrailer',
        name: 'DetachTrailer',
        component: DetachTrailer,
    },
    {
        path: '/detachtrailersuccess',
        name: 'DetachTrailerSuccess',
        component: DetachTrailerSuccess,
    },
    {
        path: '/driverProfile',
        name: 'DriverProfile',
        component: DriverProfile,
    },
    {
        path: '/support',
        name: 'Support',
        component: Support,
    },
    {
        path: '/termsOfUse',
        name: 'TermsOfUse',
        component: TermsOfUse,
    },
    {
        path: '/loadlookup',
        name: 'LoadLookup',
        component: LoadLookup,
    },
    {
        path: '/getstarted',
        name: 'GetStarted',
        component: GetStarted,
    },
    {
        path: '/trailers/:id?',
        name: 'TrailerScan',
        component: TrailerScan,
    },
    {
        path: '/signup',
        name: 'Signup',
        component: Signup,
    },
    {
        path: '/enterTrailerDetails/:alert?',
        name: 'EnterTrailerDetails',
        component: EnterTrailerDetails,
    },
    {
        path: '/userselection',
        name: 'UserSelection',
        component: UserSelection,
    },
    {
        path: '/scan',
        name: 'Scan',
        component: Scan,
    },
    {
        path: '/trailerAssessment',
        name: 'TrailerAssessment',
        component: TrailerAssessment,
    },
    {
        path: '/trailerNotFit',
        name: 'TrailerNotFit',
        component: TrailerNotFit,
    },
    {
        path: '/attachTrailerSuccess',
        name: 'AttachTrailerSuccess',
        component: AttachTrailerSuccess,
    },
    {
        path: '/loadConfirmation',
        name: 'LoadConfirmation',
        component: LoadConfirmation,
    },
    {
        path: '/trailerunavailable',
        name: 'TrailerUnavailable',
        component: TrailerUnavailable,
    },
    {
        path: '/trailers/map',
        name: 'AssetTrailerFinderMap',
        component: AssetTrailerFinderMap,
        beforeEnter: (to) => checkAccessGrant(to),
    },
    {
        path: '/trailers/tacMap',
        name: 'ThirdPartyTrailerFinderMap',
        component: ThirdPartyTrailerFinderMap,
        beforeEnter: (to) => checkAccessGrant(to),
    },
    {
        path: '/verifyidentity',
        name: 'VerifyIdentity',
        component: VerifyIdentity,
    },
    {
        path: '/attachtrailerwithoutloadsuccess',
        name: 'AttachTrailerWithoutLoadSuccess',
        component: AttachTrailerWithoutLoadSuccess,
    },
    {
        path: '/findload',
        name: 'FindLoad',
        component: FindLoad,
    },
    // Truck routes
    {
        path: '/trucks/:truckId',
        name: 'TruckScanner',
        component: TruckScanner,
    },

    // Sandbox
    {
        path: '/pairtrailer',
        name: 'PairTrailer',
        component: PairTrailer,
    },

    // Default Route
    {
        path: '/',
        redirect: (to) => {
            return 'Scan';
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
});

const checkAccessGrant = (to) => {
    const grant = to.query?.tag;

    // If access grant exists, store and remove from url
    if (grant) {
        Store.commit('setTrailerMapAccessGrant', grant);
    }
};

export default router;
