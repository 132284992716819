import apiErrorHandler from '../plugins/apiErrorHandler';
import axios from '@/plugins/axiosConfig';

export default async function (mcNumber, dotNumber, mobileNumber) {
    let driverSigninRequestDto = {
        mcNumber: mcNumber,
        dotNumber: dotNumber,
        mobileNumber: mobileNumber,
    };

    return await axios({
        method: 'post',
        url: `${process.env.VUE_APP_EXT_API_URL}api/drivers/signin`,
        data: driverSigninRequestDto,
    })
        .then((r) => r)
        .catch((error) => {
            return apiErrorHandler(error);
        });
}
