<template>
    <div>
        <div class="fb-container body">
            <div class="fb-form">
                <h1 class="fb-form-heading">
                    {{ $t('VerifyIdentity.title') }}
                </h1>

                <p class="fb-form-paragraph">
                    {{ $t('VerifyIdentity.subTitle') }}
                    <strong>**{{ mobileNumber.slice(-2) }}</strong
                    >.
                </p>

                <div
                    class="fb-form-control-group verify-code"
                    @keyup="(e) => keyUpEventHandler(e)"
                >
                    <div
                        class="uix-control uix-number-control"
                        :class="{ 'has-error': hasError }"
                    >
                        <div class="uix-control-container">
                            <div class="uix-control-groups">
                                <div
                                    class="uix-control-group"
                                    :class="{ 'has-error': hasError }"
                                >
                                    <input
                                        id="Code1"
                                        ref="code1Input"
                                        v-model="code1"
                                        class="uix-control-text has-placeholder-offset"
                                        inputmode="decimal"
                                        name="Code1"
                                        placeholder=""
                                        required
                                        type="text"
                                        @paste="
                                            (e) =>
                                                handlePaste(
                                                    e?.clipboardData?.getData(
                                                        'text'
                                                    )
                                                )
                                        "
                                        @focus="$refs.code1Input.select()"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="uix-control uix-number-control"
                        :class="{ 'has-error': hasError }"
                    >
                        <div class="uix-control-container">
                            <div class="uix-control-groups">
                                <div
                                    class="uix-control-group"
                                    :class="{ 'has-error': hasError }"
                                >
                                    <input
                                        id="Code2"
                                        ref="code2Input"
                                        v-model="code2"
                                        class="uix-control-text has-placeholder-offset"
                                        inputmode="decimal"
                                        name="Code2"
                                        placeholder=""
                                        required
                                        type="text"
                                        @paste="
                                            (e) =>
                                                handlePaste(
                                                    e?.clipboardData?.getData(
                                                        'text'
                                                    )
                                                )
                                        "
                                        @focus="$refs.code2Input.select()"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="uix-control uix-number-control"
                        :class="{ 'has-error': hasError }"
                    >
                        <div class="uix-control-container">
                            <div class="uix-control-groups">
                                <div
                                    class="uix-control-group"
                                    :class="{ 'has-error': hasError }"
                                >
                                    <input
                                        id="Code3"
                                        ref="code3Input"
                                        v-model="code3"
                                        class="uix-control-text has-placeholder-offset"
                                        inputmode="decimal"
                                        name="Code3"
                                        placeholder=""
                                        required
                                        type="text"
                                        @paste="
                                            (e) =>
                                                handlePaste(
                                                    e?.clipboardData?.getData(
                                                        'text'
                                                    )
                                                )
                                        "
                                        @focus="$refs.code3Input.select()"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="uix-control uix-number-control"
                        :class="{ 'has-error': hasError }"
                    >
                        <div class="uix-control-container">
                            <div class="uix-control-groups">
                                <div
                                    class="uix-control-group"
                                    :class="{ 'has-error': hasError }"
                                >
                                    <input
                                        id="Code4"
                                        ref="code4Input"
                                        v-model="code4"
                                        class="uix-control-text has-placeholder-offset"
                                        inputmode="decimal"
                                        name="Code4"
                                        placeholder=""
                                        required
                                        type="text"
                                        @paste="
                                            (e) =>
                                                handlePaste(
                                                    e?.clipboardData?.getData(
                                                        'text'
                                                    )
                                                )
                                        "
                                        @focus="$refs.code4Input.select()"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="uix-control uix-number-control"
                        :class="{ 'has-error': hasError }"
                    >
                        <div class="uix-control-container">
                            <div class="uix-control-groups">
                                <div
                                    class="uix-control-group"
                                    :class="{ 'has-error': hasError }"
                                >
                                    <input
                                        id="Code5"
                                        ref="code5Input"
                                        v-model="code5"
                                        class="uix-control-text has-placeholder-offset"
                                        inputmode="decimal"
                                        name="Code5"
                                        placeholder=""
                                        required
                                        type="text"
                                        @paste="
                                            (e) =>
                                                handlePaste(
                                                    e?.clipboardData?.getData(
                                                        'text'
                                                    )
                                                )
                                        "
                                        @focus="$refs.code5Input.select()"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="uix-control uix-number-control"
                        :class="{ 'has-error': hasError }"
                    >
                        <div class="uix-control-container">
                            <div class="uix-control-groups">
                                <div
                                    class="uix-control-group"
                                    :class="{ 'has-error': hasError }"
                                >
                                    <input
                                        id="Code6"
                                        ref="code6Input"
                                        v-model="code6"
                                        class="uix-control-text has-placeholder-offset"
                                        inputmode="decimal"
                                        name="Code6"
                                        placeholder=""
                                        required
                                        type="text"
                                        @paste="
                                            (e) =>
                                                handlePaste(
                                                    e?.clipboardData?.getData(
                                                        'text'
                                                    )
                                                )
                                        "
                                        @focus="$refs.code6Input.select()"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <label
                    for="Code1"
                    class="fb-form-control-alert error"
                    :class="{ show: hasError }"
                >
                    <span>{{ $t('VerifyIdentity.invalidCodeText') }}</span>
                </label>

                <!-- TODO: Remove "show" class to hide button appropriately -->
                <button
                    type="button"
                    class="btn btn-link btn-sm fb-btn-no-code"
                    :class="{ show: hasError }"
                    @click="showRequestCodeButton = true"
                >
                    {{ $t('VerifyIdentity.noCodeText') }}
                </button>

                <div
                    class="fb-form-resend"
                    :class="{ show: showRequestCodeButton }"
                >
                    <p class="fb-form-paragraph">
                        {{ $t('VerifyIdentity.codeRequestTimeoutText') }}
                    </p>
                    <button
                        type="button"
                        class="btn btn-primary fb-btn-resend-code"
                        @click="resendCode()"
                    >
                        <font-awesome-icon :icon="['fas', 'rotate-left']" />
                        {{ $t('VerifyIdentity.resendCodeButtonText') }}
                    </button>
                </div>

                <div
                    class="fb-form-alert success"
                    :class="{ show: resentCode }"
                >
                    <div class="fb-form-alert-container">
                        <svg
                            version="1.1"
                            class="fb-svg verification-code-sent"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 236 112"
                            style="enable-background: new 0 0 236 112"
                            xml:space="preserve"
                        >
                            <g transform="translate(88 311)">
                                <path
                                    class="fb-path mobile-phone"
                                    d="M137.5-311h-49c-5.8,0-10.5,4.7-10.5,10.5v91c0,5.8,4.7,10.5,10.5,10.5h49 c5.8,0,10.5-4.7,10.5-10.5v-91C148-306.3,143.3-311,137.5-311z M113-206c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7 C120-209.1,116.9-206,113-206C113-206,113-206,113-206z M137.5-229.6c0,1.4-1.2,2.6-2.6,2.6H91.1c-1.4,0-2.6-1.2-2.6-2.6v-68.2 c0-1.4,1.2-2.6,2.6-2.6h43.8c1.4,0,2.6,1.2,2.6,2.6V-229.6z"
                                />
                                <path
                                    class="fb-path comment-dots"
                                    d="M113.3-294c-10.1,0-18.3,6.6-18.3,14.9c0.1,3.5,1.5,6.9,4.1,9.3 c-0.8,2.5-2.1,4.9-3.9,6.8c-0.2,0.2-0.2,0.4-0.1,0.6c0.1,0.2,0.3,0.3,0.5,0.3c3.7-0.1,7.2-1.4,10-3.7c2.5,0.9,5.1,1.4,7.7,1.4 c10.1,0,18.3-6.6,18.3-14.9S123.4-294,113.3-294z M104.1-276.9c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1,2.3,2.3l0,0 C106.4-277.9,105.4-276.9,104.1-276.9C104.1-276.9,104.1-276.9,104.1-276.9z M113.3-276.9c-1.3,0-2.3-1-2.3-2.3 c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1,2.3,2.3l0,0C115.6-277.9,114.6-276.9,113.3-276.9C113.3-276.9,113.3-276.9,113.3-276.9z M122.4-276.9c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1,2.3,2.3l0,0C124.7-277.9,123.7-276.9,122.4-276.9 C122.4-276.9,122.4-276.9,122.4-276.9z"
                                />
                            </g>
                            <path
                                class="fb-path caret-right"
                                d="M149.9,64.2l-7.3-7.3c-0.3-0.3-0.3-0.8,0-1.1c0.2-0.2,0.4-0.2,0.6-0.2h7.3 c0.4,0,0.8,0.4,0.8,0.8l0,7.3c0,0.4-0.4,0.8-0.8,0.8C150.3,64.5,150.1,64.4,149.9,64.2z"
                            />
                            <path
                                class="fb-path dashed-line"
                                d="M39.9,43.9c0,0,17.8-21.7,34.7-21.8c27.8-1.5,15.2,30.9,15.1,31c-11,19.2-5.6,69.7,58.9,5.2"
                            />
                            <path
                                class="fb-path paper-plane"
                                d="M33.5,49.2L0.9,68c-0.8,0.4-1.1,1.5-0.7,2.3c0.2,0.3,0.5,0.6,0.8,0.7l7.5,3.1l20.2-17.8 c0.2-0.1,0.4-0.1,0.6,0.1c0.1,0.2,0.1,0.4,0,0.5L12.4,77.6v5.7c0,0.9,0.7,1.7,1.6,1.7c0.5,0,1-0.2,1.3-0.6l4.5-5.4l8.8,3.7 c0.9,0.4,1.9,0,2.2-0.9c0-0.1,0.1-0.2,0.1-0.4L36,51c0.2-0.9-0.5-1.8-1.4-1.9C34.2,49,33.8,49,33.5,49.2z"
                            />
                        </svg>
                        <h4 class="fb-form-sub-heading">
                            {{ $t('VerifyIdentity.codeSentTitle') }}
                        </h4>
                        <p class="fb-form-paragraph">
                            {{ $t('VerifyIdentity.codeSentSubtitle') }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="bottom-div">
                <button
                    type="button"
                    class="btn fb-btn-back-to-signin"
                    @click="goBack()"
                >
                    {{ $t('VerifyIdentity.backToSignin') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import FbaApiError from '../models/ApiError';
import globalConstants from '../plugins/globalConstants';
export default {
    name: 'VerifyIdentity',
    components: {},
    data: () => ({
        code1: '',
        code2: '',
        code3: '',
        code4: '',
        code5: '',
        code6: '',
        mobileNumber: '',
        hasError: false,
        showRequestCodeButton: false,
        resentCode: false,
        resendStartTime: null,
        resendShowAfterSeconds: 30,
    }),
    computed: {
        code() {
            return (
                this.code1 +
                this.code2 +
                this.code3 +
                this.code4 +
                this.code5 +
                this.code6 +
                ''
            );
        },
    },
    watch: {
        code1() {
            this.code1 = this.code1.replace(/[^0-9]/g, '');
            this.handlePaste(this.code1);
        },
        code2() {
            this.code2 = this.code2.replace(/[^0-9]/g, '');
            this.handlePaste(this.code2);
        },
        code3() {
            this.code3 = this.code3.replace(/[^0-9]/g, '');
            this.handlePaste(this.code3);
        },
        code4() {
            this.code4 = this.code4.replace(/[^0-9]/g, '');
            this.handlePaste(this.code4);
        },
        code5() {
            this.code5 = this.code5.replace(/[^0-9]/g, '');
            this.handlePaste(this.code5);
        },
        code6() {
            this.code6 = this.code6.replace(/[^0-9]/g, '');
            this.handlePaste(this.code6);
        },
    },
    created() {
        this.setLoading(false);
        this.mobileNumber = this.$store.state.userDetails.mobileNumber;
        if (!this.mobileNumber) {
            this.$router.push({ name: 'GetStarted' });
        }
        setTimeout(() => {
            this.showResendButton();
        }, 30000);
    },
    mounted() {
        this.$refs.code1Input.focus();
    },
    methods: {
        handlePaste(code) {
            if (code.length >= 6) {
                document.activeElement.blur();
                this.code1 = code[0];
                this.code2 = code[1];
                this.code3 = code[2];
                this.code4 = code[3];
                this.code5 = code[4];
                this.code6 = code[5];
                this.submitVerification();
            }
        },
        goBack() {
            this.setLoading(true);
            this.$store.commit('clearUserDetails');
            this.$router.push({ name: 'GetStarted' });
        },
        showResendButton() {
            this.showRequestCodeButton = true;
        },
        clearForm() {
            this.code1 = '';
            this.code2 = '';
            this.code3 = '';
            this.code4 = '';
            this.code5 = '';
            this.code6 = '';
            this.hasError = false;
            this.showRequestCodeButton = false;
        },
        async resendCode() {
            let result = await this.$store.dispatch(
                'driverResendVerificationCode'
            );
            if (result === true) {
                this.clearForm();
                this.resentCode = true;
                setTimeout(() => {
                    this.showResendButton();
                }, 30000);
            }
        },
        async submitVerification() {
            if (this.code.trim().length != 6) {
                this.hasError = true;
                this.handlePaste(this.code);
                return;
            } else {
                this.hasError = false;
                this.setLoading(true);
                let result = await this.$store.dispatch(
                    'driverVerifyIdentity',
                    this.code
                );
                if (result === true) {
                    this.setLoading(false);
                    this.showRequestCodeButton = false;
                    this.resentCode = false;
                    this.hasError = false;
                    this.$store.commit('saveApiToken', true);
                    this.$store.commit('setMobileNumberAsValid');
                    let route = this.$store.state.userDetails.needsToRegister
                        ? 'Signup'
                        : 'TrailerScan';
                    this.$router.push({ name: route });
                } else if (result instanceof FbaApiError) {
                    if (
                        result.reasonCode ===
                        globalConstants.reasonCodes.invalidMfaCode
                    ) {
                        this.hasError = true;
                    }
                    this.setLoading(false);
                    this.showRequestCodeButton = false;
                    this.resentCode = false;
                }
            }
        },
        keyUpEventHandler(event) {
            if (
                event?.key === globalConstants.keyboard.key.backspace ||
                event?.code === globalConstants.keyboard.code.backspace
            ) {
                let currentId = event.target.id;
                if (currentId.includes('Code') && currentId !== 'Code1') {
                    let newId =
                        'code' + (parseInt(currentId.slice(-1)) - 1) + 'Input';
                    this.$refs[newId].focus();
                    this.$refs[newId].select();
                }
                return;
            }

            if (
                globalConstants.keyboard.key.digits.includes(event?.key) ||
                globalConstants.keyboard.code.digits.includes(event?.code)
            ) {
                let currentId = event.target.id;
                if (currentId.includes('Code') && currentId !== 'Code6') {
                    let newId =
                        'code' + (parseInt(currentId.slice(-1)) + 1) + 'Input';
                    this.$refs[newId].focus();
                    this.$refs[newId].select();
                } else if (currentId === 'Code6') {
                    this.submitVerification();
                }
                return;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.bottom-div {
    margin-top: auto;
    width: 100%;
}
.fb-form-control-group {
    &.verify-code {
        align-content: center;
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        gap: 10px;
        justify-content: center;
        margin: 30px 0 10px;
        position: relative;

        .uix-number-control {
            margin: 0;
            max-width: 58px;
            min-width: 0;
            width: 100%;

            .uix-control-text {
                padding: 0.375rem 0;
                text-align: center;
            }
        }
    }
}

.fb-form-control-alert {
    align-content: center;
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    font-size: 0.8125rem; // 132px
    font-weight: 600;
    gap: 0;
    margin: -5px auto 30px;
    position: relative;
    text-align: center;
    visibility: hidden;
    width: 100%;

    &.error {
        color: $uix-color-error;
    }

    > span {
        margin: 0 auto;
        max-width: 398px;
        position: relative;
        text-align: left;
        width: 100%;
    }

    &.show {
        visibility: visible;
    }
}

.fb-btn-no-code {
    display: none;
    margin-bottom: 40px;
    margin-top: 60px;

    &.show {
        display: inline-block;
    }
}

.fb-form-resend {
    display: none;
    margin: 0 0 40px;
    position: relative;

    .fb-form-paragraph {
        margin-left: auto;
        margin-right: auto;
        max-width: 398px;
    }

    .fb-btn-resend-code {
        max-width: 398px;
        width: 100%;
    }

    &.show {
        display: block;
    }
}

.fb-svg {
    &.verification-code-sent {
        height: 94.9097px;
        position: relative;
        width: 200px;
        z-index: 0;
    }
}

.fb-path {
    &.mobile-phone,
    &.caret-right {
        fill: #a4bd7e;
    }

    &.comment-dots,
    &.paper-plane {
        fill: #709a34;
    }

    &.dashed-line {
        fill: none;
        stroke: #a4bd7e;
        stroke-width: 3;
        stroke-dasharray: 7;
    }
}

.fb-form-alert-container {
    background-color: $uix-shade-100;
    -webkit-box-shadow: 0 1px 2px 0 $uix-shade;
    -moz-box-shadow: 0 1px 2px 0 $uix-shade;
    box-shadow: 0 1px 2px 0 $uix-shade;

    border-radius: 6px;
    display: inline-block;
    margin: 0 auto;
    max-width: 398px;
    padding: 30px 20px 20px;
    position: relative;
    width: 100%;
    .fb-form-sub-heading,
    .fb-form-paragraph {
        color: $uix-color-error;
    }

    .fb-form-sub-heading {
        font-weight: 700;
        margin: 25px 0 5px;
    }
}

.fb-form-alert {
    display: none;
    margin: 0 0 60px;
    position: relative;
    width: 100%;

    &.success {
        .fb-form-alert-container {
            background-color: #e7ebdd;
            -webkit-box-shadow: 0 1px 2px 0 rgba(112, 154, 52, 0.66);
            -moz-box-shadow: 0 1px 2px 0 rgba(112, 154, 52, 0.66);
            box-shadow: 0 1px 2px 0 rgba(112, 154, 52, 0.66);
            margin-top: -35px;
            max-width: 398px;

            .fb-form-sub-heading,
            .fb-form-paragraph {
                color: $uix-color-success;
            }

            .fb-form-sub-heading {
                font-weight: 400;
            }
        }
    }

    &.show {
        display: inline-block;
    }
}
</style>
