<template>
    <div>
        <div class="fb-container body">
            <div class="fb-form">

                <button
                    type="button"
                    class="btn btn-link fb-form-clear-button"
                    @click="goBack"
                    >
                    <font-awesome-icon :icon="['fas', 'xmark']" />
                </button>

                <div class="circled">
                    <font-awesome-icon :icon="['fas', 'shield-halved']" />
                </div>
                
                <div class="fb-form-text-block">
                    <h3 class="policy-header bold">
                        FreightBox Mobile App Privacy Policy
                    </h3>

                    <p class="policy-text">
                        Last modified: March 31, 2022
                    </p>

                    <p class="policy-section-header bold">
                        Introduction
                    </p>

                    <p class="policy-paragraph">
                        Knight Logistics, LLC and Swift Logistics, LLC ("<span class="bold">Company</span>" or "<span class="bold">We</span>") respects your privacy and is committed to protecting it through its compliance with this policy. This policy describes: 
                        <ul>
                            <li>
                                The types of information we may collect or that you may provide when you download, install, register with, access, or use the FreightBox mobile website (the "<span class="bold">App</span>").
                            </li>
                            <li>
                                Our practices for collecting, using, maintaining, protecting, and disclosing that information.
                            </li>
                        </ul>
                        This policy applies only to information we collect in the App.
                    </p>

                    <p class="policy-paragraph">
                        This policy DOES NOT apply to information that:
                        <ul>
                            <li>
                                We collect outside of the App, or on any other Company apps or websites, including websites you may access through this App.
                            </li>
                            <li>
                                You provide to or is collected by any third party.
                            </li>
                        </ul>
                        Our websites and apps, and other third parties may have their own privacy policies, which we encourage you to read before providing information on or through them.
                    </p>
                    
                    <p class="policy-paragraph">
                        Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, do not download, register with, or use the App. By downloading, registering with, or using the App, you agree to this privacy policy. This policy may change from time to time. Your continued use of the App after we revise this policy means you accept those changes, so please check the policy periodically for updates. 
                    </p>

                    <p class="policy-section-header bold">
                        Children Under the Age of 16
                    </p>

                    <p class="policy-paragraph">
                        The App is not intended for children under 16 years of age, and we do not knowingly collect personal information from children under 16. If we learn we have collected or received personal information from a child under 16 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 16, please contact us at
                        <br />
                        <br />
                        Knight Logistics, LLC/Swift Logistics, LLC
                        <br />
                        Attn: Legal Department
                        <br />
                        2002 West Wahalla Lane
                        <br />
                        Phoenix, Arizona 85027
                        <br />
                        or via email at: <a class="policy-email" href="mailto:FreightBox@knighttrans.com">FreightBox@knighttrans.com</a>
                        <br />
                        or via our toll-free number: <a class="policy-phone" href="tel:18005270032">1-800-527-0032</a>
                    </p>

                    <p class="policy-paragraph">
                        California residents under 16 years of age may have additional rights regarding the collection and sale of their personal information. Please see <span class="bold">Mobile App Privacy Notice for California Residents</span>, below, for more information.
                    </p>
                    
                    <p class="policy-section-header bold">
                        Information We Collect and How We Collect It
                    </p>

                    <p class="policy-paragraph">
                        We collect information from and about users of our App:
                        <ul>
                            <li>
                                Directly from you when you provide it to us.
                            </li>
                            <li>
                                Automatically when you use the App. 
                            </li>
                        </ul>
                    </p>

                    <p class="policy-subsection-header bold">
                        Information You Provide to Us
                    </p>

                    <p class="policy-paragraph">
                        When you download, register with, or use this App, we may ask you provide information:
                        <ul>
                            <li>
                                By which you may be personally identified, such as your name, mobile telephone number, and location data ("<span class="bold">personal information</span>").
                            </li>
                            <li>
                                That is about you but individually does not identify you, such as truck number, motor carrier number, inspection information about the trailer, and information about the load you may be hauling.
                            </li>
                        </ul>
                    </p>

                    <p class="policy-paragraph">
                        This information includes: 
                        <ul>
                            <li>
                                Information that you provide by filling in forms in the App. This includes information provided at the time of registering to use the App and while using it during the normal course of business.
                            </li>
                            <li>
                                Details of transactions you carry out through the App.
                            </li>
                            <li>
                                Any searches you conduct using the App.
                            </li>
                        </ul>
                    </p>

                    <p class="policy-subsection-header bold">
                        Automatic Information Collection and Tracking
                    </p>

                    <p class="policy-paragraph">
                        When you download, access, and use the App, it may use technology to automatically collect:
                        <ul>
                            <li>
                                <span class="bold">Usage Details.</span> When you access and use the App, we may automatically collect certain details of your access to and use of the App, including location data, logs, and other communication data and the resources that you access and use on or through the App.
                            </li>
                            <li>
                                <span class="bold">Device Information.</span> We may collect information about your mobile device and internet connection, including the browser type and operating system.
                            </li>
                            <li>
                                <span class="bold">Stored Information and Files.</span> The App also may access metadata and other information associated with other files stored on your device. This may include, for example, photographs.
                            </li>
                            <li>
                                <span class="bold">Location Information.</span> This App collects real-time information about the location of your device, so we may confirm that the freight transaction is occurring as expected and so that we may match you with the correct available freight.
                            </li>
                        </ul>
                        If you do not want us to collect this information, you may opt out at any time by notifying us.  If you opt out, you will not be able to use the services provided through the App.
                    </p>

                    <p class="policy-subsection-header bold">
                        Information Collection Technologies 
                    </p>

                    <p class="policy-paragraph">
                        The technologies we use for automatic information collection may include:
                        <ul>
                            <li>
                                <span class="bold">Cookies (or mobile cookies).</span> A cookie is a small file placed on your smartphone. It may be possible to refuse to accept mobile cookies by activating the appropriate setting on your smartphone. However, if you select this setting you may be unable to access certain parts of our App.
                            </li>
                        </ul>
                    </p>

                    <p class="policy-section-header bold">
                        How We Use Your Information
                    </p>

                    <p class="policy-paragraph">
                        We use information that we collect about you or that you provide to us, including any personal information, to:
                        <ul>
                            <li>
                                Provide you with the App and its contents, and any other information, products or services that you request from us.
                            </li>
                            <li>
                                Fulfill any other purpose for which you provide it.
                            </li>
                            <li>
                                Carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.
                            </li>
                        </ul>
                    </p>

                    <p class="policy-paragraph">
                        The usage information we collect helps us to improve our App and to deliver a better and more personalized experience by enabling us to:
                        <ul>
                            <li>
                                Estimate our audience size and usage patterns.
                            </li>
                            <li>
                                Store information about your preferences, allowing us to customize our App according to your individual interests.
                            </li>
                            <li>
                                Speed up your searches.
                            </li>
                            <li>
                                Recognize you when you use the App.
                            </li>
                        </ul>
                    </p>

                    <p class="policy-paragraph">
                        We use location information we collect to so we may confirm that the freight transaction is occurring as expected and to improve efficiency by matching you to the appropriate available loads.
                    </p>

                    <p class="policy-section-header bold">
                        Disclosure of Your Information
                    </p>

                    <p class="policy-paragraph">
                        We may disclose aggregated information about our users, and information that does not identify any individual or device, without restriction. 
                    </p>

                    <p class="policy-paragraph">
                        In addition, we may disclose personal information that we collect or you provide:
                        <ul>
                            <li>
                                To our subsidiaries and affiliates.
                            </li>
                            <li>
                                To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.
                            </li>
                            <li>
                                To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Knight Logistics, LLC’s and/or Swift Logistics, LLC’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Knight Logistics, LLC and/or Swift Logistics, LLC about our App users is among the assets transferred.
                            </li>
                            <li>
                                To fulfill the purpose for which you provide it.
                            </li>
                            <li>
                                For any other purpose disclosed by us when you provide the information.
                            </li>
                            <li>
                                With your consent.
                            </li>
                            <li>
                                To comply with any court order, law, or legal process, including to respond to any government or regulatory request.
                            </li>
                            <li>
                                To enforce our rights arising from any contracts entered into between you and us, including the Broker-Carrier Transportation Agreement, Equipment Use or Interchange Addendum, and/or the Bill of Lading, and/or for billing and collection.
                            </li>
                            <li>
                                If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Knight Logistics, LLC, Swift Logistics, LLC and their affiliates, our customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.
                            </li>
                        </ul>
                    </p>

                    <p class="policy-section-header bold">
                        Accessing and Correcting Your Personal Information
                    </p>

                    <p class="policy-paragraph">
                        You can review and change your personal information by logging into the App and visiting your driver profile page.
                    </p>

                    <p class="policy-paragraph">
                        You may also send us an email at <a class="policy-email" href="freightbox@knightttrans.com">freightbox@knightttrans.com</a> to request access to, correct, or delete any personal information that you have provided to us. We cannot delete your personal information except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.  If we delete your personal information, you may not be able to use the functionality that the app provides and you may be unable to transact business through the App.
                    </p>

                    <p class="policy-section-header bold">
                        Data Security
                    </p>

                    <p class="policy-paragraph">
                        We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls. 
                    </p>

                    <p class="policy-paragraph">
                        The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our App, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.
                    </p>

                    <p class="policy-paragraph">
                        Unfortunately, the transmission of information via the internet and mobile platforms is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted through our App. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures we provide.
                    </p>

                    <p class="policy-section-header bold">
                        Changes to Our Privacy Policy
                    </p>

                    <p class="policy-paragraph">
                        We may update our privacy policy from time to time. If we make material changes to how we treat our users' personal information, we will post the new privacy policy on this page.
                    </p>

                    <p class="policy-paragraph">
                        The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable phone number for you and for periodically visiting this privacy policy to check for any changes.
                    </p>

                    <p class="policy-section-header bold">
                        Contact Information
                    </p>

                    <p class="policy-paragraph">
                        To ask questions or comment about this privacy policy and our privacy practices, contact us at: 
                    </p>

                    <p class="policy-paragraph">
                        Knight-Swift Transportation Holdings, Inc.
                        <br />
                        Attn: Legal Department
                        <br />
                        2002 West Wahalla Lane
                        <br />
                        Phoenix, Arizona 85027
                        <br />
                        or via email at: <a class="policy-email" href="mailto:FreightBox@knighttrans.com">FreightBox@knighttrans.com</a>
                        <br />
                        or via our toll-free number: <a class="policy-phone" href="tel:18005270032">1-800-527-0032</a>
                    </p>

                    <p class="policy-paragraph">
                        California residents may have additional personal information rights and choices. Please see the FreightBox Mobile App Privacy Notice for California Residents, directly below, for more information.
                    </p>

                    <p class="policy-section-header bold">
                        FreightBox Mobile App Privacy Notice for California Residents
                    </p>

                    <p class="policy-paragraph">
                        This <span class="bold">Privacy Notice for California Residents</span> supplements the information contained in the above FreightBox Mobile App (the “App”) Privacy Policy and applies solely to all App users who reside in the State of California ("consumers" or "you"). We adopt this notice to comply with the California Consumer Privacy Act of 2018 (CCPA) and any terms defined in the CCPA have the same meaning when used in this Notice.
                    </p>

                    <p class="policy-paragraph">
                        Where noted in this Notice, the CCPA temporarily exempts personal information reflecting a written or verbal business-to-business communication ("<span class="bold">B2B personal information</span>") from some its requirements.
                    </p>

                    <p class="policy-section-header bold">
                        Information We Collect
                    </p>

                    <p class="policy-paragraph">
                        The App collects information that identifies, relates to, describes, references, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer, household, or device ("<span class="bold">personal information</span>"). Personal information does not include:
                        <ul>
                            <li>
                                Publicly available information from government records.
                            </li>
                            <li>
                                Deidentified or aggregated consumer information.
                            </li>
                            <li>
                                Information excluded from the CCPA's scope, like:
                                <ul>
                                    <li>
                                        health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data;
                                    </li>
                                    <li>
                                        personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FCRA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        In particular, the App has collected the following types of information from its consumers within the last twelve (12) months, some of which may be considered personal information:
                    </p>

                    <table class="policy-table">
                        <tr>
                            <th class="policy-table-header bold">
                                Data Collected
                            </th>
                            <th class="policy-table-header bold">
                                Data Use
                            </th>
                        </tr>
                        <tr>
                            <td class="policy-table-cell">
                                Driver Name (First, Last if available)
                            </td>
                            <td class="policy-table-cell">
                                <ul>
                                    <li>
                                        Stored in driver roster index
                                    </li>
                                    <li>
                                        Only used internally to associate drivers to a truck, carrier roster, and a load
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td class="policy-table-cell">
                                Mobile Phone Number
                            </td>
                            <td class="policy-table-cell">
                                <ul>
                                    <li>
                                        Stored in driver roster index
                                    </li>
                                    <li>
                                        Only used internally to associate drivers to a truck, carrier roster, and a load
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td class="policy-table-cell">
                                Motor Carrier Number
                            </td>
                            <td class="policy-table-cell">
                                <ul>
                                    <li>
                                        Stored in driver roster index
                                    </li>
                                    <li>
                                        Only used internally to associate drivers to a truck, carrier roster, and a load
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td class="policy-table-cell">
                                Attempted Order and Bill of Lading Numbers
                            </td>
                            <td class="policy-table-cell">
                                <ul>
                                    <li>
                                        Basic load information used internally
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td class="policy-table-cell">
                                Location (latitude, longitude)
                            </td>
                            <td class="policy-table-cell">
                                <ul>
                                    <li>
                                        Confirm that the freight transaction is occurring as expected
                                    </li>
                                    <li>
                                        Improve efficiency by matching driver to the appropriate available loads
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </table>

                    <p class="policy-paragraph">
                        The App obtains the information listed in the chart above:
                        <ul>
                            <li>
                                Directly from you. For example, from fields you fill out in the App.
                            </li>
                            <li>
                                Indirectly from you. For example, from observing your actions on the App.
                            </li>
                        </ul>
                    </p>

                    <p class="policy-section-header bold">
                        Use of Personal Information
                    </p>

                    <p class="policy-paragraph">
                        We may use or disclose the personal information we collect for one or more of the following purposes:
                        <ul>
                            <li>
                                To fulfill or meet the reason you provided the information. For example, if you share your name and contact information, we will use that to facilitate the transaction of you attaching to a trailer so that you may haul the freight.
                            </li>
                            <li>
                                To provide, support, personalize, and develop the App and related products and services.
                            </li>
                            <li>
                                To create, maintain, customize, and secure your account with us.
                            </li>
                            <li>
                                To provide you with support and to respond to your inquiries, including to investigate and address your concerns and monitor and improve our responses.
                            </li>
                            <li>
                                To help maintain the safety, security, and integrity of the App and related products and services, databases and other technology assets, and business.
                            </li>
                            <li>
                                For testing, research, analysis, and product development, including to develop and improve our App and related products and services.
                            </li>
                            <li>
                                To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.
                            </li>
                            <li>
                                As described to you when collecting your personal information or as otherwise set forth in the CCPA.
                            </li>
                            <li>
                                To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us about our App users is among the assets transferred.
                            </li>
                        </ul>
                    </p>

                    <p class="policy-paragraph">
                        We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.
                    </p>

                    <p class="policy-section-header bold">
                        Sharing Personal Information
                    </p>

                    <p class="policy-paragraph">
                        We may disclose your personal information to our third-party service providers for a business purpose. When we disclose personal information for a business purpose, we enter into a contract that requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract.
                    </p>

                    <p class="policy-subsection-header bold">
                        Sale of Personal Information
                    </p>

                    <p class="policy-paragraph">
                        In the preceding twelve (12) months, we have not sold personal information.
                    </p>

                    <p class="policy-section-header bold">
                        Your Rights and Choices
                    </p>

                    <p class="policy-paragraph">
                        The CCPA provides consumers (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.
                    </p>

                    <p class="policy-subsection-header bold">
                        Access to Specific Information and Data Portability Rights
                    </p>

                    <p class="policy-paragraph">
                        You have the right to know what personal information we have collected, used, or disclosed and can request a copy of that data in a portable format. This does not currently apply to B2B personal information.
                    </p>

                    <p class="policy-subsection-header bold">
                        Deletion Request Rights
                    </p>

                    <p class="policy-paragraph">
                        You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and, where applicable, direct our service providers to delete) your personal information from our records, unless an exception applies.
                    </p>

                    <p class="policy-paragraph">
                        We may deny your deletion request if retaining the information is necessary for us or our service provider(s) to:
                        <ol>
                            <li>
                                Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, or otherwise perform our contract with you.
                            </li>
                            <li>
                                Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.
                            </li>
                            <li>
                                Debug products to identify and repair errors that impair existing intended functionality.
                            </li>
                            <li>
                                Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.
                            </li>
                            <li>
                                Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).
                            </li>
                            <li>
                                Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent.
                            </li>
                            <li>
                                Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.
                            </li>
                            <li>
                                Comply with a legal obligation.
                            </li>
                            <li>
                                Make other internal and lawful uses of that information that are compatible with the context in which you provided it.
                            </li>
                        </ol>
                    </p>

                    <p class="policy-paragraph">
                        We do not currently provide these deletion rights for B2B personal information.
                    </p>

                    <p class="policy-subsection-header bold">
                        Exercising Access, Data Portability, and Deletion Rights
                    </p>

                    <p class="policy-paragraph">
                        To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to us by either:
                        <ul>
                            <li>
                                Calling us at <a class="policy-phone" href="tel:18005270032">1-800-527-0032</a> or
                            </li>
                            <li>
                                Emailing us at <a class="policy-email" href="mailto:Freightbox@knighttrans.com">Freightbox@knighttrans.com</a>
                            </li>
                            <li>
                                Visiting <a class="policy-url" href="https://privacy.knight-swift.com/CCPA/">https://privacy.knight-swift.com/CCPA/</a>
                            </li>
                        </ul>
                    </p>

                    <p class="policy-paragraph">
                        Only you, or someone legally authorized to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child.
                    </p>

                    <p class="policy-paragraph">
                        You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must:
                        <ul>
                        <li>
                            Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative.
                        </li>
                        <li>
                            Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.
                        </li>
                        </ul>
                    </p>

                    <p class="policy-paragraph">
                        We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you.
                    </p>

                    <p class="policy-paragraph">
                        Making a verifiable consumer request does not require you to create an account with us.
                    </p>

                    <p class="policy-paragraph">
                        We will only use personal information provided in a verifiable consumer request to verify the requestor's identity or authority to make the request.
                    </p>

                    <p class="policy-subsection-header bold">
                        Response Timing and Format
                    </p>

                    <p class="policy-paragraph">
                        We endeavor to respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time (up to an additional 45 days), we will inform you of the reason and extension period in writing.
                    </p>

                    <p class="policy-paragraph">
                        Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request's receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.
                    </p>

                    <p class="policy-paragraph">
                        We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.
                    </p>

                    <p class="policy-section-header bold">
                        Non-Discrimination
                    </p>

                    <p class="policy-paragraph">
                        We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:
                        <ul>
                            <li>
                                Deny you goods or services.
                            </li>
                            <li>
                                Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.
                            </li>
                            <li>
                                Provide you a different level or quality of goods or services.
                            </li>
                            <li>
                                Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.
                            </li>
                        </ul>
                    </p>

                    <p class="policy-section-header bold">
                        Changes to Our Privacy Notice
                    </p>

                    <p class="policy-paragraph">
                        We reserve the right to amend this privacy notice at our discretion and at any time. When we make changes to this privacy notice, we will post the updated notice on the App and update the notice's effective date.  <span class="bold">Your continued use of the App following the posting of changes constitutes your acceptance of such changes.</span>
                    </p>

                    <p class="policy-section-header bold">
                        Contact Information
                    </p>

                    <p class="policy-paragraph">
                        If you have any questions or comments about this notice, the ways in which the Company collects and uses your information described here, your choices and rights regarding such use, or wish to exercise your rights under California law, please do not hesitate to contact us at:
                        <br />
                        <br />
                        Postal Address:
                        <br />
                        Knight Logistics, LLC
                        <br />
                        Swift Logistics, LLC
                        <br />
                        Attn: Legal Department
                        <br />
                        2002 West Wahalla Lane
                        <br />
                        Phoenix, Arizona 85027
                        <br />
                        Email Address: <a class="policy-email" href="mailto:FreightBox@knighttrans.com">FreightBox@knighttrans.com</a>
                        <br />
                        Or Phone at: <a class="policy-phone" href="tel:18005270032">1-800-527-0032</a>
                    </p>
                </div>

            </div>
        </div>
    </div>
</template>

<script>


export default {
  methods: {
    goBack() {
      this.$router.push({ name: history.go(-1)})
    },
  },
}
</script>

<style lang="scss" scoped>
.circled {
    width: 110px;
    height: 110px;
    background-color: #8597b3;
    color: white;
    margin: 0px auto;
    font-size: 4rem;
    border-radius: 50%;
    margin-top: 80px;
}

.fb-form-text-block {
    margin-top: 20px;
    max-height: 55vh;
    width: 100%;
    word-wrap: normal;
    overflow-y: scroll;
    text-align: left;
    font-size: 0.9rem;
}

.policy-header {
    text-align: center;
}

.policy-section-header {
    text-decoration: underline;
    font-size: 1rem;
}

.policy-subsection-header {
    font-style: italic;
    font-size: 1rem;
}

.policy-table-header {
    border: 1px solid black;
}

.policy-table-cell {
    border: 1px solid black;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}
</style>
