import apiErrorHandler from '../plugins/apiErrorHandler';
import axios from '@/plugins/axiosConfig';

export default async function (driverNumber) {
    return await axios({
        method: 'get',
        url: `${process.env.VUE_APP_EXT_API_URL}api/trailers/getAttachedTrailersByDriverNumber/${driverNumber}`,
    })
        .then((r) => r.data)
        .catch((error) => {
            return apiErrorHandler(error);
        });
}
