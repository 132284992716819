<template>
    <div
        v-if="camera != 'off'"
        :class="[fullscreen ? 'fullscreen' : 'scanner']"
        @full-screen-change="onFullscreenChange"
    >
        <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
            <div class="fb-scanner-crosshairs">
                <svg
                    class="fb-svg crosshairs"
                    style="enable-background: new 0 0 189 189"
                    version="1.1"
                    viewBox="0 0 189 189"
                    x="0px"
                    xmlns="http://www.w3.org/2000/svg"
                    xml:space="preserve"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    y="0px"
                >
                    <path
                        class="fb-path crosshairs"
                        d="M145.5,175.5v-3h16c7.7,0,14-6.2,14-14c0,0,0,0,0,0l0-16h3v17c0,8.8-7.2,16-16,16L145.5,175.5z M26.5,175.5c-8.8,0-16-7.2-16-16l0-17h3v16c0,7.7,6.2,14,14,14c0,0,0,0,0,0l16,0v3H26.5z M175.5,40.5v-16c0-7.7-6.2-14-14-14 c0,0,0,0,0,0l-16,0v-3h17c8.8,0,16,7.2,16,16v17H175.5z M10.5,40.5l0-17c0-8.8,7.2-16,16-16h17v3h-16c-7.7,0-14,6.2-14,14 c0,0,0,0,0,0l0,16L10.5,40.5z"
                    />
                </svg>
            </div>
            <div class="fb-scanner-button-group">
                <button
                    class="fullscreen-button"
                    @click="fullscreen = !fullscreen"
                >
                    <font-awesome-icon
                        :icon="[
                            'fas',
                            fullscreen
                                ? 'down-left-and-up-right-to-center'
                                : 'up-right-and-down-left-from-center',
                        ]"
                    />
                </button>
            </div>
        </qrcode-stream>
    </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import crosshairSvg from '../assets/fullscreen.svg';
export default {
    name: 'FbxQrScanner',
    components: { QrcodeStream },
    props: {
        required: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['scanned', 'fullScreenChange', 'scanError'],
    setup: (props) => {},
    data: function () {
        return {
            fullscreen: true,
            crosshairSvg: crosshairSvg,
            camera: 'auto',
        };
    },
    computed: {},
    watch: {},
    created: async function () {},
    methods: {
        onDecode(decodedString) {
            let qrUrl = new URL(decodedString);
            if (this.validateUrl(qrUrl)) {
                var values = decodedString.split('/');
                this.scannedId = values[values.length - 1];
                this.$emit('scanned', this.scannedId);
            } else {
                alert('Invalid QR, Please scan another');
            }
        },
        async refreshForIphone() {
            // NOTE [mskowron 3.25.2022]: This is a hack.
            //  If anyone finds a better way to make this work, please remove.
            //  This appears to be an issue with our version of VueJS and the QR Reader
            //  library, because their demo works fine on iPhone: https://gruhn.github.io/vue-qrcode-reader/demos/DecodeAll.html
            await this.$nextTick();
            this.fullscreen = false;
        },
        async onInit(promise) {
            this.setLoading(true);
            try {
                const capabilities = await promise;
                await this.refreshForIphone();
            } catch (e) {
                this.camera = 'off';
                this.$emit('scanError');
                if (e.message === 'Permission denied') {
                    console.warn("Scanner can't access the camera");
                } else {
                    console.error(e.message);
                }
            } finally {
                this.setLoading(false);
            }
        },
        validateUrl(qrUrl) {
            const vueUrl = new URL(process.env.VUE_APP_TACLITE_ROOT_URL);
            return qrUrl.host === vueUrl.host;
        },
    },
};
</script>

<style lang="scss" scoped>
.fb-scanner-crosshairs {
    align-content: center;
    align-items: center;
    display: flex;
    gap: 0;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    .fb-svg {
        height: 240px;
        position: relative;
        width: 24opx;

        .fb-path.crosshairs {
            fill: $uix-shade-400;
            filter: drop-shadow(0 1px 1px rgb(0 0 0 / 0.4));
        }
    }
}

.fullscreen-button {
    align-content: center;
    align-items: center;
    background-color: $uix-shade-400;
    border: 0 solid transparent;
    border-radius: 24px;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
    color: $uix-shade-black;
    display: flex;
    font-size: 1.375rem; // 22px
    gap: 0;
    height: 48px;
    justify-content: center;
    padding: 0;
    margin: 10px;
    width: 48px;

    img {
        width: 1.5rem;
    }

    &:focus,
    &:hover {
        background-color: $uix-shade-white;
    }
}

.fb-scanner-button-group {
    align-content: center;
    align-items: center;
    bottom: 0;
    display: flex;
    gap: 0;
    justify-content: center;
    margin: auto;
    padding: 10px;
    position: absolute;
    width: 100%;
    z-index: 2;
}

.scanner {
    height: 60vh;
}

.fullscreen {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
}
</style>
