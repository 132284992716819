import apiErrorHandler from '../plugins/apiErrorHandler';
import axios from '@/plugins/axiosConfig';

export default async function (scannedId, truckId, isAttached) {
    return await axios({
        method: 'post',
        url: `${
            process.env.VUE_APP_EXT_API_URL
        }api/trailers/byqid/${scannedId}/trucks/${truckId}/${
            isAttached === true ? 'attach' : 'detach'
        }`,
    })
        .then((r) => {
            r.data;
            return true;
        })
        .catch((error) => {
            return apiErrorHandler(error);
        });
}
