import apiErrorHandler from '@/plugins/apiErrorHandler';
import axios from '@/plugins/axiosConfig';
import { tryGetCurrentPosition } from '@/shared/helpers.js';
import apmTransactions from '@/libraries/apm/transactions';
import globalConstants from '@/plugins/globalConstants';

const { attach, detach } = globalConstants.trailer.assessmentVarieties;

async function postAssociation(association, dto) {
    const position = await tryGetCurrentPosition();
    dto.driver.location = position.coords;

    try {
        const response = await axios({
            method: 'post',
            url: `${process.env.VUE_APP_EXT_API_URL}api/trailerAssociations/${association}`,
            data: {
                ...(dto.load || {}),
                ...trailerToParams(dto.trailer),
                ...driverToParams(dto.driver),
            },
        });

        logTrailerAssociation(dto.trailer, response.data?.extras, association);
        return response.data;
    } catch (error) {
        return apiErrorHandler(error);
    }
}

export const postAttach = postAssociation.bind(null, attach);
export const postDetach = postAssociation.bind(null, detach);

function trailerToParams(trailer) {
    if ('compositeId' in trailer) {
        return { trailerId: trailer.compositeId };
    }

    return { scannedId: trailer.qrCode };
}

function driverToParams(driver) {
    const params = {
        reportedLocation: driver.location,
        ...(driver.loadDetails || {}),
    };

    if ('compositeId' in driver) {
        params.assetDriverId = driver.compositeId;
    } else {
        params.mobileNumber = driver.mobileNumber;
        params.driverName = driver.driverName;
    }

    return { driver: params };
}

function logTrailerAssociation(trailer, response, association) {
    if (association === attach && response?.detachResults?.length > 0) {
        logAutoDetaches(response.detachResults);
    }

    const trailerMapped = trailerToParams(trailer);
    const trailerIdentifier =
        trailerMapped?.trailerId || trailerMapped?.scannedId;

    apmTransactions.instrumentTrailerAssociation(
        trailerIdentifier,
        Object.keys(trailerMapped)[0],
        association
    );
}

function logAutoDetaches(detachResults) {
    detachResults.forEach((detachRes) => {
        apmTransactions.instrumentTrailerAssociation(
            detachRes?.trailer?.id,
            'autoDetach',
            detach
        );
    });
}
