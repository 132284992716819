<template>
    <ConfirmationOverlay
        v-if="$store.state.confirmationShown"
        :subtitleStyle="subtitleStyle"
        :acceptTheme="acceptTheme"
        :rejectTheme="rejectTheme"
        :titleText="$t(titleText, [assistanceCompany, assistanceNumber])"
        :subtitleText="$t(subtitleText, [assistanceCompany, assistanceNumber])"
        :acceptText="$t(acceptButtonText)"
        :rejectText="$t(rejectButtonText)"
        :acceptAction="acceptAction"
        :rejectAction="rejectAction"
        :acceptActionArgs="actionArgs"
        :rejectActionArgs="actionArgs"
        :acceptDestination="acceptDestination"
        :rejectDestination="rejectDestination"
        @confirmStep="changeStage"
    >
        <template v-if="warningText" v-slot:warning>
            <div :class="warningStyle" v-html="$t(warningText)"></div>
        </template>
    </ConfirmationOverlay>
</template>

<script>
import ConfirmationOverlay from '../../components/ConfirmationOverlay.vue';
import { mapGetters } from 'vuex';
import globalConstants from '../../plugins/globalConstants';

export default {
    props: {
        showBodyDamageConfirmation: {
            type: Boolean,
            default: false,
        },
        actionArgs: Object,
    },
    data: () => ({
        popupStage: 1,
        hasAccepted: true,
    }),
    components: {
        ConfirmationOverlay,
    },
    computed: {
        ...mapGetters(['getCurrentTrailerInfo']),
        trailerCompany() {
            return this.getCurrentTrailerInfo.trailerCompany;
        },
        assistanceCompany() {
            return this.trailerCompany === 'knight'
                ? globalConstants.trailer.assistance.knight
                : globalConstants.trailer.assistance.swift;
        },
        assistanceNumber() {
            return this.trailerCompany === 'knight'
                ? process.env.VUE_APP_KNIGHT_BREAKDOWN_NUMBER
                : process.env.VUE_APP_SWIFT_ONROAD_NUMBER;
        },
        acceptTheme() {
            return 'primary';
        },
        rejectTheme() {
            return !this.showBodyDamageConfirmation ? 'abort' : 'return';
        },
        subtitleStyle() {
            return !this.showBodyDamageConfirmation
                ? 'red'
                : this.hasAccepted
                ? 'strong-subtitle'
                : 'warning';
        },
        warningStyle() {
            return this.hasAccepted ? 'warning' : 'warning close';
        },
        titleText() {
            if (!this.showBodyDamageConfirmation) {
                return 'TrailerAssessment.confirmationTitle';
            } else {
                return this.popupStage === 1
                    ? 'TrailerAssessment.popup.firstTitle'
                    : this.hasAccepted
                    ? 'TrailerAssessment.popup.secondTitleAccepted'
                    : 'TrailerAssessment.popup.secondTitleDeclined';
            }
        },
        subtitleText() {
            if (!this.showBodyDamageConfirmation) {
                return 'TrailerAssessment.confirmationSubtitle';
            } else {
                return this.popupStage === 2 && this.hasAccepted
                    ? 'TrailerAssessment.popup.secondSubTitleAccepted'
                    : 'TrailerAssessment.popup.secondSubTitleDeclined';
            }
        },
        warningText() {
            if (!this.showBodyDamageConfirmation) {
                return null;
            } else {
                return this.popupStage === 2
                    ? 'TrailerAssessment.popup.secondWarning'
                    : 'TrailerAssessment.popup.firstWarning';
            }
        },
        acceptButtonText() {
            if (!this.showBodyDamageConfirmation) {
                return 'TrailerAssessment.acceptText';
            } else {
                return this.popupStage === 1
                    ? 'TrailerAssessment.popup.firstConfirm'
                    : this.hasAccepted
                    ? 'TrailerAssessment.popup.secondConfirmAccepted'
                    : 'TrailerAssessment.popup.secondConfirmDeclined';
            }
        },
        rejectButtonText() {
            if (!this.showBodyDamageConfirmation) {
                return 'TrailerAssessment.rejectText';
            } else {
                return this.popupStage === 1
                    ? 'TrailerAssessment.popup.firstDecline'
                    : 'TrailerAssessment.popup.secondDecline';
            }
        },
        acceptAction() {
            if (!this.showBodyDamageConfirmation) {
                return 'confirmTrailerAssessmentWithRedTag';
            } else {
                return this.popupStage === 1
                    ? 'skip'
                    : this.hasAccepted
                    ? 'acceptTrailerWithBodyDamageWithPossessionAccepted'
                    : 'acceptTrailerWithBodyDamageWithBreakdownAccepted';
            }
        },
        rejectAction() {
            if (!this.showBodyDamageConfirmation) {
                return;
            } else {
                return this.popupStage === 1
                    ? 'skip'
                    : this.hasAccepted
                    ? 'confirmTrailerAssessmentWithPossessionDeclined'
                    : 'confirmTrailerAssessmentWithBreakdownDeclined';
            }
        },
        acceptDestination() {
            if (!this.showBodyDamageConfirmation) {
                return 'TrailerNotFit';
            } else {
                return this.popupStage === 1 ? null : 'LoadConfirmation';
            }
        },
        rejectDestination() {
            if (!this.showBodyDamageConfirmation) {
                return null;
            } else {
                return this.popupStage === 1 ? null : 'TrailerNotFit';
            }
        },
    },
    methods: {
        changeStage(val) {
            this.$store.dispatch(
                'noteTrailerAssessmentQuestionDotSafe',
                val.didAccept
            );
            this.popupStage = 2;
            this.hasAccepted = val.didAccept;
        },
    },
};
</script>

<style lang="scss" scoped>
.warning {
    color: $uix-color-error;
    font-style: italic;
    font-weight: bold;
    font-size: min(3.5vw, 1.15rem);
    margin-top: -10px;
    padding-bottom: 20px;

    &.close {
        margin-top: -30px;
    }
}
</style>
