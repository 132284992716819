<template>
    <div>
        <div v-if="currentLoad" class="fb-load">
            <div class="fb-container">
                <!-- TODO: You can use this same element as a card on the "find load" and "confirm load" pages, just add the "has-border" class -->
                <div class="fb-load-card">
                    <div class="fb-load-itinerary">
                        <div class="fb-load-origin">
                            <div class="fb-load-bullet" />
                            <div class="fb-load-label">
                                <small>{{ currentLoad?.origin?.name }}</small>
                                <strong>{{ originCityState }}</strong>
                            </div>
                        </div>
                        <div class="fb-load-details">
                            <div class="fb-load-bullet">
                                <svg
                                    version="1.1"
                                    class="fb-svg box-moving"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 42.2 27"
                                    style="enable-background: new 0 0 42.2 27"
                                    xml:space="preserve"
                                >
                                    <path
                                        class="fb-path box-moving"
                                        d="M42.2,6L42.2,6C42.2,5.9,42.2,5.9,42.2,6c0-0.1,0-0.2,0-0.2l0,0l0,0l0,0l0,0l0,0 c-0.1-0.1-0.1-0.1-0.2-0.1L29.1,0.1c-0.2-0.1-0.4-0.1-0.5,0L14.4,5.5h-0.1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0c0,0,0,0.1-0.1,0.1 c0,0,0,0.1-0.1,0.1l0,0l0,0c0,0,0,0,0,0.1l-0.2,1.5H4.7c-0.5,0-0.8,0.4-0.8,0.9S4.3,9,4.7,9h12.2c0.3,0,0.6,0.2,0.6,0.5 s-0.2,0.6-0.5,0.6l0,0h-3.7L13,12.2H0.9C0.4,12.2,0,12.5,0,13s0.4,0.9,0.8,0.9c0,0,0,0,0.1,0h15.3c0.3,0,0.6,0.2,0.6,0.5 S16.6,15,16.3,15l0,0h-3.6l-0.2,1.7H5.9c-0.5,0-0.9,0.4-0.9,0.8s0.4,0.9,0.8,0.9c0,0,0,0,0.1,0h9.4c0.3,0,0.5,0.3,0.5,0.6 c0,0.3-0.2,0.5-0.5,0.5h-3.4l-0.2,1.3c0,0.3,0.1,0.5,0.3,0.6L24.8,27l0,0h0.1l0,0H25l0,0h0.1l0,0h0.1l0,0l14.3-5.5 c0.3-0.1,0.5-0.4,0.5-0.6l2.1-14.7c0,0,0,0,0-0.1C42.2,6.1,42.2,6.1,42.2,6L42.2,6L42.2,6 M28.7,1.4l11,4.7l-4.1,1.6L24.6,3 L28.7,1.4z M16.4,6.2l4.5-1.7l11,4.7l-4.5,1.7L16.4,6.2z M38.8,20.4L26,25.3l1.9-13.2l4.9-1.9l-0.9,6.4l3.1-1.1L35.9,9l4.8-1.8 L38.8,20.4z"
                                    />
                                </svg>
                            </div>
                            <div class="fb-load-label">
                                <strong
                                    >{{ $t('DetachTrailerSuccess.preload')
                                    }}{{ currentLoad?.loadNumber }}</strong
                                >
                            </div>
                        </div>
                        <div class="fb-load-destination">
                            <div class="fb-load-bullet" />
                            <div class="fb-load-label">
                                <small>{{
                                    currentLoad?.destination?.name
                                }}</small>
                                <strong>{{ destinationCityState }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fb-container body">
            <div class="fb-form">
                <!-- TODO: Add "mt-60" class when clear button above is displayed -->
                <div class="fb-form-image trailer-side success mt-60">
                    <font-awesome-icon :icon="['fas', 'circle-check']" />
                    <svg
                        version="1.1"
                        class="fb-svg trailer-side"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 280.5 96.5"
                        style="enable-background: new 0 0 280.5 96.5"
                        xml:space="preserve"
                    >
                        <path
                            class="fb-path trailer-outline"
                            d="M50.2,84.6c0-6.3,5.1-11.4,11.4-11.4l0,0c6.3,0,11.4,5.1,11.4,11.4l0,0C73.1,90.9,68,96,61.6,96 l0,0C55.3,96,50.2,90.9,50.2,84.6L50.2,84.6z M55.8,84.6c0,3.3,2.6,5.9,5.9,5.9l0,0c3.3,0,5.9-2.6,5.9-5.9l0,0 c0-3.3-2.6-5.9-5.9-5.9l0,0C58.4,78.7,55.8,81.3,55.8,84.6L55.8,84.6z M23.6,84.6c0-6.3,5.1-11.4,11.4-11.4l0,0 c6.3,0,11.4,5.1,11.4,11.4l0,0C46.4,90.9,41.3,96,35,96l0,0C28.7,96,23.6,90.9,23.6,84.6L23.6,84.6z M29.1,84.6 c0,3.3,2.6,5.9,5.9,5.9s5.9-2.6,5.9-5.9l0,0c0-3.3-2.6-5.9-5.9-5.9l0,0C31.8,78.7,29.1,81.3,29.1,84.6z M201.5,91.2V74.1h-99.8 l-21.5,6.5c0,0.1,0,0.2,0,0.3V87h-4.6c0.1-0.8,0.2-1.6,0.2-2.4c0-7.8-6.3-14.2-14.2-14.2c-6,0-11.3,3.8-13.3,9.4 c-2-5.6-7.3-9.4-13.3-9.4c-7.8,0-14.2,6.3-14.2,14.2c0,0.8,0.1,1.6,0.2,2.4h-4.4v-6.1c0-0.3,0-0.7,0.1-1L2.2,74.3v-7.9H280v5.3 h-73.5v19.5L201.5,91.2z M1,56v-6h4c0.6,0,1,0.4,1,1l0,0v4c0,0.6-0.4,1-1,1l0,0H1z M1,38v-6h4c0.6,0,1,0.4,1,1l0,0v4 c0,0.6-0.4,1-1,1l0,0H1z M1,20v-6h4c0.6,0,1,0.4,1,1l0,0v4c0,0.6-0.4,1-1,1l0,0H1z"
                        />
                        <g>
                            <path
                                class="fb-path trailer-box"
                                d="M278.5,67.5H1.5V6.8c0-2.9,2.4-5.3,5.3-5.3h266.5c2.9,0,5.3,2.4,5.3,5.3L278.5,67.5L278.5,67.5z"
                            />
                            <path
                                class="fb-path trailer-outline"
                                d="M6.8,3C4.7,3,3,4.7,3,6.8V66h274V6.8c0-2.1-1.7-3.8-3.8-3.8L6.8,3 M6.8,0h266.5c3.7,0,6.8,3,6.8,6.8V69H0V6.8 C0,3,3,0,6.8,0z"
                            />
                        </g>
                    </svg>
                </div>

                <div class="fb-form-heading-group">
                    <h1 class="fb-form-heading">
                        {{ $t('DetachTrailerSuccess.thankYou') }}
                    </h1>
                    <span class="fb-form-sub-heading"
                        >{{ $t('DetachTrailerSuccess.dryVan')
                        }}{{ getCurrentTrailerInfo?.trailerId }}</span
                    >
                </div>

                <div class="fb-form-button-group footer-buttons">
                    <button
                        type="button"
                        class="btn btn-link fb-form-button"
                        @click="onScanClicked"
                    >
                        <em>{{ $t('Scan.differentTrailerButton') }}</em>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FbaApiError from '../models/ApiError';
import globalConstants from '../plugins/globalConstants';

export default {
    data: () => ({
        user: null,
        currentLoad: null,
    }),

    computed: {
        ...mapGetters([
            'getCurrentTrailerInfo',
            'validateUserDetails',
            'getUserDetails',
        ]),
        ...mapGetters({ currentLoadInfo: 'loadStore/getCurrentLoadInfo' }),

        originCityState() {
            return (
                this.currentLoad?.origin?.city.trim() +
                ', ' +
                this.currentLoad?.origin?.state.trim()
            );
        },

        destinationCityState() {
            return (
                this.currentLoad?.destination?.city.trim() +
                ', ' +
                this.currentLoad?.destination?.state.trim()
            );
        },
    },

    async created() {
        const validUser = this.validateUserDetails;
        if (!validUser) {
            this.$router.push({ name: 'GetStarted' });
            return;
        }
        this.user = this.getUserDetails;
        await this.getCurrentLoad();
        this.setLoading(false);
    },

    methods: {
        async getCurrentLoad() {
            const loadInfo = this.currentLoadInfo;

            if (loadInfo) {
                this.currentLoad = loadInfo;
            } else {
                const response = await this.$store.dispatch(
                    'loadStore/getAttachedLoadByDriverNumber',
                    this.user?.mobileNumber
                );
                if (
                    response instanceof FbaApiError &&
                    response.statusCode ===
                        globalConstants.httpStatusCodes.timeout
                ) {
                    this.$router.push({ name: history.go(-1) });
                    this.setLoading(false);
                    return;
                }
                this.currentLoad = response;
            }
        },
        async onScanClicked() {
            this.setLoading(true);
            await this.$store.commit('clearStaleDataAfterAssociationSuccess');
            this.$router.push({ name: 'Scan' });
        },
    },
};
</script>

<style lang="scss" scoped>
.fb-load-bullet {
    align-content: center;
    align-items: center;
    background-color: $uix-shade-white;
    border: 2px solid #c3c3bf;
    display: flex;
    flex-flow: row nowrap;
    gap: 0;
    justify-content: center;
    position: absolute;
}

.fb-load-label {
    position: absolute;
    white-space: nowrap;
    width: 160px;

    > strong,
    > small {
        color: $uix-paragraph-text-color;
        display: block;
        position: relative;
    }

    > strong {
        font-size: 0.875rem; // 14px
        font-weight: 600;
    }

    > small {
        font-size: 0.75rem; // 12px
        font-weight: 400;
        font-style: italic;
    }
}

.fb-load-origin,
.fb-load-details,
.fb-load-destination {
    background-color: #c3c3bf;
    height: 2px;
    position: absolute;
    top: -2px;
    width: 2px;
}

.fb-load-origin,
.fb-load-destination {
    .fb-load-bullet {
        border-radius: 6px;
        height: 12px;
        left: -5px;
        top: -5px;
        width: 12px;
    }

    .fb-load-label {
        margin: 18px 0 0;
        top: 100%;
    }
}

.fb-load-origin {
    left: 0;
    margin: 0;

    .fb-load-label {
        left: -20px;
        text-align: left;
    }
}

.fb-load-destination {
    left: 100%;
    margin: 0 0 0 -2px;

    .fb-load-label {
        right: -20px;
        text-align: right;
    }
}

.fb-load-details {
    left: 50%;
    margin: 0 0 0 -1px;

    .fb-load-bullet {
        border-radius: 28px;
        height: 56px;
        left: -27px;
        top: -27px;
        width: 56px;

        > svg {
            height: 27px;
            width: 42.1875px;
        }
    }

    .fb-load-label {
        bottom: 100%;
        margin: 0 0 38px;
        right: -80px;
    }
}

.fb-load-itinerary {
    border-top: 2px dashed #c3c3bf;
    display: inline-block;
    height: 0;
    margin: -4px 0 0;
    max-width: 100%;
    min-width: 100%;
    position: relative;
    width: 100%;
}

.fb-load-card {
    align-content: center;
    align-items: center;
    border-radius: 4px;
    display: flex;
    flex-flow: row nowrap;
    gap: 0;
    justify-content: center;
    height: 168px;
    margin: 0 auto;
    padding: 20px 30px;
    position: relative;
    width: 100%;

    &.has-border {
        background-color: rgba($uix-shade-white, 0.5);
        border: 1px solid $uix-shade;
        margin-bottom: 40px;
        margin-top: 30px;
        padding: 20px 40px;
    }
}

.fb-load {
    align-content: center;
    align-items: center;
    background-color: $uix-shade-alt-one;
    border-bottom: 5px solid $uix-navbar-border-color;
    display: flex;
    flex-flow: column nowrap;
    gap: 0;
    justify-content: center;
    margin: -3px auto 0;
    position: relative;
    text-align: center;
    width: 100%;

    .fb-container {
        align-content: center;
        align-items: center;
        justify-content: center;
    }
}

.fb-svg {
    &.trailer-side {
        height: 99.7639px;
        margin: 0;
        position: relative;
        width: 290px;
        z-index: 0;
    }
}

.fb-path {
    &.trailer-outline {
        fill: $uix-heading-text-color;
    }

    &.trailer-box {
        fill: rgba($uix-heading-text-color, 0);
    }
}

.fb-form-image {
    &.trailer-side {
        color: $uix-heading-text-color;
        display: inline-block;
        margin: 40px auto 0;
        position: relative;

        .fa-circle-question,
        .fa-circle-check,
        .fa-circle-xmark {
            font-size: 32px;
            left: 50%;
            margin: -30px 0 0 -16px;
            position: absolute;
            top: 50%;
            z-index: 1;
        }

        &.success {
            .fb-path {
                &.trailer-outline {
                    fill: $uix-color-success;
                }

                &.trailer-box {
                    fill: rgba($uix-color-success, 0);
                }
            }

            .fa-circle-check {
                color: $uix-color-success;
            }
        }

        &.error {
            .fb-path {
                &.trailer-outline {
                    fill: $uix-color-error;
                }

                &.trailer-box {
                    fill: rgba($uix-color-error, 0);
                }
            }

            .fa-circle-xmark {
                color: $uix-color-error;
            }
        }

        &.mt-60 {
            margin-top: 60px;
        }
    }
}

.fb-form-heading-group {
    margin: 20px 0 30px;

    .fb-form-heading {
        margin-top: 0;
    }

    .fb-form-sub-heading {
        margin-bottom: 0;
    }

    &.mb-60 {
        margin-bottom: 60px;
    }
}

.fb-form-paragraph {
    margin: 15px 0 60px;
    text-align: left;
}
</style>
