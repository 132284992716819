import UAParser from 'ua-parser-js';

const globalMethods = {
    install(Vue, options) {
        Vue.mixin({
            methods: {
                setLoading(bool, callback) {
                    this.$store.dispatch('setGlobalLoading', bool);
                    if (callback) {
                        callback(bool);
                    }
                },
                titleCase(value) {
                    return value?.replace(/(?:^|\s|-)\S/g, (x) =>
                        x.toUpperCase()
                    );
                },
            },
        });
    },
};

export default globalMethods;
