<template>
    <div class="app-container" :style="addedVars">
        <FBAHeader />
        <transition name="fade">
            <PageOverlay v-show="$store.state.globalLoading" />
        </transition>
        <transition name="fade">
            <TopBarNotification
                v-if="notificationMessage"
                :alertMessage="notificationMessage"
            />
        </transition>
        <router-view class="fb-body" />
        <PopupAlert
            v-if="activeAlertLocaleKey"
            :alert-text-locale-key="activeAlertLocaleKey"
        />
        <FBAFooter />
        <!-- TODO: Update every button to use fbx-button and the new action classes -->
        <!-- Currently, only the admin pages use this loading indicator -->
        <div
            :class="{
                'fb-progress-overlay': true,
                show: $store.state.adminGlobalLoading,
            }"
        />
    </div>
</template>

<script>
import PageOverlay from '@/views/PageOverlay';
import FBAFooter from '@/components/FBAFooter';
import FBAHeader from '@/components/FBAHeader';
import PopupAlert from '@/components/PopupAlert';
import TopBarNotification from '@/components/TopBarNotification';
import { mapGetters } from 'vuex';
import apmTransactions from '@/libraries/apm/transactions';

export default {
    components: {
        FBAHeader,
        PageOverlay,
        FBAFooter,
        PopupAlert,
        TopBarNotification,
    },
    data: () => ({
        windowHeight: null,
    }),
    computed: {
        ...mapGetters(['activeAlertLocaleKey', 'notificationMessage']),
        addedVars() {
            const height = this.windowHeight || window.innerHeight;

            return { '--innerHeight': height + 'px' };
        },
    },
    methods: {
        onResize() {
            this.windowHeight = window.innerHeight;
        },
    },
    beforeCreate() {
        this.$store.commit('initializeStore');
    },
    created() {
        apmTransactions.setUserDetails();
        this.windowHeight = window.innerHeight;
        window.addEventListener('resize', this.onResize);
        window.addEventListener(
            'appinstalled',
            apmTransactions.instrumentPwaInstalled
        );
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
        window.removeEventListener(
            'appinstalled',
            apmTransactions.instrumentPwaInstalled
        );
    },
};
</script>

<style lang="scss">
@import './styles/bootstrap-import.scss';

html,
body {
    background-color: $uix-shade-alt-one;
}

#app {
    background: rgb(247, 246, 245);
    background: linear-gradient(
        0deg,
        rgba(247, 246, 245, 1) 0%,
        rgba(253, 252, 252, 1) 100%
    );
    color: $uix-paragraph-text-color;
    font-family: 'Open Sans', Avenir, Helvetica, Arial, sans-serif;
    height: 100%;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    width: 100%;
}

.fb-body {
    display: inline-block;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    z-index: 1;
}

.fade-enter-active {
    animation: fade-in 0.2s;
}

.fade-leave-active {
    animation: fade-in 0.1s reverse;
}

.fb-progress-overlay {
    background-color: rgba($uix-shade-white, 0);
    bottom: 0;
    display: none;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999;

    &:hover {
        cursor: default;
    }

    &.show {
        display: block;
    }
}
</style>
