import store from './index';
import { isEmpty } from 'lodash';
import FbaApiError from '../models/ApiError';
import globalConstants from '../plugins/globalConstants';
import { isNullOrUndefined } from '@/shared/helpers';
import apmTransactions from '../libraries/apm/transactions';
import queries from '@/queries';

export default {
    namespaced: true,
    state: {
        availableLoads: null,
        currentLoadInfo: null,
        selectedLoad: null,
    },
    mutations: {
        clearLoadStore() {
            localStorage.removeItem(
                globalConstants.localStorageKeys.availableLoadsKey
            );
            localStorage.removeItem(
                globalConstants.localStorageKeys.currentLoadInfoKey
            );
        },
        initializeLoadsStore(state) {
            const availableLoads = localStorage.getItem(
                globalConstants.localStorageKeys.availableLoadsKey
            );
            const currentLoadInfo = localStorage.getItem(
                globalConstants.localStorageKeys.currentLoadInfoKey
            );
            if (availableLoads) {
                state.availableLoads = JSON.parse(availableLoads);
            }
            if (currentLoadInfo) {
                state.currentLoadInfo = JSON.parse(currentLoadInfo);
            }
        },
        saveAvaliableLoads(state, availableLoads) {
            localStorage.setItem(
                globalConstants.localStorageKeys.availableLoadsKey,
                JSON.stringify(availableLoads)
            );
            state.availableLoads = availableLoads;
        },
        saveCurrentLoadInfo(state, currentLoadInfo) {
            localStorage.setItem(
                globalConstants.localStorageKeys.currentLoadInfoKey,
                JSON.stringify(currentLoadInfo)
            );
            state.currentLoadInfo = currentLoadInfo;
        },
        clearCurrentLoadInfo(state) {
            state.currentLoadInfo = null;
            localStorage.removeItem(
                globalConstants.localStorageKeys.currentLoadInfoKey
            );
        },
        setSelectedLoad(state, selectedLoad) {
            state.selectedLoad = selectedLoad;
        },
    },
    actions: {
        async getLoad({ commit, state }, loadNumber) {
            var load = await queries.getLoad(
                loadNumber,
                store.state.userDetails.dotNumber
            );
            return load;
        },
        async getAllLoadsByDriverNumber({ commit, state }, driverNumber) {
            try {
                const response = await queries.getLoadsByDriverNumber(
                    driverNumber
                );
                if (!isEmpty(response)) {
                    commit('saveAvaliableLoads', response);
                }
                return response;
            } catch (e) {
                console.error(e);
                return new FbaApiError();
            }
        },

        async getAttachedLoadByDriverNumber({ commit, state }, driverNumber) {
            try {
                const response = await queries.getAttachedLoadByDriverNumber(
                    driverNumber
                );
                if (!isEmpty(response)) {
                    commit('saveCurrentLoadInfo', response);
                }
                return response;
            } catch (e) {
                console.error(e);
                return new FbaApiError();
            }
        },
    },
    getters: {
        getSelectedLoad: (state) => {
            return state?.selectedLoad;
        },
        getCurrentLoadInfo: (state) => {
            return state?.currentLoadInfo;
        },

        getCurrentLoadNumber: (state, getters) => {
            if (!isNullOrUndefined(state?.currentLoadInfo?.loadNumber)) {
                return state.currentLoadInfo.loadNumber;
            } else if (!isNullOrUndefined(getters['lastEvent'])) {
                return getters['lastEvent'].loadNumber;
            }
            return null;
        },
    },
};
