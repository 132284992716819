<template>
    <div>
        <ConfirmationOverlay
            v-if="helpModalVisible"
            @confirmStep="dismissHelpModal"
            :titleText="$t('TrailerScan.infoModal.heading')"
            :acceptText="$t('TrailerScan.infoModal.accept')"
            :noReject="true"
            acceptAction="skip"
            icon="fa-lightbulb"
        />
        <div class="fb-container body">
            <div class="fb-form">
                <h1 class="fb-form-heading" data-test="scanner-title">
                    {{ $t('Scan.title') }}
                </h1>
                <p
                    class="fb-form-paragraph directions"
                    data-test="scanner-subtitle"
                >
                    {{ $t('Scan.text1') }}
                    <em>{{ $t('Scan.text2') }}</em>
                </p>

                <fbx-qr-scanner
                    @scanned="updateScannedID"
                    @scanError="$router.push({ name: 'EnterTrailerDetails' })"
                />

                <div class="fb-form-support">
                    <p
                        class="fb-form-paragraph question"
                        data-test="scanner-unable-to-scan-text"
                    >
                        {{ $t('Scan.unableToScan') }}
                    </p>
                    <fbx-button
                        data-test="scanner-unable-to-scan-button"
                        theme="link"
                        :button-text="$t('Scan.manualInputButton')"
                        @clicked="$router.push({ name: 'EnterTrailerDetails' })"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FbxQrScanner from '../components/fbx-qr-scanner.vue';
import FbxButton from '../components/fbx-button.vue';
import ConfirmationOverlay from '../components/ConfirmationOverlay.vue';

export default {
    name: 'Scan',
    components: { FbxQrScanner, FbxButton, ConfirmationOverlay },
    data: function () {
        return {
            camera: 'rear',
            trailerCompany: '',
            trailerNumber: '',
            scannerActive: true,
            helpModalVisible: true,
        };
    },
    computed: {},
    created() {
        // Cleaning localStorage to renew the load info
        this.$store.commit('clearLoadStore');
    },
    methods: {
        updateScannedID(value) {
            this.setLoading(true);
            this.$store.dispatch('setNewQid', value);
            this.$router.push({ name: 'TrailerScan', params: { id: value } });
        },
        dismissHelpModal() {
            this.helpModalVisible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.fb-form-paragraph {
    &.directions {
        margin: 0 0 30px;

        > em {
            display: block;
        }
    }

    &.question {
        margin: 0;
    }
}

.fb-form-support {
    margin: 30px auto 40px;

    .btn {
        min-height: 0;
        padding: 2px 12px;
    }
}
</style>
