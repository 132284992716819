import FbaApiError from '@/models/ApiError';
import axios from '@/plugins/axiosConfig';
import apmTransactions from '@/libraries/apm/transactions';

export default async function getAssociatabilityEvaluation(association, dto) {
    const endpoint =
        association === 'attach'
            ? 'driverTrailerAttachability'
            : 'driverTrailerDetachability';
    const url = `${process.env.VUE_APP_EXT_API_URL}api/trailerAssociations/${endpoint}`;

    const response = await axios({
        method: 'post',
        url,
        data: {
            ...trailerToParams(dto.trailer),
            ...driverToParams(dto.driver),
        },
    }).catch((error) => error.response);

    if (response.status === 200) {
        const rawResponse = rawResponseToResponse(response.data);
        logResponseWarnOrFail(rawResponse, association, dto.trailer);
        return rawResponse;
    }

    const { data } = response;
    if ('validationErrors' in data) {
        logTrailerNotFound(data.validationErrors, dto.trailer);
        return data.validationErrors;
    }

    return new FbaApiError(data);
}

function trailerToParams(trailer) {
    if ('compositeId' in trailer) {
        return { trailerId: trailer.compositeId };
    }

    return { scannedId: trailer.qrCode };
}

function driverToParams(driver) {
    const params = {};

    if ('compositeId' in driver) {
        params.assetDriverId = driver.compositeId;
    } else {
        params.mobileNumber = driver.mobileNumber;
    }

    if ('location' in driver) {
        params.reportedLocation = driver.location;
    }

    return { driver: params };
}

function logResponseWarnOrFail(attachabilityResponse, association, trailer) {
    const failOrWarnEvaluationTypes = [];

    attachabilityResponse.attachabilityEvaluations.forEach((aE) => {
        if (aE.result !== 'pass') {
            failOrWarnEvaluationTypes.push(aE.name.toString());
        }
    });

    if (failOrWarnEvaluationTypes.length > 0) {
        const trailerMapped = trailerToParams(trailer);

        apmTransactions.instrumentFailedAssociabilityRule(
            association,
            failOrWarnEvaluationTypes,
            trailerMapped?.trailerId || trailerMapped?.scannedId,
            Object.keys(trailerMapped)[0]
        );
    }
}

function logTrailerNotFound(validationErrors, trailer) {
    if (validationErrors.some((err) => err?.field === 'Trailer Not Found')) {
        const trailerMapped = trailerToParams(trailer);

        apmTransactions.instrumentTrailerNotFound(
            trailerMapped?.trailerId || trailerMapped?.scannedId,
            Object.keys(trailerMapped)[0]
        );
    }
}

function rawResponseToResponse(rawResponse) {
    const attachabilityEvaluations = rawResponse.attachabilityEvaluations.map(
        (e) => ({
            ...e,
            name: nameMap[e.evaluationType],
            result: resultMap[e.result],
        })
    );

    return {
        ...rawResponse,
        attachabilityEvaluations,
    };
}

const RawEvaluationResult = Object.freeze({
    Pass: 0,
    Warn: 1,
    Fail: 2,
});

const RawEvaluationType = Object.freeze({
    TrailerStatus: 0,
    TrailerAttached: 1,
    TrailerReserved: 2,
    TrailerProximity: 3,
    TrailerType: 4,
});

const resultMap = {
    [RawEvaluationResult.Fail]: 'fail',
    [RawEvaluationResult.Pass]: 'pass',
    [RawEvaluationResult.Warn]: 'warn',
};

const nameMap = {
    [RawEvaluationType.TrailerAttached]: 'trailer_attached',
    [RawEvaluationType.TrailerProximity]: 'trailer_proximity',
    [RawEvaluationType.TrailerReserved]: 'trailer_reserved',
    [RawEvaluationType.TrailerStatus]: 'trailer_status',
    [RawEvaluationType.TrailerType]: 'trailer_type',
};
