import apiErrorHandler from '../plugins/apiErrorHandler';
import axios from '@/plugins/axiosConfig';

export default async function (documentDtos) {
    return await axios({
        method: 'post',
        url: `${process.env.VUE_APP_EXT_API_URL}api/documents`,
        data: documentDtos,
    })
        .then((r) => r)
        .catch((error) => {
            return apiErrorHandler(error);
        });
}
