import { createI18n } from 'vue-i18n/index';
import en from './locale/en.json';
import es from './locale/es.json';
import ps from './locale/ps.json';

const messages = {
    es,
    en,
    ps,
};

export const i18n = createI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});
