<template>
    <div>
        <div class="fb-container body">
            <img
                class="fb-form-logo"
                src="../../public/img/knx_logistics.png"
                alt="Knight Swift Logistics"
            />
            <div v-if="load == null" class="fb-form">
                <h1 class="fb-form-heading">
                    {{ $t('LoadLookup.default.title') }}
                </h1>
                <p class="fb-form-paragraph">
                    {{ $t('LoadLookup.default.subTitle') }}
                </p>
                <div class="fb-form-group">
                    <input
                        id="LoadNumber"
                        v-model="loadNumber"
                        type="tel"
                        class="fb-form-control"
                        :class="loadNumberErrorClass"
                        placeholder="Load Number"
                        required
                    />
                    <label
                        class="fb-form-label"
                        for="LoadNumber"
                        v-text="loadNumberLabel"
                    />
                </div>
                <button
                    :class="
                        loadNumber === null ||
                        loadNumber === undefined ||
                        loadNumber.length === 0
                            ? 'fb-form-button-disabled'
                            : 'fb-form-button'
                    "
                    :disabled="
                        loadNumber === null ||
                        loadNumber === undefined ||
                        loadNumber.length === 0
                    "
                    @click="onSearchClicked"
                >
                    {{ $t('LoadLookup.default.submitButton') }}
                </button>
            </div>
            <div
                v-else-if="load.isFound == false && load.reasonCode === 470"
                class="fb-form"
            >
                <h1 class="fb-form-heading">
                    {{ $t('LoadLookup.notFound.title') }}
                </h1>
                <i18n-t
                    class="fb-form-paragraph"
                    keypath="LoadLookup.notFound.subTitle"
                    tag="p"
                >
                    <a :href="`tel:${supportNumber}`">{{ supportNumber }}</a>
                </i18n-t>
                <button class="fb-form-button" @click="onResetSearchClicked">
                    {{ $t('LoadLookup.notFound.submitButton') }}
                </button>
            </div>
            <div
                v-else-if="load.isFound == false && load.reasonCode === 472"
                class="fb-form"
            >
                <h1 class="fb-form-heading">
                    {{ $t('LoadLookup.alreadyCompleted.title') }}
                </h1>
                <i18n-t
                    class="fb-form-paragraph"
                    keypath="LoadLookup.alreadyCompleted.subTitle"
                    tag="p"
                >
                    <a :href="`tel:${supportNumber}`">{{ supportNumber }}</a>
                </i18n-t>
                <button class="fb-form-button" @click="onResetSearchClicked">
                    {{ $t('LoadLookup.notFound.submitButton') }}
                </button>
            </div>
            <div v-else-if="load.isFound == true" class="fb-form">
                <h1 class="fb-form-heading">
                    {{ $t('LoadLookup.wasFound.title') }}
                </h1>
                <p class="fb-form-paragraph">
                    <br />{{ $t('LoadLookup.wasFound.subTitle.load') }}
                    <strong>{{ load.loadNumber }}</strong
                    ><br />
                    {{ $t('LoadLookup.wasFound.subTitle.customer') }}
                    <strong>{{ load.customerName }}</strong
                    ><br />
                    {{ $t('LoadLookup.wasFound.subTitle.from') }}
                    <strong
                        >{{ load.origin.city }}, {{ load.origin.state }}</strong
                    >
                    {{ $t('LoadLookup.wasFound.subTitle.to') }}
                    <strong
                        >{{ load.destination.city }},
                        {{ load.destination.state }}</strong
                    ><br /><br />
                    <button
                        class="fb-form-button"
                        @click="onYesClicked(load.loadNumber)"
                    >
                        {{ $t('LoadLookup.wasFound.submitButton') }}
                    </button>
                </p>
                <div>
                    <a href="#" @click="onResetSearchClicked">{{
                        $t('LoadLookup.wasFound.rejectButton')
                    }}</a>
                </div>
            </div>
            <p class="fb-form-disclaimer">
                <strong
                    >Please refer to the rate confirmation for the load and stop
                    information.</strong
                >
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        load: null,
        loadNumber: null,
        supportNumber: null,
    }),
    created() {
        this.validateUser();
        this.supportNumber = process.env.VUE_APP_SUPPORT_NUMBER;
    },
    methods: {
        onResetSearchClicked() {
            this.loadNumber = '';
            this.loadNumberErrorClass = null;
            this.loadNumberLabel = null;
            this.load = null;
        },
        async onSearchClicked() {
            this.loadNumberErrorClass = null;
            this.loadNumberLabel = null;
            if (this.loadNumber === '' || this.loadNumber === null) {
                this.loadNumberErrorClass = 'has-error';
                this.loadNumberLabel = 'Required';
                return;
            }
            this.setLoading(true);
            this.load = await this.$store.dispatch(
                'loadStore/getLoad',
                this.loadNumber
            );
            this.setLoading(false);
        },
        async onYesClicked(loadNumber) {
            var data = {
                loadNumber: this.load.loadNumber,
            };
            this.setLoading(true);
            await this.$store.dispatch('attachDriverTrailerLoad', data);
            this.$router.push({ path: '/Success' });
            this.setLoading(false);
        },
        validateUser() {
            this.isValidUser = this.$store.state.savedUserDetail !== null;

            if (this.isValidUser === false) {
                this.$router.push({ name: 'getStarted' });
            }
        },
    },
};
</script>
