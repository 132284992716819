<template>
    <div>
        <div class="fb-container body">
            <div class="fb-form">
                <h1 class="fb-form-heading">
                    {{ $t('DamageScreens.title') }}
                </h1>
                <p class="fb-form-paragraph">
                    {{ $t('DamageScreens.brakesSubtitle') }}
                </p>

                <div class="fb-trailer-side-selector">
                    <svg
                        class="fb-svg trailer-back"
                        style="enable-background: new 0 0 237 347"
                        version="1.1"
                        viewBox="0 0 237 347"
                        x="0px"
                        xmlns="http://www.w3.org/2000/svg"
                        xml:space="preserve"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        y="0px"
                    >
                        <path
                            class="fb-path chassis"
                            d="M213,347c-1.7,0-3-1.3-3-3v-14h23v14c0,1.7-1.3,3-3,3H213z M185,347c-1.7,0-3-1.3-3-3v-14h24v14 c0,1.7-1.3,3-3,3H185z M34,347c-1.7,0-3-1.3-3-3v-14h23v14c0,1.7-1.3,3-3,3H34z M6,347c-1.7,0-3-1.3-3-3v-14h24v14c0,1.7-1.3,3-3,3 H6z M0,326v-12h58v-28H0v-16h237v16h-58v28h58v12H0z M162,314v-28h-26v14c0,1.1-0.9,2-2,2h-31c-1.1,0-2-0.9-2-2v-14H75v28H162z M210,311v-19c0-1.7,1.3-3,3-3h17c1.7,0,3,1.3,3,3v19H210z M182,311v-19c0-1.7,1.3-3,3-3h18c1.7,0,3,1.3,3,3v19H182z M31,311v-19 c0-1.7,1.3-3,3-3h17c1.7,0,3,1.3,3,3v19H31z M3,311v-19c0-1.7,1.3-3,3-3h18c1.7,0,3,1.3,3,3v19H3z"
                        />
                        <path
                            class="fb-path box"
                            d="M237,272H0V4c0-2.2,1.8-4,4-4h229c2.2,0,4,1.8,4,4V272L237,272L237,272z M6,4C4.9,4,4,4.9,4,6v262h229 V6c0-1.1-0.9-2-2-2H6z"
                        />
                        <path
                            class="fb-path doors"
                            d="M6,4h225c1.1,0,2,0.9,2,2v262H4V6C4,4.9,4.9,4,6,4z"
                        />
                        <path class="fb-path seal" d="M117,9h3v253h-3V9z" />
                        <path
                            class="fb-path hinges"
                            d="M228,215c-1.1,0-2-0.9-2-2v-9c0-1.1,0.9-2,2-2h9v13H228z M0,215v-13h8c1.1,0,2,0.9,2,2v9 c0,1.1-0.9,2-2,2H0z M228,142c-1.1,0-2-0.9-2-2v-9c0-1.1,0.9-2,2-2h9v13H228z M0,142v-13h8c1.1,0,2,0.9,2,2v9c0,1.1-0.9,2-2,2H0z M228,70c-1.1,0-2-0.9-2-2v-9c0-1.1,0.9-2,2-2h9v13H228z M0,70V57h8c1.1,0,2,0.9,2,2v9c0,1.1-0.9,2-2,2H0z"
                        />
                        <path
                            class="fb-path laches"
                            d="M125,262V9h5v253H125z M107,262v-21H95v2c0,0.6-0.4,1-1,1h-5c-0.6,0-1-0.4-1-1v-2H62 c-1.1,0-2-0.9-2-2v-1c0-1.1,0.9-2,2-2h26v-3c0-0.6,0.4-1,1-1h5c0.6,0,1,0.4,1,1v3h12V9h4v253H107z M143,244c-0.6,0-1-0.4-1-1v-2h-12 v-5h12v-3c0-0.6,0.4-1,1-1h5c0.6,0,1,0.4,1,1v3h25c1.1,0,2,0.9,2,2v1c0,1.1-0.9,2-2,2h-25v2c0,0.6-0.4,1-1,1H143z"
                        />
                    </svg>

                    <button
                        type="button"
                        class="btn btn-outline-dark input-radius fb-btn-selector bottom-left"
                        :class="driverSide"
                        @click="enterDriverSide"
                    >
                        <font-awesome-icon :icon="['fas', 'thumbs-down']" />
                    </button>

                    <button
                        type="button"
                        class="btn btn-outline-dark input-radius fb-btn-selector bottom-right"
                        :class="passengerSide"
                        @click="enterPassengerSide"
                    >
                        <font-awesome-icon :icon="['fas', 'thumbs-down']" />
                    </button>
                </div>

                <button
                    type="button"
                    class="btn btn-primary fb-form-button mb-5"
                    @click="$router.push({ name: 'TrailerAssessment' })"
                >
                    <span>{{ $t('DamageScreens.doneButton') }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {
    assessmentHasImagesOrComments,
    isNullOrUndefined,
} from '@/shared/helpers';
import globalConstants from '../../plugins/globalConstants';
import { mapGetters } from 'vuex';

export default {
    data: () => ({
        type: globalConstants.trailer.assessmentTypes.brakes,
        subTypes: {
            driverSide:
                globalConstants.trailer.assessmentTypes.subTypes.driverSide,
            passengerSide:
                globalConstants.trailer.assessmentTypes.subTypes.passengerSide,
        },
        typeAssessment: null,
        trailerData: null,
    }),
    async created() {
        this.setLoading(true);
        if (isNullOrUndefined(this.trailerAssessment))
            await this.$store.dispatch('getTrailerAssessmentFromIndexedDB');

        this.trailerData = this.$store.getters.getCurrentTrailerInfo;
        this.typeAssessment = this.trailerAssessment?.[this.type];
        this.setLoading(false);
    },
    computed: {
        ...mapGetters(['trailerAssessment']),
        driverSide() {
            return assessmentHasImagesOrComments(
                this.typeAssessment,
                this.subTypes.driverSide
            )
                ? 'active'
                : '';
        },
        passengerSide() {
            return assessmentHasImagesOrComments(
                this.typeAssessment,
                this.subTypes.passengerSide
            )
                ? 'active'
                : '';
        },
    },
    methods: {
        enterDriverSide() {
            this.$router.push({
                path: `/trailers/${this.trailerData.trailerId}/brakeAssessment/${this.subTypes.driverSide}`,
            });
        },
        enterPassengerSide() {
            this.$router.push({
                path: `/trailers/${this.trailerData.trailerId}/brakeAssessment/${this.subTypes.passengerSide}`,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@-webkit-keyframes LOADING-SELECTOR {
    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.1;
    }
}

@keyframes LOADING-SELECTOR {
    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.1;
    }
}

.fb-path {
    &.chassis,
    &.box,
    &.hinges,
    &.laches {
        fill: $uix-shade;
    }

    &.doors {
        fill: $uix-shade-white;
    }

    &.seal {
        fill: #eeeeed;
    }
}

.fb-svg {
    &.trailer-back {
        height: 351.389px;
        position: relative;
        width: 240px;
        z-index: 0;
    }
}

.fb-btn-selector {
    align-content: center;
    align-items: center;
    border-style: dashed;
    border-width: 3px;
    display: flex;
    gap: 0;
    justify-content: center;
    margin: 0;
    position: absolute;
    z-index: 1;

    &.top-left,
    &.top-center,
    &.top-right {
        height: 68px;
        width: 68px;
        top: -15px;
    }

    &.top-left {
        left: -15px;
    }

    &.top-center {
        left: 50%;
        margin: 0 0 0 -34px;
    }

    &.top-right {
        right: -15px;
    }

    &.bottom-left,
    &.bottom-right {
        bottom: -15px;
        height: 112px;
        width: 91px;
    }

    &.bottom-left {
        left: -15px;
    }

    &.bottom-center {
        bottom: 16px;
        height: 68px;
        left: 50%;
        margin: 0 0 0 -34px;
        width: 68px;
    }

    &.bottom-right {
        right: -15px;
    }
}

.fb-trailer-side-selector {
    height: 351.389px;
    margin: 45px auto 65px;
    position: relative;
    width: 240px;

    .fb-btn-selector {
        > svg {
            display: none;
            font-size: 24px;
        }

        &:active,
        &:focus,
        &.focus {
            background-color: rgba($uix-shade-white, 0.66);
            border: 3px solid $uix-color-error;
            -webkit-box-shadow: 0 0 0 5px rgba($uix-color-error, 0.25) !important;
            box-shadow: 0 0 0 5px rgba($uix-color-error, 0.25) !important;
            color: $uix-color-error;
        }

        &:hover {
            background-color: rgba($uix-shade-white, 0.66);
            border: 3px solid $uix-color-error;
            color: $uix-color-error;
        }

        &.active {
            background-color: rgba($uix-shade-white, 0.66) !important;
            border: 3px solid $uix-color-error !important;
            color: $uix-color-error !important;

            > svg {
                display: block;
            }

            &:focus,
            &.focus {
                -webkit-box-shadow: 0 0 0 5px rgba($uix-color-error, 0.25);
                box-shadow: 0 0 0 5px rgba($uix-color-error, 0.25);
            }
        }
    }

    &.active-bad {
        .fb-container {
            border: 2px solid $uix-color-error;
            padding: 4px;

            .fb-card-label {
                color: $uix-color-error;

                .fb-path {
                    fill: $uix-color-error !important;
                }
            }

            .fb-btn-good {
                &::after {
                    background-color: rgba($uix-color-error, 0.5);
                }
            }

            .fb-btn-bad {
                color: $uix-color-error !important;
            }

            .fb-card-buttons {
                border-top: 1px solid rgba($uix-color-error, 0.5);
            }
        }
    }

    &.loading {
        .fb-btn-selector {
            &,
            &:focus,
            &:hover,
            &.active,
            &.disabled {
                background-color: darken($uix-shade, 10%) !important;
                border: 3px solid darken($uix-shade, 10%) !important;
                box-shadow: none !important;
                cursor: default !important;

                > svg {
                    display: none !important;
                }
            }

            &.top-left {
                -webkit-animation: LOADING-SELECTOR 1.75s infinite;
                animation: LOADING-SELECTOR 1.75s infinite;
            }

            &.top-center {
                -webkit-animation: LOADING-SELECTOR 2s infinite;
                animation: LOADING-SELECTOR 2s infinite;
            }

            &.top-right {
                -webkit-animation: LOADING-SELECTOR 1.5s infinite;
                animation: LOADING-SELECTOR 1.5s infinite;
            }

            &.bottom-left {
                -webkit-animation: LOADING-SELECTOR 2.75s infinite;
                animation: LOADING-SELECTOR 2.75s infinite;
            }

            &.bottom-center {
                -webkit-animation: LOADING-SELECTOR 2.5s infinite;
                animation: LOADING-SELECTOR 2.5s infinite;
            }

            &.bottom-right {
                -webkit-animation: LOADING-SELECTOR 2.25s infinite;
                animation: LOADING-SELECTOR 2.25s infinite;
            }
        }
    }
}

.fb-form-paragraph {
    &.instructions {
        margin: 20px 0 50px;
        text-align: left;
    }
}

.fb-form-button {
    margin: 0;
}
</style>
