import apiErrorHandler from '../plugins/apiErrorHandler';
import axios from '@/plugins/axiosConfig';

export default async function (
    dotNumber,
    trailerId,
    trailerCompany,
    location,
    mobileNumber,
    qid
) {
    return await axios({
        method: 'post',
        url: `${process.env.VUE_APP_EXT_API_URL}api/trailers/trailerNotRegistered`,
        data: {
            dotNumber: dotNumber,
            trailerId: trailerId,
            trailerCompany: trailerCompany,
            location: location,
            mobileNumber: mobileNumber,
            qrId: qid,
        },
    })
        .then((r) => r.data)
        .catch((error) => {
            return apiErrorHandler(error);
        });
}
