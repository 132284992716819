<template>
    <div
        class="customer-info"
        :class="{
            'without-background': hideBackground,
            'not-found': !hasTrailer,
        }"
    >
        <h2 class="customer-name">{{ customerName }}</h2>
        <a class="customer-link" :href="geoLink" target="_blank">
            {{ trailerAddressLine1 }}
            {{ trailerAddressLine2 }}
        </a>
        <em class="customer-last-update" v-html="subheaderText" />
    </div>
</template>

<script>
export default {
    name: 'LocationGroup',
    props: {
        hideBackground: {
            type: Boolean,
            default: false,
        },
        hasTrailer: {
            type: Boolean,
            default: true,
        },
        customerName: {
            type: String,
            default: '',
        },
        geoLink: {
            type: String,
            default: '',
        },
        trailerAddressLine1: {
            type: String,
            default: '',
        },
        trailerAddressLine2: {
            type: String,
            default: '',
        },
        subheaderText: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" scoped>
.customer-info {
    background: $uix-shade-alt-four;
    border-radius: 9px;
    margin: 15px 0 30px 0;
    padding: 10px 10px 20px 10px;
    width: 100%;

    .customer-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.without-background {
        background: transparent;
        margin-top: 10px;
        padding-bottom: 0;
    }

    h2 {
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.563rem;
        margin-bottom: 5px;
        text-transform: uppercase;
    }

    a {
        color: $uix-paragraph-secondary-color;
        font-family: 'Inter', sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.063rem;
        margin-bottom: 6px;
        text-decoration: underline;
        text-transform: uppercase;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    em {
        display: block;
        font-family: 'Inter', sans-serif;
        font-size: 0.813rem;
        font-weight: 400;
        line-height: 1rem;
    }

    &.not-found {
        padding-top: 12px;
        padding-bottom: 12px;

        h2,
        a {
            display: none;
        }
    }
}
</style>
