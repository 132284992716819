import { createApp } from 'vue';
import { i18n } from './localization';
import { ApmVuePlugin } from './libraries/apm';
import App from './App.vue';
import router from './router';
import store from './store';
import globalMethods from './plugins/globalMethods';
import globalConstants from './plugins/globalConstants';
import localforage from 'localforage';
import './registerServiceWorker';

import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faCompress } from '@fortawesome/free-solid-svg-icons';
import { faDownLeftAndUpRightToCenter } from '@fortawesome/free-solid-svg-icons';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { faFileContract } from '@fortawesome/free-solid-svg-icons';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import LoadScript from 'vue-plugin-load-script';

library.add(faCamera);
library.add(faCircleCheck);
library.add(faCircleInfo);
library.add(faCircleExclamation);
library.add(faInfo);
library.add(faCircleQuestion);
library.add(faCircleXmark);
library.add(faCompress);
library.add(faDownLeftAndUpRightToCenter);
library.add(faExpand);
library.add(faFileContract);
library.add(faMagnifyingGlass);
library.add(faQrcode);
library.add(faPhone);
library.add(faRotateLeft);
library.add(faShare);
library.add(faThumbsDown);
library.add(faThumbsUp);
library.add(faTrashCan);
library.add(faUpRightAndDownLeftFromCenter);
library.add(faUser);
library.add(faXmark);
library.add(faShieldHalved);
library.add(faLightbulb);

localforage.config({
    driver: localforage.INDEXEDDB,
    name: 'fba-DB',
});

export default createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(store)
    .use(router)
    .use(ApmVuePlugin)
    .use(i18n)
    .use(globalMethods)
    .use(globalConstants)
    .use(LoadScript)
    .mount('#app');
