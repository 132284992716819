<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_3802_138163)">
            <path
                d="M4.94877 0H12.0184C13.1938 0 14.1393 0.945569 14.1393 2.1209C14.1393 3.29623 13.1938 4.2418 12.0184 4.2418H4.94877C4.55994 4.2418 4.2418 4.55994 4.2418 4.94877V12.0184C4.2418 13.1938 3.29623 14.1393 2.1209 14.1393C0.945569 14.1393 0 13.1938 0 12.0184V4.94877C0 2.21811 2.21811 0 4.94877 0ZM33.2275 0H40.2971C43.0278 0 45.2459 2.21811 45.2459 4.94877V12.0184C45.2459 13.1938 44.3003 14.1393 43.125 14.1393C41.9497 14.1393 41.0041 13.1938 41.0041 12.0184V4.94877C41.0041 4.55994 40.686 4.2418 40.2971 4.2418H33.2275C32.0521 4.2418 31.1066 3.29623 31.1066 2.1209C31.1066 0.945569 32.0521 0 33.2275 0ZM4.2418 33.2275V40.2971C4.2418 40.686 4.55994 41.0041 4.94877 41.0041H12.0184C13.1938 41.0041 14.1393 41.9497 14.1393 43.125C14.1393 44.3003 13.1938 45.2459 12.0184 45.2459H4.94877C2.21811 45.2459 0 43.0278 0 40.2971V33.2275C0 32.0521 0.945569 31.1066 2.1209 31.1066C3.29623 31.1066 4.2418 32.0521 4.2418 33.2275ZM45.2459 33.2275V40.2971C45.2459 43.0278 43.0278 45.2459 40.2971 45.2459H33.2275C32.0521 45.2459 31.1066 44.3003 31.1066 43.125C31.1066 41.9497 32.0521 41.0041 33.2275 41.0041H40.2971C40.686 41.0041 41.0041 40.686 41.0041 40.2971V33.2275C41.0041 32.0521 41.9497 31.1066 43.125 31.1066C44.3003 31.1066 45.2459 32.0521 45.2459 33.2275ZM15.9068 11.3115L16.4547 9.86219C16.764 9.03151 17.5593 8.48361 18.443 8.48361H26.8117C27.6954 8.48361 28.4908 9.03151 28.8001 9.86219L29.3391 11.3115H32.5205C34.8623 11.3115 36.7623 13.2114 36.7623 15.5533V29.6926C36.7623 32.0345 34.8623 33.9344 32.5205 33.9344H12.7254C10.3836 33.9344 8.48361 32.0345 8.48361 29.6926V15.5533C8.48361 13.2114 10.3836 11.3115 12.7254 11.3115H15.9068ZM28.2787 22.6229C28.2787 19.5035 25.7424 16.9672 22.6229 16.9672C19.5035 16.9672 16.9672 19.5035 16.9672 22.6229C16.9672 25.7424 19.5035 28.2787 22.6229 28.2787C25.7424 28.2787 28.2787 25.7424 28.2787 22.6229Z"
                fill="#615F5A"
            />
        </g>
        <defs>
            <clipPath id="clip0_3802_138163">
                <rect width="45.2459" height="45.2459" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    props: {
        height: Number,
        width: Number,
    },
};
</script>
