<template>
    <div>
        <div class="fb-container body">
            <div class="fb-form">
                <h1 class="fb-form-heading">
                    {{ $t('DamageScreens.title') }}
                </h1>
                <p class="fb-form-paragraph">
                    {{ $t('DamageScreens.bodyDamageSubtitle') }}
                </p>

                <!-- TODO: Set class to 'fb-body-damage-selector' for driver app, OR 'fb-trailer-side-selector' for admin portal -->
                <!-- TODO: Add 'loading' class when you want to show progress indicator buttons -->
                <div class="fb-trailer-side-selector fb-body-damage-selector">
                    <svg
                        version="1.1"
                        class="fb-svg truck-trailer-arial"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 141 535.5"
                        style="enable-background: new 0 0 141 535.5"
                        xml:space="preserve"
                    >
                        <path
                            class="fb-path truck-arial"
                            d="M140.5,58.9c0,0.7-0.5,1.2-1.2,1.2h-8.1c-0.5,0-1-0.3-1.2-0.8c-1.2,0.4-2.8,0.9-4.5,1.4 c-1.3,1.9-3.2,3.5-5.4,4.3v38l6.2,4.4v11.2c-1.3-2.4-3-4.6-4.8-6.6l-3.2-3.5c-1-2.1-9.9-18.3-29.2-23.8c-12.1-3.3-24.8-3.3-36.9,0 c-18.2,4.9-28.3,21.8-29.2,23.8l-3.2,3.5c-1.9,2-3.5,4.2-4.9,6.6v-11.3L21,103V65c-2.2-0.9-4.2-2.4-5.6-4.3c-1.7-0.5-3.3-1-4.5-1.4 c-0.2,0.5-0.6,0.8-1.2,0.8H1.7c-0.7,0-1.2-0.6-1.2-1.2c0,0,0,0,0,0v-2c0-1.3,1-2.3,2.3-2.3h0h5.9c1.1,0.1,2,0.9,2.4,1.9l2.6,0.6 c-0.4-1.3-0.7-2.6-0.7-3.9L13,34.5c0.5-11.8-2.9-31.2,32.8-33.7L28.7,52.1c-0.2,0.4-0.4,0.8-0.5,1.3c-1,2.5-2.4,7.1-2.7,8.1 c0,0.9,0.7,1,1.5,0.5c3.2-2.2,6.8-3.8,10.6-4.8c9.7-2.6,22.3-2.6,31.7-2.6c10.1,0,23.8-0.1,34.2,2.6c3.8,0.9,7.4,2.5,10.6,4.8 c0.8,0.5,1.5,0.5,1.5-0.5c-0.3-1-1.8-5.7-2.7-8.1c-0.2-0.4-0.3-0.9-0.5-1.3L95.2,0.8c35.7,2.5,32.3,21.9,32.8,33.7l0.1,18.7 c0,1.3-0.2,2.7-0.7,3.9l2.6-0.6c0.3-1.1,1.3-1.8,2.4-1.9h5.9c1.3,0,2.3,1,2.3,2.3l0,0L140.5,58.9z M123.8,20.8 c0.2,0.3,0.5,0.4,0.8,0.2c0.6-0.4,0.7-1.9,0.3-2.8c-1.4-4.2-4.3-7.8-8-10.2c-6.4-4-14.5-4.2-18.6-4.4C110.3,8,116.5,13.4,123.8,20.8 L123.8,20.8z M16.2,21.1c0.3,0.2,0.6,0,0.8-0.2C24.3,13.4,30.6,8,42.6,3.6C38.5,3.8,30.3,4.1,23.9,8c-3.8,2.4-6.6,6-8,10.2 c-0.1,0.4-0.2,0.8-0.2,1.2C15.6,20.1,15.8,20.7,16.2,21.1L16.2,21.1z M129.4,161.9h-19.5l-2.3-10.6c-0.4-1.9-2.1-3.3-4.1-3.3H37.6 c-2,0-3.7,1.4-4.1,3.3l-2.3,10.6H11.8v-26c0-4.9,1-9.8,3.1-14.3c0-3.3,8.2-11.7,8.9-12.5C31.6,96,43.8,88.1,52.3,85.8 c14.3-2.9,23.1-2.9,36.5,0c8,2.2,21.8,10.6,28.6,23.4c1.5,1.6,8.9,9.2,8.9,12.4c2,4.5,3.1,9.4,3.1,14.4L129.4,161.9z M126.2,192.1 h-27v-41.7h4.1c0.8,0,1.6,0.6,1.8,1.4l2.7,12.5h18.4L126.2,192.1z M107.9,49.6c-2.3-0.8-5.1-1.3-10.9-1.7 c-8.2-0.5-16.8-0.7-26.5-0.7c-9.7-0.1-18.3,0.1-26.5,0.7c-5.9,0.4-8.7,0.9-10.9,1.7c-0.7,0.2-1.3,0.5-1.9,0.9L47.3,0.7 c2.2-0.1,4.3-0.2,6.9-0.2h32.7c2.5,0,4.6,0.1,6.9,0.2l16.1,49.8C109.2,50.1,108.6,49.8,107.9,49.6L107.9,49.6z M41.8,192.1h-27 v-27.8h18.5l2.7-12.5c0.2-0.8,0.9-1.4,1.8-1.4h4.1L41.8,192.1z"
                        />
                        <path
                            class="fb-path trailer-arial"
                            d="M11.8,171.8v361.7h117.6V171.8H11.8z"
                        />
                    </svg>
                    <button
                        type="button"
                        class="btn btn-outline-dark input-radius fb-btn-selector front"
                        :class="frontButton"
                        @click="frontTapped"
                    >
                        <font-awesome-icon :icon="['fas', 'thumbs-down']" />
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-dark input-radius fb-btn-selector driver"
                        :class="driverButton"
                        @click="driverTapped"
                    >
                        <font-awesome-icon :icon="['fas', 'thumbs-down']" />
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-dark input-radius fb-btn-selector passenger"
                        :class="passengerButton"
                        @click="passengerTapped"
                    >
                        <font-awesome-icon :icon="['fas', 'thumbs-down']" />
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-dark input-radius fb-btn-selector back"
                        :class="rearButton"
                        @click="rearTapped"
                    >
                        <font-awesome-icon :icon="['fas', 'thumbs-down']" />
                    </button>
                </div>
                <button
                    type="button"
                    class="btn btn-primary fb-form-button mb-5"
                    @click="$router.push({ name: 'TrailerAssessment' })"
                >
                    <span>{{ $t('DamageScreens.doneButton') }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {
    assessmentHasImagesOrComments,
    isNullOrUndefined,
} from '@/shared/helpers';
import globalConstants from '../../plugins/globalConstants';
import { mapGetters } from 'vuex';

export default {
    name: 'BodyDamage',
    data: () => ({
        type: globalConstants.trailer.assessmentTypes.damage,
        subTypes: {
            driverSide:
                globalConstants.trailer.assessmentTypes.subTypes.driverSide,
            passengerSide:
                globalConstants.trailer.assessmentTypes.subTypes.passengerSide,
            frontSide:
                globalConstants.trailer.assessmentTypes.subTypes.frontSide,
            rearSide: globalConstants.trailer.assessmentTypes.subTypes.rearSide,
        },
        typeAssessment: null,
        trailerData: null,
    }),
    computed: {
        ...mapGetters(['trailerAssessment']),
        driverButton() {
            return assessmentHasImagesOrComments(
                this.typeAssessment,
                this.subTypes.driverSide
            )
                ? 'active'
                : '';
        },
        passengerButton() {
            return assessmentHasImagesOrComments(
                this.typeAssessment,
                this.subTypes.passengerSide
            )
                ? 'active'
                : '';
        },
        frontButton() {
            return assessmentHasImagesOrComments(
                this.typeAssessment,
                this.subTypes.frontSide
            )
                ? 'active'
                : '';
        },
        rearButton() {
            return assessmentHasImagesOrComments(
                this.typeAssessment,
                this.subTypes.rearSide
            )
                ? 'active'
                : '';
        },
    },
    async created() {
        this.setLoading(true);
        if (isNullOrUndefined(this.trailerAssessment))
            await this.$store.dispatch('getTrailerAssessmentFromIndexedDB');

        this.trailerData = this.$store.getters.getCurrentTrailerInfo;
        this.typeAssessment = this.trailerAssessment?.[this.type];
        this.setLoading(false);
    },
    methods: {
        frontTapped() {
            this.$router.push({
                path: `/trailers/${this.trailerData.trailerId}/damageAssessment/${this.subTypes.frontSide}`,
            });
        },
        rearTapped() {
            this.$router.push({
                path: `/trailers/${this.trailerData.trailerId}/damageAssessment/${this.subTypes.rearSide}`,
            });
        },
        driverTapped() {
            this.$router.push({
                path: `/trailers/${this.trailerData.trailerId}/damageAssessment/${this.subTypes.driverSide}`,
            });
        },
        passengerTapped() {
            this.$router.push({
                path: `/trailers/${this.trailerData.trailerId}/damageAssessment/${this.subTypes.passengerSide}`,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@-webkit-keyframes LOADING-SELECTOR {
    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.1;
    }
}

@keyframes LOADING-SELECTOR {
    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.1;
    }
}

.fb-svg {
    &.truck-trailer-arial {
        height: 531.7px;
        position: relative;
        width: 140px;
        z-index: 0;
    }
}

.fb-path {
    &.truck-arial {
        fill: #c7c6c4;
    }

    &.trailer-arial {
        fill: #ffffff;
        stroke: #c7c6c4;
        stroke-width: 4;
    }
}

.fb-btn-selector {
    align-content: center;
    align-items: center;
    border-style: dashed;
    border-width: 3px;
    display: flex;
    gap: 0;
    justify-content: center;
    position: absolute;
    z-index: 1;

    &.front,
    &.back {
        height: 68px;
        left: -15px;
        right: -15px;
    }

    &.front {
        top: 132px;
    }

    &.back {
        bottom: -34px;
    }

    &.passenger,
    &.driver {
        bottom: 44px;
        top: 210px;
        width: 68px;
    }

    &.passenger {
        right: -34px;
    }

    &.driver {
        left: -34px;
    }
}

.fb-body-damage-selector,
.fb-trailer-side-selector {
    height: 531.7px;
    margin: 30px auto 70px;
    position: relative;
    width: 140px;

    .fb-btn-selector {
        > svg,
        > span {
            display: none;
        }
    }
}

.fb-body-damage-selector {
    .fb-btn-selector {
        > svg {
            font-size: 24px;
        }

        &:active,
        &:focus,
        &.focus {
            background-color: rgba($uix-shade-white, 0.66);
            border: 3px solid $uix-color-error;
            -webkit-box-shadow: 0 0 0 5px rgba($uix-color-error, 0.25) !important;
            box-shadow: 0 0 0 5px rgba($uix-color-error, 0.25) !important;
            color: $uix-color-error;
        }

        &:hover {
            background-color: rgba($uix-shade-white, 0.66);
            border: 3px solid $uix-color-error;
            color: $uix-color-error;
        }

        &.active {
            background-color: rgba($uix-shade-white, 0.66) !important;
            border: 3px solid $uix-color-error !important;
            color: $uix-color-error !important;

            > svg {
                display: block;
            }

            &:focus,
            &.focus {
                -webkit-box-shadow: 0 0 0 5px rgba($uix-color-error, 0.25);
                box-shadow: 0 0 0 5px rgba($uix-color-error, 0.25);
            }
        }
    }
}

.fb-trailer-side-selector {
    .fb-btn-selector {
        &.passenger,
        &.driver {
            > span {
                transform: rotate(-90deg);
                -webkit-transform: rotate(-90deg);
                -moz-transform: rotate(-90deg);
                -ms-transform: rotate(-90deg);
                -o-transform: rotate(-90deg);
                filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
            }
        }

        &:active,
        &:focus,
        &.focus {
            background-color: rgba($uix-shade-white, 0.66);
            border: 3px solid darken($uix-color-primary, 10%);
            -webkit-box-shadow: 0 0 0 5px
                rgba(darken($uix-color-primary, 10%), 0.25) !important;
            box-shadow: 0 0 0 5px rgba(darken($uix-color-primary, 10%), 0.25) !important;
            color: darken($uix-color-primary, 10%);
        }

        &:hover {
            background-color: rgba($uix-shade-white, 0.66);
            border: 3px solid darken($uix-color-error, 10%);
            color: darken($uix-color-error, 10%);
        }

        &.active {
            background-color: rgba($uix-shade-white, 0.66) !important;
            border: 3px solid $uix-color-error, 10% !important;
            color: $uix-color-error, 10% !important;

            > span {
                display: block;
            }

            &:focus,
            &.focus {
                -webkit-box-shadow: 0 0 0 5px rgba($uix-color-primary, 0.25);
                box-shadow: 0 0 0 5px rgba($uix-color-primary, 0.25);
            }
        }
    }
}

.fb-body-damage-selector,
.fb-trailer-side-selector {
    &.loading {
        .fb-btn-selector {
            &,
            &:focus,
            &:hover,
            &.active,
            &.disabled {
                background-color: darken($uix-shade, 10%) !important;
                border: 3px solid darken($uix-shade, 10%) !important;
                box-shadow: none !important;
                cursor: default !important;

                > svg,
                > span {
                    display: none !important;
                }
            }

            &.front {
                -webkit-animation: LOADING-SELECTOR 1.75s infinite;
                animation: LOADING-SELECTOR 1.75s infinite;
            }

            &.driver {
                -webkit-animation: LOADING-SELECTOR 2.25s infinite;
                animation: LOADING-SELECTOR 2.25s infinite;
            }

            &.passenger {
                -webkit-animation: LOADING-SELECTOR 1.5s infinite;
                animation: LOADING-SELECTOR 1.5s infinite;
            }

            &.back {
                -webkit-animation: LOADING-SELECTOR 2s infinite;
                animation: LOADING-SELECTOR 2s infinite;
            }
        }
    }
}

.fb-form-button {
    margin: 0;
}

.fb-form-paragraph {
    &.instructions {
        margin: 20px 0 50px;
        text-align: left;
    }
}
</style>
