<template>
    <p class="fb-footer-copyright" data-test="copyright-text">
        {{ $t('Copyright') }}
    </p>
</template>

<script>
export default {
    name: 'Copyright',
};
</script>

<style lang="scss" scoped>
.fb-footer-copyright {
    color: $uix-paragraph-text-color;
    font-size: 0.8125rem; // 13px
    font-weight: 400;
}
</style>
