<template>
    <div>
        <div class="fb-container body">
            <div class="fb-form">
                <h1 class="fb-form-heading">
                    {{ $t('FindLoad.title') }}
                </h1>

                <div class="fb-input-container">
                    <fbx-input
                        ref="input"
                        v-model="loadNumber"
                        class="fb-control-text"
                        data-test="findload-load-search-number-input"
                        keyboard-type="numeric"
                        :label="'FindLoad.searchPlaceholder'"
                        :required="true"
                        @validation-error="
                            (value) => updateLoadNumberInvalid(value)
                        "
                    />

                    <div class="fb-control-divider" />

                    <fbx-input-inline-button
                        data-test="get-started-submit-button"
                        class="fb-control-button"
                        theme="primary"
                        uses-icon="true"
                        icon-type="fas"
                        icon-name="magnifying-glass"
                        @clicked="initiateSearch"
                    />
                </div>

                <div class="fb-form-alert show">
                    <div v-if="loadNotFound" class="fb-form-alert-container">
                        <svg
                            class="fb-svg binoculars"
                            style="enable-background: new 0 0 128 77.4"
                            version="1.1"
                            viewBox="0 0 128 77.4"
                            x="0px"
                            xmlns="http://www.w3.org/2000/svg"
                            xml:space="preserve"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            y="0px"
                        >
                            <path
                                class="fb-path binoculars st0"
                                d="M123.6,37L123.6,37L123.6,37c-1-1.5-2.1-2.8-3.4-4.1l-17.6-21.3c-0.1-0.2-0.3-0.3-0.4-0.5 l-0.3-0.3l0,0c-6.3-7-17-7.9-24.3-1.9l0,0C72.9,12.2,64,12.3,64,
                12.3s-8.9-0.1-13.6-3.4l0,0c-7.3-6-18-5.1-24.3,1.9l0,0l-0.3,0.3 c-0.1,0.2-0.3,0.3-0.4,0.5L7.7,32.9c-1.3,1.2-2.4,2.6-3.4,4.1l0,0l0,0c-8,11.9-4.7,28.1,7.2,36s28.1,
                4.7,36-7.2 c2.3-3.5,3.8-7.5,4.2-11.7c2.4,6.7,9.8,10.2,16.5,7.8c3.7-1.3,6.5-4.2,7.8-7.8c1.5,14.3,14.2,24.7,28.5,23.2s24.7-14.2,23.2-28.5 C127.4,44.6,126,40.5,123.6,
                37 M25.7,71.2c-11,0-20-8.9-20-20c0-11,8.9-20,20-20c11,0,20,8.9,20,20l0,0 C45.7,62.3,36.8,71.2,25.7,71.2L25.7,71.2 M64,57.3c-2.2,0-4.1-1.1-4.1-2.5s1.8-2.5,
                4.1-2.5c2.2,0,4.1,1.1,4.1,2.5 S66.2,57.3,64,57.3 M102.3,71.2c-11,0-20-8.9-20-20s8.9-20,20-20s20,8.9,20,20l0,0C122.2,62.3,113.3,71.2,102.3,71.2 M33.3,
                37.5 c-5.8-0.5-11.4,1.6-15.4,5.8c-4.2,4-6.3,9.7-5.8,15.4c-3.6-8.1,0-17.6,8.1-21.2C24.3,35.7,29.1,35.7,33.3,37.5 M109.8,37.5 c-11-0.7-20.5,7.6-21.3,18.6c-0.1,
                0.9-0.1,1.8,0,2.7c-3.6-8.1,0-17.6,8.1-21.3C100.8,35.7,105.6,35.7,109.8,37.5 M39.2,3 c-0.7,0-1.5,0-2.2,0.1C41.7-1.3,49.1-1,53.5,3.8c1.4,1.6,2.4,3.5,2.8,
                5.5c-1.7-0.4-3.4-1.1-4.9-2C48,4.5,43.6,3,39.2,3 M76.5,7.3 c-1.5,0.9-3.1,1.6-4.9,2C73,3,79.2-1.1,85.5,0.3c2.1,0.4,4,1.4,5.5,2.8C90.3,3,89.6,3,88.8,3C84.4,3,80,4.5,
                76.5,7.3"
                            />
                        </svg>

                        <h4 class="fb-form-sub-heading">
                            {{ $t('FindLoad.notFound.title') }}
                        </h4>
                        <p class="fb-form-paragraph">
                            {{ $t('FindLoad.notFound.subtitle') }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="fb-superfooter">
                <p class="fb-form-paragraph fb-paragraph-continue">
                    {{ $t('FindLoad.cantFindLoad') }}
                </p>

                <fbxButton
                    theme="linkEm"
                    type="button"
                    class=""
                    :button-text="$t('FindLoad.continueWithoutLoad')"
                    @click="continueWithoutLoad"
                />
            </div>
        </div>

        <ConfirmationOverlay
            v-if="$store.state.confirmationShown"
            :titleText="$t('FindLoad.confirmationTitle')"
            :subtitleText="$t('FindLoad.confirmationSubtitle')"
            :acceptText="$t('FindLoad.acceptText')"
            :rejectText="$t('FindLoad.rejectText')"
            acceptTheme="primary"
            rejectTheme="return"
            acceptAction="confirmUnknownLoad"
            acceptDestination="AttachTrailerWithoutLoadSuccess"
            :acceptActionArgs="loadNumber"
        />
    </div>
</template>

<script>
import FbaApiError from '../models/ApiError';
import fbxInput from '@/components/fbx-input.vue';
import fbxButton from '@/components/fbx-button.vue';
import fbxInputInlineButton from '@/components/fbx-input-inline-button.vue';
import globalConstants from '../plugins/globalConstants';
import ConfirmationOverlay from '../components/ConfirmationOverlay.vue';

export default {
    components: {
        fbxButton,
        fbxInput,
        fbxInputInlineButton,
        ConfirmationOverlay,
    },
    data: () => ({
        loadNumber: null,
        loadNumberInvalid: false,
        loadInfo: null,
    }),
    computed: {
        loadNotFound() {
            return (
                this.loadNumberInvalid &&
                this.loadNumber !== null &&
                this.loadNumber != undefined &&
                this.loadNumber !== ''
            );
        },
    },
    watch: {
        loadNumber() {
            this.loadNumber = this.loadNumber.replace(/[^0-9]/g, '');
            this.$refs.input.setValue(this.loadNumber);
        },
    },
    methods: {
        async initiateSearch() {
            if (
                this.loadNumber === null ||
                this.loadNumber === undefined ||
                this.loadNumber === ''
            ) {
                this.$refs.input.setAlert('validation');
                return;
            }

            this.loadNumberInvalid = false;

            this.setLoading(true);

            let result = await this.$store.dispatch(
                'loadStore/getLoad',
                this.loadNumber
            );
            if (
                result instanceof FbaApiError &&
                result.reasonCode === globalConstants.reasonCodes.loadNotFound
            ) {
                this.loadNumberInvalid = true;
                this.setLoading(false);
                return;
            }

            this.$store.commit('loadStore/saveCurrentLoadInfo', result);
            this.$router.push({ path: '/loadConfirmation' });
            this.setLoading(false);
        },
        updateLoadNumberInvalid(value) {
            this.loadNumberInvalid = value;
        },
        async continueWithoutLoad() {
            this.$store.dispatch('showConfirmation');
        },
    },
};
</script>

<style lang="scss" scoped>
.fb-input-container {
    display: flex;
}

.fb-control-text {
    flex-grow: 5;
}

.fb-superfooter {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 30px;
}

.fb-form-heading {
    margin-bottom: 30px;
}

.fb-svg {
    &.binoculars {
        height: 80px;
        width: 132.312px;
    }
}

.fb-form-alert-container {
    background-color: $uix-shade-100;
    -webkit-box-shadow: 0 1px 2px 0 $uix-shade;
    -moz-box-shadow: 0 1px 2px 0 $uix-shade;
    box-shadow: 0 1px 2px 0 $uix-shade;
    border-radius: 6px;
    display: inline-block;
    margin: 0 auto;
    padding: 30px 20px 20px;
    position: relative;
    width: 100%;

    .fb-form-sub-heading,
    .fb-form-paragraph {
        color: $uix-color-error;
    }

    .fb-form-sub-heading {
        font-weight: 700;
        margin: 25px 0 5px;
    }
}

.fb-form-alert {
    display: none;
    margin: 0 0 60px;
    position: relative;
    width: 100%;
    top: 20px;

    &.success {
        .fb-form-alert-container {
            background-color: #e7ebdd;
            -webkit-box-shadow: 0 1px 2px 0 rgba(112, 154, 52, 0.66);
            -moz-box-shadow: 0 1px 2px 0 rgba(112, 154, 52, 0.66);
            box-shadow: 0 1px 2px 0 rgba(112, 154, 52, 0.66);
            margin-top: -35px;
            max-width: 398px;

            .fb-form-sub-heading,
            .fb-form-paragraph {
                color: $uix-color-success;
            }

            .fb-form-sub-heading {
                font-weight: 400;
            }
        }
    }

    &.show {
        display: inline-block;
    }
}

.fb-path {
    &.binoculars {
        fill: $uix-heading-text-color;
    }
}

.fb-paragraph-continue {
    margin-bottom: 0;
}

.fb-btn-continue {
    min-height: 0;
    padding: 1px 10px;
}
</style>
