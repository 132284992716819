<template>
    <div class="topbar-container d-flex flex-row justify-content-center">
        <div
            class="notification-row d-flex flex-row justify-content-between m-3"
        >
            <div class="d-flex flex-row align-items-center">
                <font-awesome-icon :icon="['fas', iconType]" />
                <div class="notification">
                    {{ alertMessage }}
                </div>
            </div>
            <div class="more-info">
                <!-- [ TODO: ingee - 7/19/2023 ] enable more feature with AFBAPP-60 -->
                <!-- more -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopBarNotification',
    components: {},
    props: {
        alertMessage: {
            type: String,
            default: '',
        },
        iconType: {
            type: String,
            default: 'circle-exclamation',
        },
    },
};
</script>

<style lang="scss" scoped>
.topbar-container {
    z-index: 100;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    background-color: #524f4a;
    color: #fff;

    .notification-row {
        max-width: $uix-app-max-width;
        width: 100%;
    }

    svg {
        height: 1.5rem;
        width: 1.5rem;
    }

    .notification {
        font-size: 0.875rem;
        margin-left: 1rem;
        text-align: left;
    }

    .more-info {
        font-size: 0.875rem;
        font-style: italic;
        margin: auto 1rem;
        color: #93c7ff;
        cursor: pointer;
    }
}
</style>
