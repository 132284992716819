<template>
    <div>
        <div class="fb-container body">
            <button type="button" class="btn btn-link fb-form-clear-button">
                <font-awesome-icon :icon="['fas', 'xmark']" />
            </button>
            <div class="fb-form-image terms">
                <div>
                    <font-awesome-icon :icon="['fas', 'file-contract']" />
                </div>
            </div>
            <div class="fb-form">
                <h1 class="fb-form-heading">
                    {{ $t('Terms.title') }}
                </h1>
                <p class="fb-form-paragraph">
                    {{ $t('Terms.text') }}
                </p>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.fb-form-image {
    &.terms {
        display: inline-block;
        margin: 40px auto 0;
        position: relative;

        > div {
            align-content: center;
            align-items: center;
            background-color: #4d6a99;
            border-radius: 48px;
            color: $uix-shade-white;
            display: flex;
            font-size: 3.5rem; // 56px
            gap: 0;
            justify-content: center;
            height: 96px;
            margin: 0;
            position: relative;
            width: 96px;
        }
    }
}

.fb-form-heading {
    margin: 10px 0 30px;
}

.fb-form-paragraph {
    margin: 0 0 60px;
    text-align: left;
}
</style>
