<template>
    <div>
        <div class="fb-container body">
            <div class="fb-form">
                <h2 id="signup-title">
                    {{ $t('Signup.title') }}
                </h2>
                <p id="signup-subtitle">
                    {{ $t('Signup.subTitle') }}
                </p>

                <div id="signup-form">
                    <fbx-input
                        id="first-name-input"
                        ref="firstNameInput"
                        v-model="firstName"
                        type="text"
                        label="Signup.firstNameInputPlaceholder"
                        :required="true"
                        :trim="true"
                    />
                    <fbx-input
                        id="last-name-input"
                        ref="lastNameInput"
                        v-model="lastName"
                        type="text"
                        label="Signup.lastNameInputPlaceholder"
                        :required="false"
                        :trim="true"
                    />
                    <fbx-input
                        id="truck-number-input"
                        ref="truckNumberInput"
                        v-model="truckNumber"
                        type="text"
                        label="Signup.truckNumberPlaceholder"
                        :required="true"
                        :trim="true"
                    />
                    <fbx-button
                        id="signup-submit-button"
                        theme="primary"
                        class="wide"
                        :button-text="$t('Signup.submitButton')"
                        @clicked="submitClicked"
                    />
                </div>
                <p data-test="signup-legal-text">
                    <i18n-t
                        class="fb-form-paragraph italicized"
                        keypath="Signup.uponContinuingText"
                        tag="p"
                    >
                        <strong>
                            {{ carrierNumber }}
                        </strong>
                    </i18n-t>
                    <i18n-t
                        class="fb-form-paragraph italicized"
                        keypath="Signup.yourNameText"
                        tag="p"
                    >
                        <strong>
                            {{ $t('Signup.noteText') }}
                        </strong>
                        <a
                            class="fb-underlined-link"
                            href="This link doesn't go anywhere yet"
                        >
                            {{ $t('Signup.howWillWeUseThisText') }}
                        </a>
                    </i18n-t>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import FbxButton from '../components/fbx-button.vue';
import FbxInput from '../components/fbx-input.vue';
import FbaApiError from '../models/ApiError';
import globalConstants from '../plugins/globalConstants';
import { isNullOrUndefined } from '@/shared/helpers';

export default {
    name: 'Signup',
    components: { FbxButton, FbxInput },
    data: function () {
        return {
            firstName: '',
            lastName: '',
            truckNumber: '',
            hasError: false,
            userDetails: this.$store.getters['getUserDetails'],
        };
    },
    computed: {
        carrierNumber() {
            return this.$store.state.userDetails.mcNumber === null
                ? this.$store.state.userDetails.dotNumber
                : this.$store.state.userDetails.mcNumber;
        },
    },
    created() {
        this.setLoading(false);
    },
    methods: {
        validateForm() {
            this.hasError = false;
            if (this.firstName.trim().length <= 0) {
                this.$refs.firstNameInput.setAlert('required');
                this.hasError = true;
            }
            if (this.truckNumber.trim().length <= 0) {
                this.$refs.truckNumberInput.setAlert('required');
                this.hasError = true;
            }
            if (this.truckNumber.length > 17) {
                this.$refs.truckNumberInput.setAlert('maxLength');
                this.hasError = true;
            }
            if (!this.userDetails.mcNumber && !this.userDetails.dotNumber) {
                this.hasError = true;
                this.$router.push({ name: 'GetStarted' });
            }
            return !this.hasError;
        },
        async submitClicked() {
            if (this.validateForm()) {
                this.setLoading(true);
                try {
                    let response = await this.$store.dispatch(
                        'driverRegister',
                        {
                            mcNumber: this.userDetails.mcNumber,
                            dotNumber: this.userDetails.dotNumber,
                            mobileNumber: this.userDetails.mobileNumber,
                            lastName: this.lastName,
                            firstName: this.firstName,
                            truckNumber: this.truckNumber,
                            mobileNumberValidated:
                                this.userDetails.mobileNumberValidated,
                        }
                    );
                    if (!isNullOrUndefined(response?.driverId)) {
                        this.setLoading(false);
                        this.$router.push({
                            name: 'TrailerScan',
                        });
                    } else if (
                        response instanceof FbaApiError &&
                        response?.statusCode !==
                            globalConstants.httpStatusCodes.timeout
                    ) {
                        this.$store.commit('clearUserDetails');
                        this.$router.push({ name: 'GetStarted' });
                        this.setLoading(false);
                    } else {
                        this.setLoading(false);
                    }
                } catch (e) {
                    console.error(e);
                    this.setLoading(false);
                }
            }
        },
    },
    watch: {
        truckNumber() {
            this.truckNumber = this.truckNumber.replace(/[^0-9a-zA-Z]/g, '');
            if (this.truckNumber.length > 17)
                this.truckNumber = this.truckNumber.substring(0, 17);
            this.$refs.truckNumberInput.setValue(this.truckNumber);
        },
    },
};
</script>

<style scoped>
.fb-form-paragraph {
    margin: 30px 40px 0px 40px;
    font-size: 0.75rem;
}

.italicized {
    font-style: italic;
    text-align: left;
}

.fb-underlined-link {
    text-decoration: underline;
}
</style>
