<template>
    <div class="popup-alert">
        <div class="left-bar" />
        <timer-icon class="icon" />
        <div class="right-content">
            <p class="popup-text">{{ $t(alertTextLocaleKey) }}</p>
        </div>
    </div>
</template>

<script>
import TimerIcon from './icons/timer-solid.vue';
import globalConstants from '../plugins/globalConstants';
export default {
    name: 'PopupAlert',
    components: { TimerIcon },
    props: {
        alertTextLocaleKey: {
            type: String,
            default: '',
        },
    },
    emits: [],
    setup: (props) => {},
    data: () => {
        return {};
    },

    watch: {},
    created: async function () {
        setTimeout(
            this.timeoutAlert,
            globalConstants.popupTimeoutInSeconds * 1000
        );
    },
    methods: {
        timeoutAlert() {
            this.$store.dispatch('clearAlert');
        },
    },
};
</script>

<style lang="scss" scoped>
.icon {
    max-width: 30px;
    background-color: $uix-paragraph-text-color;
    padding: 7px;
    height: 30px;
    vertical-align: top;
    flex: 1;
    margin: 3px;
    border-radius: 100px;
}

.left-bar {
    background-color: $uix-paragraph-text-color;
    max-width: 0.5rem;
    border-radius: 100px;
    min-height: 40px;
    height: auto;
    margin: 0 1rem;
    vertical-align: top;
    flex: 1;
}

.right-content {
    flex: 6;
    vertical-align: middle;
}

.popup-alert {
    color: $uix-heading-text-color;
    border: 1px solid $uix-heading-text-color;
    position: fixed;
    display: flex;
    background-color: $uix-control-background-color;
    bottom: 3rem;
    max-width: 500px;
    width: 90vw;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 1rem;
    z-index: 9;
    border-radius: $uix-control-border-radius;
}

.popup-text {
    padding: 1px 0;
    margin: auto 1rem;
    height: 100%;
    line-height: 30px;
    text-align: justify;
    text-align-last: left;
    font-weight: 600;
}
</style>
