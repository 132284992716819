import { apm } from './index';
import store from '@/store';
import { DateTime } from 'luxon';

const instrumentUnhandledApiResponse = function () {
    let transaction = apm.getCurrentTransaction();
    transaction?.addLabels({ unhandledApiResponse: true });
};

const instrumentApiTimeout = function () {
    let transaction = apm.getCurrentTransaction();
    transaction?.addLabels({ apiTimeout: true });
};

const instrumentLaunchFbaFromPinpoint = function (source) {
    let transaction = startTransaction('Launch FBA');

    transaction?.addLabels({ source: source });

    endTransaction(transaction);
};

const instrumentTrailerNotFound = function (trailerIdentifier, identifierType) {
    let transaction = startTransaction('Trailer Not Found');

    transaction?.addLabels({ trailerIdentifier: trailerIdentifier });
    transaction?.addLabels({ identifierType: identifierType });

    endTransaction(transaction);
};

const instrumentFailedAssociabilityRule = function (
    event,
    rules,
    trailerIdentifier,
    identifierType
) {
    let transaction = startTransaction('Associability Rule Limit');

    transaction?.addLabels({ event: event });
    transaction?.addLabels({ rules: rules.join(' ') });
    transaction?.addLabels({ trailerIdentifier: trailerIdentifier });
    transaction?.addLabels({ identifierType: identifierType });

    endTransaction(transaction);
};

const instrumentTrailerAssociation = function (
    trailerIdentifier,
    identifierType,
    association
) {
    const transactionName =
        association === 'attach' ? 'Trailer Attached' : 'Trailer Detached';
    let transaction = startTransaction(transactionName);

    transaction?.addLabels({ trailerIdentifier: trailerIdentifier });
    transaction?.addLabels({ identifierType: identifierType });

    endTransaction(transaction);
};

const instrumentLocationUnavailable = function (geolocationError) {
    let transaction = startTransaction('Location Unavailable');

    transaction?.addLabels({ errorMessage: geolocationError });

    endTransaction(transaction);
};

const instrumentInvalidCarrier = function (invalidMessage) {
    let transaction = startTransaction('Invalid Carrier');

    transaction?.addLabels({ errorMessage: invalidMessage });

    endTransaction(transaction);
};

const instrumentPwaInstalled = function () {
    let transaction = startTransaction('PWA Installed');
    endTransaction(transaction);
};

const startTransaction = function (transactionName) {
    let transaction = apm.startTransaction(transactionName, 'fba-custom');
    transaction?.startSpan(transactionName);

    return transaction;
};

const endTransaction = function (transaction) {
    transaction?.end(DateTime.now().toUTC().toMillis());
};

const setUserDetails = function () {
    if (store.getters.getUserDetails) {
        const {
            driverId,
            dotNumber,
            mcNumber,
            firstName,
            lastName,
            mobileNumber,
        } = store.getters.getUserDetails;

        const driverName = firstName + ' ' + lastName;
        apm.setUserContext({
            id: driverId,
            username: driverName,
        });

        // Checks for the standalone display-mode that is set(via manifest) when launched in the PWA view
        const isStandalone = window.matchMedia(
            '(display-mode: standalone)'
        ).matches;
        apm.setCustomContext({
            userDotNumber: dotNumber,
            userMcNumber: mcNumber,
            userName: driverName,
            userMobileNumber: mobileNumber,
            standaloneDisplay: isStandalone,
        });
    }
};

export default {
    instrumentFailedAssociabilityRule,
    instrumentInvalidCarrier,
    instrumentLocationUnavailable,
    instrumentLaunchFbaFromPinpoint,
    instrumentPwaInstalled,
    instrumentTrailerAssociation,
    instrumentTrailerNotFound,
    instrumentUnhandledApiResponse,
    instrumentApiTimeout,
    setUserDetails,
};
