<template>
    <div :class="{ 'fb-form-images': true, fullscreen: fullscreen }">
        <div class="fb-form-thumbnails">
            <div
                v-for="(image, index) in images"
                :key="index"
                @click="imageClicked(image)"
                :class="{
                    'btn uix-btn-outline-default fb-form-thumbnail': true,
                    active: image.isActive,
                }"
            >
                {{ index + 1 }}
            </div>
        </div>

        <fbx-button
            v-if="previewImage"
            :icon="['fas', 'trash-can']"
            v-on:click="onRemoveImage"
            theme="defaultOutline"
            class="fb-btn-remove"
        />
        <fbx-button
            v-if="showCaptureButton"
            buttonText="Capture"
            :icon="['fas', 'camera']"
            v-on:click="onTakePicture"
            theme="defaultOutline"
            class="fb-btn-capture"
        />

        <button
            class="btn uix-btn-outline-default fb-btn-fullscreen-toggle"
            @click="onToggleFullscreen"
        >
            <font-awesome-icon
                :icon="[
                    'fas',
                    fullscreen
                        ? 'down-left-and-up-right-to-center'
                        : 'up-right-and-down-left-from-center',
                ]"
            />
        </button>
        <img v-if="previewImage" :src="previewImage" class="fb-form-image" />
        <input
            ref="imageInput"
            type="file"
            accept="image/*"
            multiple
            v-show="false"
            v-on:change="onImageUploaded"
            capture
        />
    </div>
</template>
<script>
import FbxButton from '@/components/fbx-button.vue';
import { isNullOrUndefined } from '@/shared/helpers';
import { clone, findIndex, remove } from 'lodash';
export default {
    name: 'PhotoSelection',
    emits: ['imagesChanged'],
    components: {
        'fbx-button': FbxButton,
    },
    data: function () {
        return {
            images: [],
            previewImage: null,
            fullscreen: false,
        };
    },
    props: {
        existingImages: {
            type: Array,
            default: () => [],
        },
        numberOfImagesAllowed: {
            type: Number,
            default: 3,
        },
        title: {
            type: String,
            default: '',
        },
    },
    created() {
        this.initializeImages();
    },
    watch: {
        existingImages: function () {
            this.initializeImages();
        },
    },
    computed: {
        showCaptureButton: function () {
            return this.images.length < this.numberOfImagesAllowed;
        },
    },
    methods: {
        activateImage(image) {
            this.images.forEach((currentImage) => {
                currentImage.isActive = false;
            });

            image.isActive = true;
        },
        emitImagesChanged: function () {
            this.$emit('imagesChanged', this.images);
        },
        imageClicked: function (image) {
            if (isNullOrUndefined(image)) {
                return;
            }

            this.previewImage = image.src;

            this.activateImage(image);
        },
        initializeImages: function () {
            if (
                !isNullOrUndefined(this.existingImages) &&
                this.existingImages.length > 0
            ) {
                this.images = this.existingImages;
                this.previewImage = this.existingImages[0].src;
                this.activateImage(this.images[0]);
            }
        },
        onImageUploaded: async function (evt) {
            const file = evt.target.files[0];

            let newImgDataUrl = await this.getDataUrlFromFile(file);

            if (isNullOrUndefined(file)) {
                return;
            }

            this.images.forEach((currentImage) => {
                currentImage.isActive = false;
            });

            this.images.push({
                src: newImgDataUrl,
                fileName: file.name,
                isActive: true,
            });

            this.previewImage = newImgDataUrl;

            this.emitImagesChanged();
        },
        getDataUrlFromFile: async function (file) {
            let res = new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = async (e) => {
                    resolve(e.target.result);
                };

                reader.onerror = (e) => {
                    reject(
                        console.warn(
                            'Error converting uploaded photo to dataURL',
                            e
                        )
                    );
                };

                reader.readAsDataURL(file);
            });

            return await Promise.resolve(res);
        },
        onRemoveImage: function () {
            const indexOfPreview = findIndex(this.images, {
                src: this.previewImage,
            });
            const previewImageSrc = clone(this.previewImage);
            if (indexOfPreview === -1) {
                return;
            } else if (this.images.length > 1) {
                let newImageIndex = 0;

                if (indexOfPreview === this.images.length - 1) {
                    newImageIndex = this.images.length - 2;
                } else {
                    newImageIndex = this.images.length - 1;
                }

                this.previewImage = this.images[newImageIndex]?.src;

                this.activateImage(this.images[newImageIndex]);
            } else {
                this.previewImage = null;
            }
            remove(this.images, { src: previewImageSrc });

            this.emitImagesChanged();
        },
        onTakePicture: function (evt) {
            this.$refs.imageInput.click();
        },
        onToggleFullscreen: function (evt) {
            this.fullscreen = !this.fullscreen;
        },
    },
};
</script>
<style lang="scss" scoped>
.fb-form-image {
    margin: 0;
    max-width: 100%;
    min-width: 100%;
    position: relative;
    width: 100%;
    z-index: 0;
}
.fb-form-thumbnail,
.fb-btn-remove,
.fb-btn-capture,
.fb-btn-fullscreen-toggle {
    &,
    &.btn {
        align-content: center;
        align-items: center;
        border: 0 solid transparent;
        -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
        color: $uix-shade-black;
        display: flex;
        flex: none;
        font-size: 1.25rem; // 20px
        gap: 0;
        justify-content: center;
        margin: 0;
        overflow: hidden;
        padding: 0;
        z-index: 1;
        &:focus,
        &.focus {
            background-color: $uix-shade-white;
            border-color: transparent;
            -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
            -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
            color: $uix-shade-black;
        }
        &:hover {
            background-color: $uix-shade-white;
            border-color: transparent;
            color: $uix-shade-black;
        }

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        .show > &.dropdown-toggle {
            background-color: $uix-shade-white;
            border-color: transparent;
            color: $uix-shade-black;
        }

        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus,
        .show > &.dropdown-toggle:focus {
            -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
            -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
        }
    }
}
.fb-btn-remove {
    &,
    &.btn {
        background-color: $uix-shade;
        border-radius: 24px;
        height: 48px;
        position: absolute;
        right: 15px;
        top: 15px;
        width: 48px;
    }
}
.fb-btn-capture {
    &,
    &.btn {
        background-color: $uix-shade-400;
        border-radius: 32px;
        bottom: 20px;
        height: 64px;
        position: absolute;
        width: 64px;
    }
}
.fb-btn-fullscreen-toggle {
    &,
    &.btn {
        background-color: $uix-shade-400;
        border-radius: 24px;
        bottom: 28px;
        height: 48px;
        left: 50%;
        margin: 0 0 0 47px;
        position: absolute;
        width: 48px;
    }
}
.fb-form-thumbnail {
    &,
    &.btn {
        background-color: $uix-shade-400;
        border-radius: 3px;
        height: 36px;
        justify-content: center;
        margin: 5px;
        min-height: 36px;
        min-width: 36px;
        position: relative;
        width: 36px;
    }
}
.fb-form-thumbnails {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-flow: row nowrap;
    gap: 0;
    justify-content: flex-start;
    left: 16px;
    position: absolute;
    top: 16px;
    z-index: 1;
}
.fb-form-images {
    align-content: center;
    align-items: center;
    background-color: $uix-shade-black;
    display: flex;
    flex-flow: row wrap;
    gap: 0;
    justify-content: center;
    margin: 10px 0;
    min-height: 320px;
    padding: 0;
    position: relative;
    width: 100%;
}

.fullscreen {
    margin: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
}
</style>
