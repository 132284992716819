import apmTransactions from '@/libraries/apm/transactions';
import store from '@/store/index';
// General Helpers

export const isNullOrUndefined = function (value) {
    return value === null || value === undefined;
};

export const stopPropagation = (evt) => {
    try {
        if (typeof evt.preventDefault === 'function') {
            evt.preventDefault();
        }
        if (typeof evt.stopImmediatePropagation === 'function') {
            evt.stopImmediatePropagation();
        } else {
            evt.cancelBubble = true;
        }
    } catch (exception) {
        // No-op
    }
};

export const formatMobileNumber = function (value) {
    const cleaned = value.replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = match[1] ? '+1 ' : '';
        return [intlCode, match[2], '-', match[3], '-', match[4]].join('');
    }
    return cleaned ?? null;
};

// Trailer Assessment Helpers

export const assessmentHasImagesOrComments = function (
    assessment,
    subTypeName
) {
    if (isNullOrUndefined(assessment) || isNullOrUndefined(subTypeName)) {
        return false;
    }

    const subType = assessment.subTypes?.find(
        (ele) => ele.subType === subTypeName
    );

    if (isNullOrUndefined(subType)) {
        return false;
    }

    return subType.documents?.length > 0 || subType.comment?.length > 0;
};

export const shouldClearTypeOnSubtypeRemoval = (assessment, typeAndSubType) => {
    const { assessmentType, subType } = typeAndSubType;

    const subTypes = assessment[assessmentType]?.subTypes;
    if (isNullOrUndefined(subTypes)) {
        return false;
    }

    return subTypes.length === 1 && subTypes[0].subType === subType;
};

const GeolocationPositionError = {
    1: 'PERMISSION_DENIED',
    2: 'POSITION_UNAVAILABLE',
    3: 'TIMEOUT',
};

const getPosition = () => {
    if ('geolocation' in navigator) {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
    }
};

export const tryGetCurrentPosition = async () => {
    try {
        const position = await getPosition();
        if (store.getters.notificationMessage)
            store.commit('setNotificationMessage', null);

        return {
            coords: {
                latitude: position?.coords?.latitude,
                longitude: position?.coords?.longitude,
            },
            error: null,
        };
    } catch (err) {
        store.commit(
            'setNotificationMessage',
            "Location sharing isn't enabled on this device"
        );

        const error = GeolocationPositionError[err.code];
        console.warn(
            `Error retrieving users location. ${error}: ${err.message}`
        );
        apmTransactions.instrumentLocationUnavailable(error);

        return {
            coords: null,
            error: error,
        };
    }
};

export const shouldValidateProximity = (attachabilityResponse) => {
    const proximityEval = attachabilityResponse?.attachabilityEvaluations?.find(
        (ae) => ae.name === 'trailer_proximity'
    );

    return proximityEval?.result === 'warn';
};
