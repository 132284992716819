<!-- [INGEE - 12/30/2022] NOTE: in the effort to decouple our confirmation overlay from vuex actions, this confirmation handler is set to replate the existing taclite frontend 'ConfirmationOveraly' -->

<template>
    <div class="background">
        <div class="message-container">
            <span class="title" v-html="titleText"></span>
            <br />
            <span
                class="subtitle"
                :class="subtitleStyle"
                v-html="subtitleText"
            ></span>
            <br />
            <br />
            <slot name="warning"></slot>
            <div class="button-container">
                <FbxButton
                    :button-text="acceptText"
                    :theme="acceptTheme"
                    @click="acceptClicked"
                />
                <FbxButton
                    :button-text="rejectText"
                    :theme="rejectTheme"
                    @click="rejectClicked"
                />
            </div>
        </div>
    </div>
</template>

<script>
import FbxButton from './fbx-button.vue';

export default {
    props: {
        titleText: String,
        subtitleText: String,
        subtitleStyle: String,
        acceptText: {
            type: String,
            default: 'Accept',
        },
        rejectText: {
            type: String,
            default: 'Reject',
        },
        acceptTheme: {
            type: String,
            default: 'primary',
        },
        rejectTheme: {
            type: String,
            default: 'return',
        },
        popupStage: {
            type: Number,
            default: -1,
        },
    },
    components: {
        FbxButton,
    },
    methods: {
        acceptClicked() {
            if (this.popupStage === 1) {
                this.$emit('confirmStage', { didAccept: true });
                return;
            }

            this.$emit('accept');
        },
        rejectClicked() {
            if (this.popupStage === 1) {
                this.$emit('confirmStage', { didAccept: false });
                return;
            }

            this.$emit('reject');
        },
    },
    created() {
        document.activeElement.blur();
    },
    beforeMount() {
        document.body.classList.add('modal-open');
    },
    beforeUnmount() {
        document.body.classList.remove('modal-open');
    },
};
</script>

<style lang="scss">
.red {
    color: $uix-color-error;
    font-weight: bold;
}

.message-container {
    background-color: #f5f4f2;
    position: fixed;
    bottom: 0px;
    width: 100%;
    border: 4px solid #e0dfdd;
    border-radius: 16px 16px 0px 0px;
    z-index: 101;
    padding: 10px 10px 0px 10px;
}

.button-container {
    display: flex;
    flex-direction: column;
    max-width: 376px;
    margin: auto;
}

.background {
    background-color: #333333aa;
    height: calc(100% + 80px);
    width: 100%;
    position: fixed;
    top: -80px;
    z-index: 100;
}

.title {
    font-weight: bold;
    font-size: min(4vw, 1.2rem);
}

.subtitle {
    font-style: italic;
    font-size: min(3.5vw, 1.15rem);
}

.strong-subtitle {
    font-style: normal;
    font-size: min(3.5vw, 1.15rem);
}
</style>
