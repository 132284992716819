<template>
    <div class="attach-trailer">
        <div class="exit">
            <font-awesome-icon
                :icon="['fas', 'xmark']"
                @click="$emit('toggleMenu', null)"
            />
        </div>

        <div class="trailer-title">
            {{ trailer?.trailerCompany }} #{{ trailer?.trailerId }}
        </div>

        <div class="attach-button-container">
            <FbxButton
                v-if="!hasQrs"
                button-text="Attach Trailer"
                theme="primary"
                @click="routeToAttach"
            />
            <button v-else class="btn btn-primary" @click="routeToScan">
                <CameraIcon height="24" width="24" />
                <span>Scan Trailer</span>
            </button>
        </div>
    </div>
</template>

<script>
import FbxButton from '@/components/fbx-button.vue';
import CameraIcon from '@/components/icons/camera-viewfinder-solid.vue';
import apmTransactions from '@/libraries/apm/transactions';
import globalConstants from '@/plugins/globalConstants';

export default {
    name: 'ThirdPartyAttachTrailer',
    emits: ['toggleMenu'],
    components: {
        FbxButton,
        CameraIcon,
    },
    props: {
        trailer: null,
    },
    computed: {
        hasQrs() {
            return this.trailer?.qrCodes?.length > 0;
        },
    },
    methods: {
        routeToAttach() {
            this.$router.push({ name: 'EnterTrailerDetails' });
            apmTransactions.instrumentLaunchFbaFromPinpoint(
                globalConstants.map.name
            );
        },
        routeToScan() {
            this.$router.push({ name: 'Scan' });
            apmTransactions.instrumentLaunchFbaFromPinpoint(
                globalConstants.map.name
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.attach-trailer {
    .exit {
        width: 100%;
        text-align: right;
        position: relative;
        bottom: 5px;
        height: 0px;
        max-width: 546px;
        margin: 0 auto;

        svg {
            cursor: pointer;
            height: 27px;
            width: 27px;
            color: #c7c6c4;
        }
    }

    .trailer-title {
        font-weight: 600;
        font-size: 1.125rem;
        text-transform: capitalize;
        margin-top: 0.625rem;
    }

    .attach-button-container {
        margin: 2.313rem 0rem 4.563rem;

        .btn {
            width: calc(100% - 10px) !important;
        }

        button {
            width: 100%;
            min-height: 58px;
            border-radius: 29px;
            font-size: 1rem;

            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                margin-right: 0.75rem;
            }
        }
    }
}
</style>
