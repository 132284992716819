<template>
    <div :class="cardClass" @click="onCardClicked">
        <div class="fb-load-itinerary">
            <div class="fb-load-origin">
                <div class="fb-load-bullet" />
                <div class="fb-load-label">
                    <small>{{ currentLoad?.origin?.name }}</small>
                    <strong>
                        {{ currentLoad?.origin?.city }},
                        {{ currentLoad?.origin?.state }}
                    </strong>
                </div>
            </div>
            <div class="fb-load-details">
                <div class="fb-load-bullet">
                    <svg
                        version="1.1"
                        class="fb-svg box-moving"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 42.2 27"
                        style="enable-background: new 0 0 42.2 27"
                        xml:space="preserve"
                    >
                        <path
                            class="fb-path box-moving"
                            d="M42.2,6L42.2,6C42.2,5.9,42.2,5.9,42.2,6c0-0.1,0-0.2,0-0.2l0,0l0,0l0,0l0,0l0,0 c-0.1-0.1-0.1-0.1-0.2-0.1L29.1,0.1c-0.2-0.1-0.4-0.1-0.5,0L14.4,5.5h-0.1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0c0,0,0,0.1-0.1,0.1 c0,0,0,0.1-0.1,0.1l0,0l0,0c0,0,0,0,0,0.1l-0.2,1.5H4.7c-0.5,0-0.8,0.4-0.8,0.9S4.3,9,4.7,9h12.2c0.3,0,0.6,0.2,0.6,0.5 s-0.2,0.6-0.5,0.6l0,0h-3.7L13,12.2H0.9C0.4,12.2,0,12.5,0,13s0.4,0.9,0.8,0.9c0,0,0,0,0.1,0h15.3c0.3,0,0.6,0.2,0.6,0.5 S16.6,15,16.3,15l0,0h-3.6l-0.2,1.7H5.9c-0.5,0-0.9,0.4-0.9,0.8s0.4,0.9,0.8,0.9c0,0,0,0,0.1,0h9.4c0.3,0,0.5,0.3,0.5,0.6 c0,0.3-0.2,0.5-0.5,0.5h-3.4l-0.2,1.3c0,0.3,0.1,0.5,0.3,0.6L24.8,27l0,0h0.1l0,0H25l0,0h0.1l0,0h0.1l0,0l14.3-5.5 c0.3-0.1,0.5-0.4,0.5-0.6l2.1-14.7c0,0,0,0,0-0.1C42.2,6.1,42.2,6.1,42.2,6L42.2,6L42.2,6 M28.7,1.4l11,4.7l-4.1,1.6L24.6,3 L28.7,1.4z M16.4,6.2l4.5-1.7l11,4.7l-4.5,1.7L16.4,6.2z M38.8,20.4L26,25.3l1.9-13.2l4.9-1.9l-0.9,6.4l3.1-1.1L35.9,9l4.8-1.8 L38.8,20.4z"
                        />
                    </svg>
                </div>
                <div class="fb-load-label">
                    <strong
                        >{{ $t('LoadConfirmation.preload') }} #{{
                            currentLoad?.loadNumber
                        }}
                    </strong>
                </div>
            </div>
            <div class="fb-load-destination">
                <div class="fb-load-bullet" />
                <div class="fb-load-label">
                    <small>{{ currentLoad?.destination?.name }}</small>
                    <strong>
                        {{ currentLoad?.destination?.city }},
                        {{ currentLoad?.destination?.state }}
                    </strong>
                </div>
            </div>
        </div>

        <transition>
            <div v-if="buttonOn" class="confirm-button-wrapper">
                <button
                    type="button"
                    class="btn btn-primary fb-form-button"
                    @click="onConfirmationClicked"
                >
                    {{ $t('LoadConfirmation.submitButton') }}
                </button>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'FbxLoadCard',
    props: {
        currentLoad: {
            type: Object,
            default: null,
        },
        showButtons: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['confirm'],
    data: () => ({
        buttonOn: false,
        selected: false,
    }),
    created() {
        if (this.showButtons) {
            this.$store.commit(
                'loadStore/setSelectedLoad',
                this.currentLoad?.loadNumber
            );
            this.buttonOn = true;
        }
    },
    computed: {
        cardClass() {
            return this.selected
                ? 'fb-load-card has-border selected'
                : 'fb-load-card has-border';
        },
        shouldShowButton() {
            return this.getSelectedLoad === this.currentLoad?.loadNumber;
        },
        ...mapGetters({ getSelectedLoad: 'loadStore/getSelectedLoad' }),
    },
    watch: {
        shouldShowButton(val) {
            this.toggleButtonVisibility(val);
        },
    },
    methods: {
        onConfirmationClicked() {
            this.$emit('confirm');
        },
        onCardClicked() {
            this.$store.commit(
                'loadStore/setSelectedLoad',
                this.currentLoad.loadNumber
            );
        },
        toggleButtonVisibility(shouldShowButton) {
            this.selected = shouldShowButton;

            if (!this.showButtons)
                setTimeout(
                    () => {
                        this.buttonOn = !this.buttonOn;
                    },
                    this.buttonOn ? 0 : 300
                );
        },
    },
};
</script>

<style lang="scss" scoped>
.fb-load-bullet {
    align-content: center;
    align-items: center;
    background-color: $uix-shade-white;
    border: 2px solid #c3c3bf;
    display: flex;
    flex-flow: row nowrap;
    gap: 0;
    justify-content: center;
    position: absolute;
}

.fb-load-label {
    position: absolute;
    white-space: nowrap;
    width: 160px;

    > strong,
    > small {
        color: $uix-paragraph-text-color;
        display: block;
        position: relative;
    }

    > strong {
        font-size: 0.875rem; // 14px
        font-weight: 600;
    }

    > small {
        font-size: 0.75rem; // 12px
        font-weight: 400;
        font-style: italic;
    }
}

.fb-load-origin,
.fb-load-details,
.fb-load-destination {
    background-color: #c3c3bf;
    height: 2px;
    position: absolute;
    top: -2px;
    width: 2px;
}

.fb-load-origin,
.fb-load-destination {
    .fb-load-bullet {
        border-radius: 6px;
        height: 12px;
        left: -5px;
        top: -5px;
        width: 12px;
    }

    .fb-load-label {
        margin: 18px 0 0;
        top: 100%;
    }
}

.fb-load-origin {
    left: 0;
    margin: 0;

    .fb-load-label {
        left: -20px;
        text-align: left;
    }
}

.fb-load-destination {
    left: 100%;
    margin: 0 0 0 -2px;

    .fb-load-label {
        right: -20px;
        text-align: right;
    }
}

.fb-load-details {
    left: 50%;
    margin: 0 0 0 -1px;

    .fb-load-bullet {
        border-radius: 28px;
        height: 56px;
        left: -27px;
        top: -27px;
        width: 56px;

        > svg {
            height: 27px;
            width: 42.1875px;
        }
    }

    .fb-load-label {
        bottom: 100%;
        margin: 0 0 38px;
        right: -80px;
    }
}

.fb-load-itinerary {
    border-top: 2px dashed #c3c3bf;
    display: inline-block;
    margin: -4px 0 0;
    max-width: 100%;
    min-width: 100%;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 50px;
}

.fb-load-card {
    align-content: center;
    align-items: center;
    border-radius: 4px;
    gap: 0;
    margin: 10px auto;
    padding: 30px 30px;
    position: relative;
    width: 100%;
    height: 100%;

    &.has-border {
        background-color: #ffffff;
        border: 1px solid #545454;
        height: 100%;
        min-height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;

        &.selected {
            outline: 1px solid $uix-color-secondary;
            border: 1px solid $uix-color-secondary;
            min-height: 230px;
        }
    }
}

.fb-path {
    &.box-moving {
        fill: #72706b;
    }
}

.fb-form-button {
    margin-top: 30px;
    margin-bottom: -10px;
}

.v-enter-active {
    transition: opacity 0.1s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
