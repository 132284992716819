<template>
    <CustomWaldoMap>
        <template
            v-slot:content-panel-menu="{
                generalDirections,
                menuSelection,
                toggleMenu,
            }"
        >
            <AssetMapMenu
                :generalDirections="generalDirections"
                :menuSelection="menuSelection"
                @toggleMenu="toggleMenu"
            />
        </template>
    </CustomWaldoMap>
</template>

<script>
import CustomWaldoMap from '../CustomWaldoMap.vue';
import AssetMapMenu from '@/views/Map/Asset/AssetMapMenu.vue';

export default {
    components: {
        AssetMapMenu,
        CustomWaldoMap,
    },
};
</script>
