import apiErrorHandler from '../plugins/apiErrorHandler';
import axios from '@/plugins/axiosConfig';

export default async function (loadNumber, dotNumber) {
    return await axios({
        method: 'get',
        url: `${process.env.VUE_APP_EXT_API_URL}api/loads/${loadNumber}/carriers/${dotNumber}`,
    })
        .then((r) => r.data)
        .catch((error) => {
            return apiErrorHandler(error);
        });
}
