import apiErrorHandler from '../plugins/apiErrorHandler';
import axios from '@/plugins/axiosConfig';

export default async function (
    eventName,
    driverData,
    trailerData,
    locationData,
    startTime,
    endTime,
    trailerAssessment,
    questionAnswers,
    assessmentOutcome
) {
    let data = {
        eventName: eventName,
        driver: driverData,
        trailer: trailerData,
        location: locationData,
        startTime: startTime,
        endTime: endTime,
        assessmentTypes: trailerAssessment,
        questionAnswers: questionAnswers,
        assessmentOutcome: assessmentOutcome,
        source: 'Freightbox Driver Assessment',
    };

    return await axios({
        method: 'post',
        url: `${process.env.VUE_APP_EXT_API_URL}api/trailers/trailerAssessment`,
        data: data,
    })
        .then((r) => r.data)
        .catch((error) => {
            return apiErrorHandler(error);
        });
}
