<template>
    <div>
        <div class="fb-container body">
            <div class="fb-form">
                <h1 class="fb-form-heading">{{ this.title }}</h1>
                <p v-if="subtitle" class="fb-form-paragraph">{{ subtitle }}</p>
                <photo-selection
                    v-on:imagesChanged="onImagesChanged"
                    :existingImages="images"
                />
                <fbx-textarea
                    v-model="textareaModel"
                    :maxlength="140"
                    :model="textareaModel"
                    :placeholderLocaleKey="placeholder"
                />
                <div class="fb-form-buttons">
                    <fbx-button
                        :buttonText="
                            $t('DamagedPicturesAndComments.saveButton')
                        "
                        class="wide"
                        v-on:click="onSave"
                        theme="primary"
                    />
                    <fbx-button
                        :buttonText="
                            $t('DamagedPicturesAndComments.cancelButton')
                        "
                        class="wide"
                        v-on:click="onCancel"
                        theme="primaryOutlineEm"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PhotoSelection from '@/components/PhotoSelection.vue';
import FbxTextarea from '@/components/fbx-textarea.vue';
import FbxButton from '@/components/fbx-button.vue';
import { isNullOrUndefined } from '@/shared/helpers';
import globalConstants from '../../plugins/globalConstants';
import { mapGetters } from 'vuex';

export default {
    name: 'DamagedPicturesAndComments',
    components: {
        'photo-selection': PhotoSelection,
        'fbx-textarea': FbxTextarea,
        'fbx-button': FbxButton,
    },
    props: {
        assessmentType: {
            type: String,
            default: '',
        },
        textareaPlaceholderLocaleKey: {
            type: String,
            default: '',
        },
        titleLocaleKey: {
            type: String,
            default: '',
        },
        subtitleLocaleKey: {
            type: String,
            default: '',
        },
    },
    data: function () {
        return {
            subType: '',
            images: [],
            trailerId: -1,
            textareaModel: '',
            title: '',
            subtitle: '',
            placeholder: '',
        };
    },
    computed: {
        ...mapGetters(['trailerAssessment']),
        partialTrailerAssessment: function () {
            const partial = this.trailerAssessment[
                this.assessmentType
            ]?.subTypes?.find((ele) => ele.subType === this.subType);

            if (!isNullOrUndefined(partial)) {
                return partial;
            }

            return null;
        },
    },
    async created() {
        this.setLoading(true);
        this.initializeRouteParams();
        this.initializeLocalizations();

        if (isNullOrUndefined(this.trailerAssessment))
            await this.$store.dispatch('getTrailerAssessmentFromIndexedDB');

        this.initializeImagesAndComments();
        this.setLoading(false);
    },
    methods: {
        initializeRouteParams: function () {
            this.trailerId = this.$route.params.trailerId;
            this.subType = this.$route.params.subType;

            if (isNullOrUndefined(this.trailerId)) {
                console.error('trailerId route param is required');
            }

            if (isNullOrUndefined(this.subType)) {
                console.error('subType route param is required');
            }
        },
        initializeLocalizations: function () {
            this.title = this.$t(this.titleLocaleKey, [
                this.$t(`DamagedPicturesAndComments.subTypes.${this.subType}`),
            ]);
            this.subtitle = this.subtitleLocaleKey
                ? this.$t(this.subtitleLocaleKey)
                : undefined;
            this.placeholder = this.textareaPlaceholderLocaleKey;
            if (
                this.assessmentType ===
                globalConstants.trailer.assessmentTypes.kingpinLanding
            ) {
                this.placeholder = `DamagedPicturesAndComments.KingpinDamage.subTypes.${this.subType}`;
            } else if (
                this.assessmentType ===
                globalConstants.trailer.assessmentTypes.airlinesElectrical
            ) {
                this.placeholder = `DamagedPicturesAndComments.AirlinesDamage.subTypes.${this.subType}`;
            }
        },
        initializeImagesAndComments: function () {
            if (!isNullOrUndefined(this.partialTrailerAssessment)) {
                if (!isNullOrUndefined(this.partialTrailerAssessment.comment)) {
                    this.textareaModel = this.partialTrailerAssessment.comment;
                }

                if (
                    !isNullOrUndefined(this.partialTrailerAssessment.documents)
                ) {
                    this.images = this.partialTrailerAssessment.documents;
                }
            }
        },
        onSave: async function () {
            const assessmentDocuments = this.images.map((img) => {
                return {
                    src: img.src,
                    fileName: img.fileName,
                    metadata: {
                        trailerAssessmentId: null,
                        trailerId: this.trailerId,
                        type: this.assessmentType,
                        subType: this.subType,
                    },
                };
            });

            if (
                assessmentDocuments?.length > 0 ||
                this.textareaModel?.length > 0
            ) {
                const partialTrailerAssessment = {
                    assessmentType: this.assessmentType,
                    subType: this.subType,
                    documents: assessmentDocuments,
                    comment: this.textareaModel,
                };

                await this.$store.dispatch(
                    'savePartialTrailerAssessment',
                    partialTrailerAssessment
                );
            } else {
                await this.$store.dispatch('removeTrailerAssessmentSubType', {
                    assessmentType: this.assessmentType,
                    subType: this.subType,
                });
            }

            this.$router.push({ name: history.go(-1) });
        },
        onCancel: async function (evt) {
            if (
                this.images?.length === 0 &&
                this.partialTrailerAssessment?.comment === ''
            ) {
                await this.$store.dispatch('removeTrailerAssessmentSubType', {
                    assessmentType: this.assessmentType,
                    subType: this.subType,
                });
            }

            this.$router.push({ name: history.go(-1) });
        },
        onImagesChanged: function (images) {
            this.images = images;
        },
    },
};
</script>
<style lang="scss" scoped>
.fb-form-buttons {
    display: inline-block;
    margin: 20px 0 40px;
    position: relative;
    width: 100%;
    .btn {
        margin: 0 0 10px;
    }
}
.fb-form-images {
    margin-top: 20px;
}
</style>
