<template>
    <div class="exp-link">
        <div class="exp-message">
            <div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="56"
                    height="56"
                    viewBox="0 0 56 56"
                    fill="none"
                >
                    <path
                        d="M56 28C56 43.4656 43.4656 56 28 56C12.5344 56 0 43.4656 0 28C0 22.1703 1.78172 16.7563 4.82891 12.2719C5.64375 11.0797 7.27672 10.7658 8.47547 11.5828C9.67422 12.3922 9.98594 14.0328 9.17109 15.225C6.69594 18.8672 5.15156 23.2641 5.15156 28C5.15156 40.5672 15.4328 50.75 27.9016 50.75C40.5672 50.75 50.75 40.5672 50.75 28C50.75 16.3188 41.9562 6.70141 30.625 5.39984V11.375C30.625 12.8297 29.4547 14 27.9016 14C26.5453 14 25.2766 12.8297 25.2766 11.375V2.625C25.2766 1.17578 26.5453 0 27.9016 0C43.4656 0 56 12.5344 56 28ZM29.7609 26.1406C30.8766 27.1688 30.8766 28.8313 29.7609 29.7609C28.8313 30.8766 27.1688 30.8766 26.1406 29.7609L17.3906 21.0109C16.3734 20.0813 16.3734 18.4188 17.3906 17.3906C18.4188 16.3734 20.0813 16.3734 21.0109 17.3906L29.7609 26.1406Z"
                        fill="#72706C"
                    />
                </svg>
            </div>
            <div class="exp-message-title">Sorry, the link has expired</div>
            <div class="exp-message-body">
                All links expire 24 hours after being sent. Please contact the
                person who shared the link to send a new one.
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LinkExpiration',
};
</script>

<style lang="scss" scoped>
.exp-link {
    height: 100%;
    width: calc(100% - 40px);
    background: inherit;
    z-index: 10;
    margin: 0 auto;

    .exp-message {
        margin: 35px 0px 100px;

        svg {
            margin-bottom: 30px;
        }

        .exp-message-title {
            font-weight: 700;
            font-size: 21px;
            line-height: 29px;
        }

        .exp-message-body {
            margin: 5px 10px;
            font-size: 15px;
        }
    }
}
</style>
