<template>
    <div>
        <div class="fb-container body">
            <div v-if="!carrierExpired" class="fb-form">
                <h1 data-test="get-started-title" class="fb-form-heading">
                    {{ $t('GetStarted.title') }}
                </h1>
                <p data-test="get-started-subtitle" class="fb-form-paragraph">
                    {{ $t('GetStarted.subTitle') }}
                </p>
                <div data-test="get-started-form" class="fb-form-controls">
                    <fbx-dropdown-input
                        ref="dropdownInput"
                        v-model="mcOrDot"
                        data-test="get-started-mcdot-input"
                        :required="true"
                        keyboard-type="numeric"
                        :dropdown-options="mcOrDotDropdownOptions"
                        @validation-error="(value) => updateMcOrDotValid(value)"
                    />

                    <fbx-input
                        ref="input"
                        v-model="mobileNumber"
                        data-test="get-started-mobile-number-input"
                        type="tel"
                        :label="'GetStarted.mobileNumberPlaceHolder'"
                        :required="false"
                        @validation-error="
                            (value) => updatePhoneValidation(value)
                        "
                    />
                    <fbx-button
                        name="signin-button"
                        data-test="get-started-submit-button"
                        class="wide"
                        theme="primary"
                        :button-text="$t('GetStarted.submitButton')"
                        @clicked="registerDriverTapped"
                    />
                    <p data-test="get-started-privacy-policy-text">
                        <i18n-t
                            class="fb-form-paragraph terms-privacy"
                            keypath="GetStarted.privacyPolicyLinkText"
                            tag="p"
                        >
                            <a class="fb-inline-link" :href="''" target="">{{
                                $t('GetStarted.termsOfUse')
                            }}</a>
                            <a
                                class="fb-inline-link"
                                :href="`https://privacy.knight-swift.com/`"
                                target="_blank"
                                >{{ $t('GetStarted.privacyPolicy') }}</a
                            >
                        </i18n-t>
                    </p>
                </div>
            </div>
            <div v-else data-test="get-started-carrier-expired-div" class="">
                <i18n-t class="" keypath="GetStarted.carrierExpired" tag="p">
                    <a :href="`tel:${supportNumber}`">{{ supportNumber }}</a>
                </i18n-t>
            </div>
        </div>
    </div>
</template>

<script>
import fbxDropdownInput from '../components/fbx-dropdown-input.vue';
import fbxButton from '../components/fbx-button.vue';
import fbxInput from '../components/fbx-input.vue';
import globalConstants from '../plugins/globalConstants';
import FbaApiError from '../models/ApiError';
import { isNullOrUndefined } from '@/shared/helpers';
import apmTransactions from '@/libraries/apm/transactions';

export default {
    components: { fbxDropdownInput, fbxButton, fbxInput },
    data: () => ({
        mcOrDot: null,
        mcOrDotDropdownOptions: [
            { Name: 'GetStarted.mcNumberPlaceHolder', Value: 'MC' },
            { Name: 'GetStarted.dotNumberPlaceHolder', Value: '' },
        ],
        invalidMcOrDotNumber: true,
        invalidPhone: true,
        carrierExpired: false,
        mobileNumber: null,
        supportNumber: null,
    }),
    created() {
        this.mcNumber = null;
        this.dotNumber = null;
        this.mcOrDot = '';
        this.mobileNumber = '';
        this.setLoading(false);
        this.supportNumber = process.env.VUE_APP_SUPPORT_NUMBER;
    },

    methods: {
        async registerDriverTapped() {
            this.setLoading(true);
            const mobileNumberError =
                this.mobileNumber.length > 0
                    ? 'invalidPhoneNumber'
                    : 'validation';
            if (this.invalidMcOrDotNumber && this.invalidPhone) {
                this.$refs.dropdownInput.setAlert('validation');
                this.$refs.input.setAlert(mobileNumberError);
                this.setLoading(false);
                return;
            } else if (this.invalidMcOrDotNumber) {
                this.$refs.dropdownInput.setAlert('validation');
                this.setLoading(false);
                return;
            } else if (this.invalidPhone) {
                this.$refs.input.setAlert(mobileNumberError);
                this.setLoading(false);
                return;
            } else {
                if (this.mcOrDot.includes('MC')) {
                    this.mcNumber = this.mcOrDot;
                    this.dotNumber = null;
                } else {
                    this.mcNumber = null;
                    this.dotNumber = this.mcOrDot;
                }
                try {
                    let response = await this.$store.dispatch('driverSignin', {
                        mcNumber: this.mcNumber,
                        dotNumber: this.dotNumber,
                        mobileNumber: this.mobileNumber.replace(/\D/g, ''),
                    });
                    if (!isNullOrUndefined(response?.dotNumber)) {
                        this.$router.push({ name: 'VerifyIdentity' });
                    } else if (response instanceof FbaApiError) {
                        if (
                            response?.reasonCode ===
                            globalConstants.reasonCodes.invalidPhoneNumber
                        ) {
                            this.$refs.input.setAlert('invalidPhoneNumber');
                        } else if (
                            response?.reasonCode ===
                            globalConstants.reasonCodes.carrierNotFound
                        ) {
                            this.$refs.dropdownInput.setAlert('notFound');
                            apmTransactions.instrumentInvalidCarrier(
                                `Carrier not found: DOT:${this.dotNumber} | MC:${this.mcNumber}`
                            );
                        } else if (
                            response?.reasonCode ===
                            globalConstants.reasonCodes.carrierNotActive
                        ) {
                            this.carrierExpired = true;
                            apmTransactions.instrumentInvalidCarrier(
                                `Expired Carrier: DOT:${this.dotNumber} | MC:${this.mcNumber}`
                            );
                        }
                        this.$store.commit('clearUserDetails');
                        this.setLoading(false);
                    }
                } catch (e) {
                    console.error(e);
                    this.setLoading(false);
                }
                this.setLoading(false);
            }
        },
        updatePhoneValidation(value) {
            this.invalidPhone = value;
        },
        updateMcOrDotValid(value) {
            this.invalidMcOrDotNumber = value;
        },
    },
};
</script>

<style lang="scss" scoped>
.fb-form-paragraph {
    margin-bottom: 30px;

    &.terms-privacy {
        margin: 0 auto 60px;
        max-width: 320px;
    }
}

.btn-primary {
    margin: 20px auto 15px;
}

.fb-inline-link {
    color: $uix-paragraph-text-color;
    font-weight: bold;

    &:focus,
    &:hover {
        color: $uix-shade-black;
    }
}
</style>
