<template>
    <div class="share-location">
        <div class="exit">
            <font-awesome-icon :icon="['fas', 'xmark']" @click="onClose" />
        </div>

        <LocationGroup
            :hideBackground="true"
            :hasTrailer="hasTrailer"
            :customerName="customerName"
            :geoLink="geoLink"
            :trailerAddressLine1="trailerAddressLine1"
            :trailerAddressLine2="trailerAddressLine2"
            :subheaderText="subheaderText"
        />

        <div class="form-content d-flex flex-column align-items-start">
            <div class="copy-link-group">
                <div>Link to share</div>
                <a @click="onCopyLink">Copy Link</a>
            </div>

            <div class="phone-input-group">
                <div>Enter phone number</div>
                <FbxInput
                    ref="phoneNumberInput"
                    label="waldoMap.mobileInputPlaceholder"
                    type="tel"
                    v-model="phoneNumber"
                    @validation-error="(value) => updatePhoneValidation(value)"
                />
            </div>
        </div>

        <div class="submit-button-container">
            <FbxButton
                :button-text="submitting ? 'Sharing' : 'Share'"
                :showLoading="submitting"
                theme="primary"
                :class="{ disabled: invalidPhone }"
                @click="onShareLocation"
            />
        </div>
    </div>
</template>

<script>
import LocationGroup from './LocationGroup.vue';
import FbxInput from '@/components/fbx-input.vue';
import FbxButton from '@/components/fbx-button.vue';

export default {
    name: 'AssetMapShareTrailer',
    emits: ['toggleMenu', 'shareLocation'],
    components: {
        LocationGroup,
        FbxInput,
        FbxButton,
    },
    data: function () {
        return {
            phoneNumber: '',
            invalidPhone: true,
        };
    },
    props: {
        customerName: {
            type: String,
            default: '',
        },
        geoLink: {
            type: String,
            default: '',
        },
        trailerAddressLine1: {
            type: String,
            default: '',
        },
        trailerAddressLine2: {
            type: String,
            default: '',
        },
        subheaderText: {
            type: String,
            default: '',
        },
        hasTrailer: {
            type: Boolean,
            default: true,
        },
        submitting: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        async onCopyLink() {
            await navigator.clipboard.writeText(window.location.href);
        },
        onShareLocation() {
            this.$emit('shareLocation', { mobileNumber: this.phoneNumber });
        },
        onClose() {
            this.$emit('toggleMenu', null);
        },
        updatePhoneValidation(val) {
            this.invalidPhone = val;
        },
    },
};
</script>

<style lang="scss" scoped>
.share-location {
    background: #ffffff;
    border: 1px solid #e0e0de;
    border-radius: 9px;
    margin: 1rem 0 1.563rem;
    position: relative;

    .exit {
        width: calc(100% - 1.313rem);
        text-align: right;
        position: relative;
        bottom: 45px;
        height: 0px;

        svg {
            cursor: pointer;
            height: 27px;
            width: 27px;
            color: #c7c6c4;
        }
    }

    .customer-info {
        margin-top: 0px !important;
    }

    .form-content {
        margin: -0.313rem 1.313rem;
        font-weight: 400;
        font-size: 0.938rem;
        line-height: 20px;
        width: calc(100% - 2.563rem);

        .copy-link-group {
            margin-top: 0.625rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            a {
                text-transform: uppercase;
                text-decoration: underline;
                font-weight: 700;
                color: $uix-color-primary;
                margin-top: 0.5rem;
                cursor: pointer;
            }

            a:active,
            a:hover {
                color: $uix-color-primary;
            }
        }

        .phone-input-group {
            margin-top: 2.25rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;

            .uix-control {
                margin: 0.5rem 0 0 0;
                width: 100%;
                max-width: 100%;
            }
        }
    }
    .submit-button-container {
        margin: 2.5rem 1.25rem 1.875rem 1.25rem;
        width: calc(100% - 2.5rem);

        button {
            width: 100%;
            min-height: 58px;
            border-radius: 29px;
        }
    }
}
</style>
