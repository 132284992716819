<template>
    <div>
        <div class="fb-container body">
            <div class="fb-form">
                <button type="button" class="btn btn-link fb-form-clear-button">
                    <font-awesome-icon :icon="['fas', 'xmark']" />
                </button>
                <div class="fb-form-image support">
                    <div>
                        <svg
                            class="fb-svg user-support"
                            version="1.1"
                            viewBox="0 0 52 65"
                            x="0px"
                            xmlns="http://www.w3.org/2000/svg"
                            xml:space="preserve"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            y="0px"
                            style="enable-background: new 0 0 52 65"
                        >
                            <path
                                class="fb-path user-support"
                                d="M34.7,64.5H17.3c-0.9,0-1.8,0-2.7,0c-8,0-14.1-0.4-14.1-8.8c0-16.8,6.7-16,15.3-16.8 c0.1,3.1,4.6,5.6,10.2,5.6s10.2-2.5,10.2-5.6c8.6,0.8,15.3,0,15.3,16.8c0,8.4-6.1,8.8-14.1,8.8C36.5,64.5,35.6,64.5,34.7,64.5z M12,21.7c0-7.8,6.3-14.1,14-14.1c7.8,0,14,6.3,14,14.1c-0.1,3.7-1.1,7.2-2.8,10.5c-0.9,0.3-1.8,0.5-2.7,0.6 c-0.7-0.6-1.5-0.9-2.4-0.9h-2.7c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8H31c-1.5,1-3.2,1.6-4.9,1.6C18.2,41.1,12,29.4,12,21.7 L12,21.7z M29.3,37.7c-1.1,0-2.1-0.9-2.1-2.1l0,0c0-1.1,0.9-2.1,2.1-2.1l0,0h2.8c0.7,0,1.4,0.4,1.7,0.9c2.9-0.2,5.8-1.1,8.2-2.8 c-0.2-0.4-0.2-0.8-0.2-1.3v-6.9c0-1.3,0.7-2.5,1.9-3.1C43.2,11.1,35.4,3.7,26,3.7S8.8,11.1,8.3,20.5c1.2,0.6,1.9,1.8,1.9,3.1v6.9 c0,1.9-1.5,3.5-3.5,3.5l0,0H6.6c-1.9,0-3.5-1.6-3.5-3.5l0,0v-6.9c0-1.3,0.8-2.5,2-3.1c0.5-11.2,9.7-20,20.9-20s20.4,8.8,20.9,20 c1.2,0.6,2,1.8,2,3.1v6.9c0,1.9-1.5,3.5-3.4,3.5l0,0h-0.2c-0.7,0-1.3-0.2-1.9-0.6c-2.8,1.9-6.1,3-9.5,3.2c-0.3,0.7-1.1,1.1-1.8,1.1 H29.3z"
                            />
                        </svg>
                    </div>
                </div>
                <h1 class="fb-form-heading">
                    {{ $t('Support.title') }}
                </h1>
                <button type="button" class="btn btn-primary fb-form-button">
                    Call Dispatch
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.fb-path {
    &.user-support {
        fill: #fff;
    }
}

.fb-svg {
    &.user-support {
        height: 64px;
        position: relative;
    }
}

.fb-form-image {
    &.support {
        display: inline-block;
        margin: 40px auto 0;
        position: relative;

        > div {
            align-content: center;
            align-items: center;
            background-color: #4d6a99;
            border-radius: 48px;
            display: flex;
            gap: 0;
            justify-content: center;
            height: 96px;
            margin: 0;
            position: relative;
            width: 96px;
        }
    }
}

.fb-form-heading {
    margin: 10px 0 50px;
}

.fb-form-button {
    margin-bottom: 60px;
}
</style>
